import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TocIcon from '@mui/icons-material/Toc';
import { EventsModel } from 'model/screensModels/EventsModel';
import { useEffect, useMemo, useState } from 'react';
import { EventsService } from 'services/menu/eventsService/EventsService';
import { msgTypes } from 'constants/msgTypes';
import { ResponseModel } from 'model/utilModels/ResponseModel';
import { Form, Formik } from 'formik';
import CustomTableGrid from 'components/uiComponents/CustomTableGrid';
import ReactTable from 'components/wrapperComponents/ReactTable';
import NoRecord from 'components/sharedComponent/noRecord/NoRecord';
import { PaginatorModel } from 'model/utilModels/PaginatorModel';
import { BlogService } from 'services/menu/blogService/BlogService';
import { UploadCertificateService } from 'services/menu/uploadCertificate/UploadCertificateService';
import Events from './Events';
import FormikControl from 'components/wrapperComponents/FormikControl';
import ApiUrl from 'services/shared/ApiUrl';


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function indexProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function EventList() {
  const [value, setValue] = React.useState(0);
  const [flag, setFlag] = useState(false)
 
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
    <Box sx={{ width: '100%' }} className="container-subheaders">

      <Box sx={{ borderBottom: 1, borderColor: '#ebedf3' }}>
        <Tabs className='tab-list' value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab icon={<TocIcon />} label="Passed Events" iconPosition='start' {...indexProps(0)} />
          <Tab icon={<TocIcon />} label="Upcoming Events" iconPosition='start' {...indexProps(1)} />
        </Tabs>
      </Box>
    
      <CustomTabPanel value={value} index={0}>
      <PastList />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      <UpcomingList />
      </CustomTabPanel>
    </Box>
    </>
  );
}



const UpcomingList = () =>{
  const [upcomingEventList, setUpcomingEventList] = useState(new ResponseModel())
  const paginatorModel: PaginatorModel = new PaginatorModel();
  const [pagination, setPagination] = useState({
    pageSize: paginatorModel.pageSize,
    pageNumber: paginatorModel.pageNumber,
    filter: "",
    orderBy: "",
    desc:"",
  })

  const columns = useMemo(() =>
    [
      
      {
        accessorKey: "eventName",
        header: "Event Name",
        size:250
      },
      {
        accessorKey: "eventStartDate",
        header: "Start Date",
        size: 150
      },
      {
        accessorKey: "eventEndDate",
        header: "End Date",
        size: 150
      },
      {
        accessorKey: "venue",
        header: "Venue",   
        size: 150     
      },
      {
        accessorKey: "countryName",
        header: "Country",
      },
    
    ], []);

  
    useEffect(() => {
      getUpcomingEventList();
  }, [pagination.pageNumber, pagination.pageSize, pagination.filter, pagination.orderBy, pagination.desc])

  //Prepare request body to get Event list
  const prepareRequest = () => {
    const requestBody = new PaginatorModel();
    requestBody.pageNumber = pagination.pageNumber;
    requestBody.pageSize = pagination.pageSize;
    requestBody.filter = pagination.filter;
    requestBody.orderBy = pagination.orderBy;
    requestBody.desc = pagination.desc;

    return requestBody;
  }

  const getUpcomingEventList = async () => {
    const requestBody = prepareRequest()
    const res = await EventsService.getUpcomingEventList(requestBody);
    if (res.statusMsg === msgTypes.SUCCESS.msg) {
      setUpcomingEventList(res);
    }
  }

  // const onPageChangeHandler = (page: number, rowsPerPage: number, values: EventsModel) => {
  //   //setSelectedValues(values);
  //   setPagination({
  //     pageNumber: page,
  //     pageSize: rowsPerPage,
  //   })
  // }

  return (
      <>
       <Formik
       enableReinitialize={true}
       initialValues={{}}
       onSubmit={(values)=>{}}
    >
      {
        formik =>
        (
          <Form>
                    <CustomTableGrid
                      item={
                      <FormikControl 
                          control='materialTable'
                          tableColumn={columns}
                          tableData={upcomingEventList}
                          formikValue={formik}
                          setPagination={setPagination}
                        />
                      }
                    />
          </Form>
        )
      }
    </Formik>
      </>
  )
}





const PastList = () =>{
  const [pastEventList, setPastEventList] = useState(new ResponseModel())
  const paginatorModel: PaginatorModel = new PaginatorModel();
  const [pagination, setPagination] = useState({
    pageSize: paginatorModel.pageSize,
    pageNumber: paginatorModel.pageNumber,
    filter: "",
    orderBy: "",
    desc:"",
  })

  const columns = useMemo(() =>
    [
      
      {
        accessorKey: "eventName",
        header: "Event Name",
        size:250
      },
      {
        accessorKey: "eventStartDate",
        header: "Start Date",
        size: 150
      },
      {
        accessorKey: "eventEndDate",
        header: "End Date",
        size: 150
      },
      {
        accessorKey: "venue",
        header: "Venue",   
        size: 150     
      },
      {
        accessorKey: "countryName",
        header: "Country",
      },
    
    ], []);
    

    useEffect(() => {
      getPastEventList();
  }, [pagination.pageNumber, pagination.pageSize, pagination.filter, pagination.orderBy, pagination.desc])

   //Prepare request body to get Events list
   const prepareRequest = () => {
    const requestBody = new PaginatorModel();
    requestBody.pageNumber = pagination.pageNumber;
    requestBody.pageSize = pagination.pageSize;
    requestBody.filter = pagination.filter;
    requestBody.orderBy = pagination.orderBy;
    requestBody.desc = pagination.desc;
    return requestBody;
  }

  const getPastEventList = async () => {
    const requestBody = prepareRequest();
    const res = await EventsService.getPastEventList(requestBody);
    
    if (res.statusMsg === msgTypes.SUCCESS.msg) {      
      setPastEventList(res);
    }
  }

  // const onPageChangeHandler = (page: number, rowsPerPage: number, values: EventsModel) => {
  //   //setSelectedValues(values);
  //   setPagination({
  //     pageNumber: page,
  //     pageSize: rowsPerPage,
  //   })
  // }

  return (
    <>
     <Formik
      enableReinitialize={true}
      initialValues={{}}
      onSubmit={(values)=>{
      }}
    >
      {
        formik =>
        (
          <Form>
                    <CustomTableGrid
                      item={
                      <FormikControl 
                          control='materialTable'
                          tableColumn={columns}
                          tableData={pastEventList}
                          formikValue={formik}
                          setPagination={setPagination}
                        />
                      }
                    />
          </Form>
        )
      }
    </Formik>
    </>
  )
}
