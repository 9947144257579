import React, { useEffect, useState } from "react";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import "./App.scss";
import Login from "./components/sharedComponent/login/Login";
import { AuthProvider } from "util/auth";
import { RequireAuth } from "util/RequireAuth";
import Home from "components/sharedComponent/Home/Home";
import { useAuth } from "util/auth";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { title } from "constants/title";
import Blog from "screens/blogs/Blog";
import Events from "screens/events/Events";
import CompanyProfile from "screens/company-profile/CompanyProfile";
import MemberSearch from "screens/memberSearch/MemberSearch";
import GrievenceRedressal from "screens/grievenceRedressal/GrievenceRedressal";
import NewsAnnouncements from "screens/newsAnnouncements/NewsAnnouncements";
// for use bootstrap
import "./../node_modules/bootstrap/dist/css/bootstrap.min.css";
import InvoiceManagement from "screens/invoiceManagement/InvoiceManagement";
import Certificate from "screens/certificate/Certificate";
import AnnouncementDisplay from "screens/announcement/AnnouncementDisplay";
import { Announcement } from "screens/announcement/Announcement";
import BlogMenu from "screens/blog-menu/BlogMenu";
import ChatAndMeet from "screens/chatAndMeet/ChatAndMeet";
import NewsLetters from "screens/newsLetters/NewsLetters";
import CompanyServices from "screens/companyServices/CompanyServices";
import OwedInvoice from "screens/invoiceManagement/invoiceList/OwedInvoice";
import MyInvoice from "screens/invoiceManagement/invoiceList/MyInvoice";
import NewsLetterSubscribers from "screens/newsLetterSubscribers/NewsLetterSubscribers";
import FeaturedInNewsLetter from "screens/featuredInNewsLetter/FeaturedInNewsLetter";
import ContactEnquiry from "screens/contactEnquiry/ContactEnquiry";
import RegisteredMembers from "screens/registeredMembersList/RegisteredMembers";

const Dashboard = React.lazy(() =>
  import("components/sharedComponent/dashboard/Dashboard")
);

function App() {
  const [pageTitle, setPageTitle] = useState("");
  const location = useLocation();
  const preTitle = "1WN :: One World One Network";

  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  useEffect(() => {
    displayTitle();
  }, [location]);

  function displayTitle() {
    const crumbArray = location.pathname.split("/");
    const endPoint = crumbArray[crumbArray.length - 1];
    const displayTitle = title.filter((item) => {
      return item.key === endPoint;
    });
    setPageTitle(
      displayTitle[0] ? preTitle + " - " + displayTitle[0].value : preTitle
    );
  }

  return (
    // <ThemeProvider theme={theme}>
    <AuthProvider>
      <div className="App">
        <HelmetProvider>
          <Helmet>
            <title>{pageTitle}</title>
          </Helmet>

          <link
            href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
            rel="stylesheet"
            integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN"
            crossOrigin="anonymous"
          ></link>
          <AppRoutes />
        </HelmetProvider>
      </div>
    </AuthProvider>
    // </ThemeProvider>
  );
}

const SuspenseLayout = () => (
  <React.Suspense fallback={<>Loading...</>}>
    <Outlet />
  </React.Suspense>
);
const RequireAuthLayout = () => (
  <RequireAuth>
    <Outlet />
  </RequireAuth>
);

function AppRoutes() {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route path="login" element={<Login />} />
        <Route path="home" element={<Home />}>
          {/* Apply lazy loading */}
          <Route element={<SuspenseLayout />}>
            {/* Authenticate, only login user can open the url's */}
            <Route element={<RequireAuthLayout />}>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="blogs" element={<Blog />} />
              <Route path="events" element={<Events />} />
              <Route
                path="invoice-management"
                element={<InvoiceManagement />}
              />
              <Route path="company-profile" element={<CompanyProfile />} />
              <Route path="uploadcertificate" element={<Certificate />} />
              <Route path="member-search" element={<MemberSearch />} />
              <Route
                path="grievance-redressal"
                element={<GrievenceRedressal />}
              />
              <Route
                path="news-announcements"
                element={<NewsAnnouncements />}
              />
              <Route path="announcements" element={<Announcement />} />
              <Route path="news-letters" element={<NewsLetters />} />
              <Route path="company-services" element={<CompanyServices />} />

              <Route
                path="view-announcement"
                element={<AnnouncementDisplay />}
              />
              <Route path="chat-meet" element={<ChatAndMeet />} />
              <Route path="owedinvoice" element={<OwedInvoice />} />
              <Route path="myinvoice" element={<MyInvoice />} />
              <Route
                path="newsletter-subscribers"
                element={<NewsLetterSubscribers />}
              />
              <Route
                path="featured-in-newsletter"
                element={<FeaturedInNewsLetter />}
              />
              <Route path="contact-enquiry" element={<ContactEnquiry />} />
              <Route
                path="registered member list"
                element={<RegisteredMembers />}
              />
            </Route>
          </Route>
        </Route>
        <Route path="*" element={<Login />} />
      </Routes>
    </>
  );
}

const RoleAuth = ({ roles, children }) => {
  const auth = useAuth();
  if (roles.includes(auth.getUserRole())) {
    return children;
  } else {
    return <Navigate to={"/"} />;
  }
};

export default App;
