//import { generateCaptchaService, generateAccessTokenService, getUserInfo } from "services/auth/LoginService";
import { Grid, Paper } from "@material-ui/core";
import { Box } from "@mui/material";
import "./Login.scss";
import { msgTypes } from "constants/msgTypes";
import { useFormik } from "formik";

import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomTextField from "components/uiComponents/CustomTextField";
import CustomButton from "components/uiComponents/CustomButton";
import { CustomPasswordInput } from "components/uiComponents/CustomPasswordInput";
import { useAuth } from "util/auth";
import RECAPTCHA from "react-google-recaptcha";
import { AuthModel } from "model/utilModels/AuthModel";
import { AuthService } from "services/auth/AuthService";
import { LocalStorageService } from "services/auth/LocalStorageService";
import { RouteType } from "constants/RouteType";
import { loginSchema } from "schemas/menu/auth/loginSchema";

const Login = () => {
  const [captchaMatched, setCaptchaMatched] = useState(false);
  const recaptcha = useRef();
  const navigate = useNavigate();
  const auth = useAuth();

  const initialValues = {
    email: "",
    password: "",
  };

  const formikValue = useFormik({
    initialValues: initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, action) => {
      signIn(values);
      //action.resetForm();
    },
  });
  const { values, errors, touched, handleSubmit, isValid } = formikValue;

  const prepareRequestBody = (values) => {
    const requestData: AuthModel = new AuthModel();
    requestData.email = values.email;
    requestData.password = values.password;

    return requestData;
  };

  //Login with captcha
  async function signIn(values) {
    try {
      const requestBody = prepareRequestBody(values);
      const res = await AuthService.autanticateUser(requestBody);
      if (res) {
        if (res.statusMsg === msgTypes.SUCCESS.msg) {
          LocalStorageService.setToken(res.data.authorization.token);
          const userRes = res.data.user;
          if (userRes) {
            auth.setUserRole("ADMIN");
            //auth.setUserRole(userRes.role);
            auth.login(userRes.firstName);
            LocalStorageService.setUserDetails(
              JSON.stringify(res.data.member[0])
            );
          }
          navigate(RouteType.HOME + RouteType.DASHBOARD);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const onCaptchaSubmit = (value) => {
    if (value) setCaptchaMatched(true);
    else setCaptchaMatched(false);
  };

  return (
    <>
      <Box className="main-box flex max-w-3 main-box-sec">
        <Grid className="login-grid">
          <Paper elevation={3} className="login-paper">
           
            <div className="admin-logo mb-4">
              <img
                src="/images/logo.png"
                className="m-auto d-flex h-40"
                alt="logo"
              />
            </div>
            <form onSubmit={handleSubmit}>
              <CustomTextField
                label="Email"
                name="email"
                requiredField
                disabled={false}
                placeholder={"Enter Email"}
                value={values.email}
                error={errors.email}
                touched={touched.email}
                formikValue={formikValue}
              />
              <CustomPasswordInput
                label="Password"
                name="password"
                requiredField
                disabled={false}
                placeholder={"Enter password"}
                value={values.password}
                error={errors.password}
                touched={touched.password}
                formikValue={formikValue}
              />
              <RECAPTCHA
                sitekey={window.location.href.includes(msgTypes.LOCALHOST) ? msgTypes.GOOGLE_CAPTCHA_SITE_KEY_LOCAL : msgTypes.GOOGLE_CAPTCHA_SITE_KEY_PROD}
                ref={recaptcha}
                onChange={onCaptchaSubmit}
              />
              <Box pt={2}>
                <CustomButton
                  label="Login"
                  className="login-button"
                  fullWidth={true}
                  disabled={!isValid || !captchaMatched}
                  submitType={"search"}
                />
              </Box>
            </form>
          </Paper>
        </Grid>
      </Box>
    </>
  );
};

export default Login;
