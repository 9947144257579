import { Box } from "@mui/material";
import * as React from "react";
import FeaturedInNewsLetterList from "./FeaturedInNewsLetterList";
export default function FeaturedInNewsLetter() {
  return (
    <>
      <Box sx={{ width: "100%" }} className="container-subheaders">
        <Box sx={{ borderBottom: 1, borderColor: "#ebedf3" }}>
          <div className="content-subheader">
            <h4 className="mr-3">Featured In News Letter List</h4>
          </div>
        </Box>
        <div className="sub-content-sec">
          <FeaturedInNewsLetterList />
        </div>
      </Box>
    </>
  );
}
