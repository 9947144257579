import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@material-ui/core';
import { Stack } from '@mui/material';


function MuiSpinner() {
  return (
    <CircularProgress disableShrink   thickness={5} size={300}/>
)
}

export default MuiSpinner