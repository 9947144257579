import React, { useState, useEffect, useMemo } from 'react'
import CustomTableGrid from 'components/uiComponents/CustomTableGrid';
import { Formik, Form } from 'formik'
import NoRecord from 'components/sharedComponent/noRecord/NoRecord';
import ReactTable from 'components/wrapperComponents/ReactTable';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ResponseModel } from 'model/utilModels/ResponseModel';
import { PaginatorModel } from 'model/utilModels/PaginatorModel';
import { AnnouncementService } from 'services/menu/announcementService/AnnouncementService';
import { msgTypes } from 'constants/msgTypes';
import { AnnouncementModel } from 'model/screensModels/AnnouncementModel';
import { conformationAlert } from 'services/shared/alert';
import FormikControl from 'components/wrapperComponents/FormikControl';
import { Box } from '@mui/material';
import ApiUrl from 'services/shared/ApiUrl';



export const AnnouncementList = ({setFlag, updateRecord }) => {

  const [announcementList, setAnnouncementList] = useState(new ResponseModel());
  const paginatorModel: PaginatorModel = new PaginatorModel();
  const [pagination, setPagination] = useState({
    pageSize: paginatorModel.pageSize,
    pageNumber: paginatorModel.pageNumber,
    filter: "",
    orderBy: "",
    desc:"",
  })

  const columns = useMemo(() =>
    [
      {
        header: 'Attachment',
        Cell: ({ renderedCellValue, row }) => (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <img alt="avatar" height={35} width={35} src={row.original.attachment ? ApiUrl.fileBaseUrl+row.original.attachment+"/" : "/image/userimage.jpg"} loading="lazy" />
          </Box>
        ),
        size: 50
      },
      {
        accessorKey: "title",
        header: "Title",
        size:150
      },
      {
        accessorKey: "message",
        header: "Message",
        size: 200
      },
    ], []);

    useEffect(() => {
      getAnnouncementList();
    }, [pagination.pageNumber, pagination.pageSize, pagination.filter, pagination.orderBy, pagination.desc])

  const prepareRequest = () => {
    const requestBody = new PaginatorModel();
    requestBody.pageNumber = pagination.pageNumber;
    requestBody.pageSize = pagination.pageSize;
    requestBody.filter = pagination.filter;
    requestBody.orderBy = pagination.orderBy;
    requestBody.desc = pagination.desc;
    return requestBody;
  }

     //Get Announcement list
     const getAnnouncementList = async () => {
      const requestBody = prepareRequest();
      const res = await AnnouncementService.getAnnouncementList(requestBody);
      if (res.statusMsg === msgTypes.SUCCESS.msg) {
        setAnnouncementList(res)
      }
    }
  
   

  const editRecord = (record: AnnouncementModel) => {
    updateRecord(record);
    setFlag(true)
  }

  const deleteRecord = async (record: AnnouncementModel) => {
    const requestData: AnnouncementModel = new AnnouncementModel();
    requestData.announcementId = record.announcementId
    const deleteFlag = await conformationAlert(msgTypes.CONFORMATION_MESSAGE.DELETE_RECORD, msgTypes.CONFORMATION_ICON.ERROR);
    if(deleteFlag) {
      const res = await AnnouncementService.deleteAnnouncement(requestData);
      if (res.statusMsg === msgTypes.SUCCESS.msg) {
        getAnnouncementList();
      }
    }
  }

  const initialValues = {}
  const onSubmit = () => {
    getAnnouncementList();
  }


  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {
        formik =>
        (
          <Form>
               <CustomTableGrid
                      item={
                         <FormikControl 
                          control='materialTable'
                          tableColumn={columns}
                          tableData={announcementList}
                          formikValue={formik}
                          setPagination={setPagination}
                          actions={[{ type: "icon", icon: <EditIcon />, actionFunc: editRecord },
                            { type: "icon", icon: <DeleteIcon color='error' />, actionFunc: deleteRecord }
                          ]}
                       />
                      }
                    />
          </Form>
        )
      }
    </Formik>
  )
}
