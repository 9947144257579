import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import { CertificateModel } from 'model/screensModels/CertificateModel';
import UploadCertificate from './UploadCertificate';
import CertificateList from './CertificateList';

export default function Certificate() {
  const [value, setValue] = React.useState(0);
  const [updateValue, setUpdateValue] = React.useState(new CertificateModel());
  const [flag, setFlag] = useState(false);

  
  const setIndex = (index: number) => {
    
  };

  
  const getUpdateRecordValue = (certificateModel: CertificateModel) => {
    setUpdateValue(certificateModel);
  };

  return (
    <>
      <Box sx={{ width: '100%' }} className="container-subheaders">
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <div className='content-subheader'>
            {!flag ? <h4 className='mr-3'>Certificate List</h4> : <h4 className='mr-3'>Upload Certificate</h4>}
            {!flag ?
              <button className='btn primary' onClick={() => setFlag(true)}>Upload Certificate</button>
              : ""}
          </div>
        </Box>
        <div className="sub-content-sec">
          {!flag ?
            <CertificateList
              setFlag={setFlag}
              updateRecord={getUpdateRecordValue}
              setIndex={setIndex} 
            />
            :
            <UploadCertificate
              setFlag={setFlag}
              updateRecord={updateValue}
              setIndex={setIndex} 
            />
          }
        </div>
      </Box>
    </>
  );
}