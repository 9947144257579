import React from 'react'
import SearchIcon from '@mui/icons-material/Search';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ImageIcon from '@mui/icons-material/Image';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import SendIcon from '@mui/icons-material/Send';

const ChatAndMeet = () => {
  return (
    <>
    <div className='content-subheader'>
        <h4 className='mr-3'>Chat & Meet  </h4>
          
        </div>
    <div className="content sub-content-sec  d-flex flex-column flex-column-fluid" id="kt_content">

        {/* <!--begin::Entry--> */}
        <div className="d-flex flex-column-fluid">
          {/* <!--begin::Container--> */}
          <div className=" container p-0">
            {/* <!--begin::Chat--> */}
            <div className="d-flex flex-row">
              {/* <!--begin::Aside--> */}
              <div className="flex-row-auto offcanvas-mobile w-350px w-xl-400px offcanvas-mobile-on" id="kt_chat_aside">
                {/* <!--begin::Card--> */}
                <div className="card card-custom">
                  {/* <!--begin::Body--> */}
                  <div className="card-body">
                    {/* <!--begin:Search--> */}
                    <div className="input-group input-group-solid">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                        <SearchIcon color="action" />
                        </span>
                      </div>
                      <input type="text" className="form-control py-3 h-auto" placeholder="Email" />
                    </div>
                    {/* <!--end:Search--> */}

                    {/* <!--begin:Users--> */}
                    <div className="mt-7 scroll scroll-pull ps ps--active-y being-user-height" >
                      {/* <!--begin:User--> */}
                      <div className="d-flex align-items-center justify-content-between mb-5">
                        <div className="d-flex align-items-center">
                          <div className="symbol symbol-circle symbol-50 mr-3">
                            <img alt="Pic" src="/images/300_12.jpg" />
                          </div>
                          <div className="d-flex flex-column">
                            <a href="#" className="text-dark-75 text-hover-primary font-weight-bold font-size-lg">Matt Pears</a>
                            <span className="text-muted font-weight-bold font-size-sm">Head of Development</span>
                          </div>
                        </div>

                        <div className="d-flex flex-column align-items-end">
                          <span className="text-muted font-weight-bold font-size-sm">35 mins</span>
                        </div>
                      </div>
                      {/* <!--end:User--> */}
                        <div className="ps__rail-x l-0 end-user-alignment"><div className="ps__thumb-x end-user-alignment"></div></div><div className="ps__rail-y end-user-height" ><div className="ps__thumb-y end-user-heightx"></div></div></div>
                    {/* <!--end:Users--> */}
                  </div>
                  {/* <!--end::Body--> */}
                </div>
                {/* <!--end::Card--> */}
              </div><div className="offcanvas-mobile-overlay"></div>
              {/* <!--end::Aside--> */}

              {/* <!--begin::Content--> */}
              <div className="flex-row-fluid ml-lg-8" id="kt_chat_content">
                {/* <!--begin::Card--> */}
                <div className="card card-custom">
                  {/* <!--begin::Header--> */}
                  <div className="card-header align-items-center px-4 py-3">
                    <div className="text-left flex-grow-1">
                      {/* <!--begin::Aside Mobile Toggle--> */}
                      <button type="button" className="btn btn-clean btn-sm btn-icon btn-icon-md d-lg-none" id="kt_app_chat_toggle" />
                      {/* <span className="svg-icon svg-icon-lg"><!--begin::Svg Icon | path:/assets/media/svg/icons/Communication/Adress-book2.svg--><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1"> */}
                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24"></rect>
                        <path d="M18,2 L20,2 C21.6568542,2 23,3.34314575 23,5 L23,19 C23,20.6568542 21.6568542,22 20,22 L18,22 L18,2 Z" fill="#000000" opacity="0.3"></path>
                        <path d="M5,2 L17,2 C18.6568542,2 20,3.34314575 20,5 L20,19 C20,20.6568542 18.6568542,22 17,22 L5,22 C4.44771525,22 4,21.5522847 4,21 L4,3 C4,2.44771525 4.44771525,2 5,2 Z M12,11 C13.1045695,11 14,10.1045695 14,9 C14,7.8954305 13.1045695,7 12,7 C10.8954305,7 10,7.8954305 10,9 C10,10.1045695 10.8954305,11 12,11 Z M7.00036205,16.4995035 C6.98863236,16.6619875 7.26484009,17 7.4041679,17 C11.463736,17 14.5228466,17 16.5815,17 C16.9988413,17 17.0053266,16.6221713 16.9988413,16.5 C16.8360465,13.4332455 14.6506758,12 11.9907452,12 C9.36772908,12 7.21569918,13.5165724 7.00036205,16.4995035 Z" fill="#000000"></path>
                      </g>
                      {/* </svg><!--end::Svg Icon--></span>                    </button> */}
                      {/* <!--end::Aside Mobile Toggle--> */}

                      {/* <!--begin::Dropdown Menu--> */}
                      <div className="dropdown dropdown-inline">
                        <button type="button" className="btn btn-clean btn-sm btn-icon btn-icon-md" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <MoreHorizIcon color="action" />
                        </button>
                        <div className="dropdown-menu p-0 m-0 dropdown-menu-left dropdown-menu-md">
                          {/* <!--begin::Navigation--> */}
                          <ul className="navi navi-hover py-5">
                            <li className="navi-item">
                              <a href="#" className="navi-link">
                                <span className="navi-icon"><i className="flaticon2-drop"></i></span>
                                <span className="navi-text">New Group</span>
                              </a>
                            </li>
                            <li className="navi-item">
                              <a href="#" className="navi-link">
                                <span className="navi-icon"><i className="flaticon2-list-3"></i></span>
                                <span className="navi-text">Contacts</span>
                              </a>
                            </li>
                            <li className="navi-item">
                              <a href="#" className="navi-link">
                                <span className="navi-icon"><i className="flaticon2-rocket-1"></i></span>
                                <span className="navi-text">Groups</span>
                                <span className="navi-link-badge">
                                  <span className="label label-light-primary label-inline font-weight-bold">new</span>
                                </span>
                              </a>
                            </li>
                            <li className="navi-item">
                              <a href="#" className="navi-link">
                                <span className="navi-icon"><i className="flaticon2-bell-2"></i></span>
                                <span className="navi-text">Calls</span>
                              </a>
                            </li>
                            <li className="navi-item">
                              <a href="#" className="navi-link">
                                <span className="navi-icon"><i className="flaticon2-gear"></i></span>
                                <span className="navi-text">Settings</span>
                              </a>
                            </li>

                            <li className="navi-separator my-3"></li>

                            <li className="navi-item">
                              <a href="#" className="navi-link">
                                <span className="navi-icon"><i className="flaticon2-magnifier-tool"></i></span>
                                <span className="navi-text">Help</span>
                              </a>
                            </li>
                            <li className="navi-item">
                              <a href="#" className="navi-link">
                                <span className="navi-icon"><i className="flaticon2-bell-2"></i></span>
                                <span className="navi-text">Privacy</span>
                                <span className="navi-link-badge">
                                  <span className="label label-light-danger label-rounded font-weight-bold">5</span>
                                </span>
                              </a>
                            </li>
                          </ul>
                          {/* <!--end::Navigation--> */}
                        </div>
                      </div>
                      {/* <!--end::Dropdown Menu--> */}
                    </div>
                    <div className="text-center flex-grow-1 d-flex align-items-center justify-content-center">
                      <div className="text-dark-75 font-weight-bold font-size-h5 mr-2">Matt Pears</div>
                      <div>
                        <span className="label label-sm label-dot label-success"></span>
                        <span className="font-weight-bold text-muted font-size-sm">Active</span>
                      </div>
                    </div>
                    <div className="text-right flex-grow-1">
                      {/* <!--begin::Dropdown Menu--> */}
                      <div className="dropdown dropdown-inline">
                        <button type="button" className="btn btn-clean btn-sm btn-icon btn-icon-md" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                        <GroupAddIcon color="action" />
                          {/* </svg><!--end::Svg Icon--></span>                        </button> */}
                          <div className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-md">
                            {/* <!--begin::Navigation--> */}
                            <ul className="navi navi-hover py-5">
                              <li className="navi-item">
                                <a href="#" className="navi-link">
                                  <span className="navi-icon"><i className="flaticon2-drop"></i></span>
                                  <span className="navi-text">New Group</span>
                                </a>
                              </li>
                              <li className="navi-item">
                                <a href="#" className="navi-link">
                                  <span className="navi-icon"><i className="flaticon2-list-3"></i></span>
                                  <span className="navi-text">Contacts</span>
                                </a>
                              </li>
                              <li className="navi-item">
                                <a href="#" className="navi-link">
                                  <span className="navi-icon"><i className="flaticon2-rocket-1"></i></span>
                                  <span className="navi-text">Groups</span>
                                  <span className="navi-link-badge">
                                    <span className="label label-light-primary label-inline font-weight-bold">new</span>
                                  </span>
                                </a>
                              </li>
                              <li className="navi-item">
                                <a href="#" className="navi-link">
                                  <span className="navi-icon"><i className="flaticon2-bell-2"></i></span>
                                  <span className="navi-text">Calls</span>
                                </a>
                              </li>
                              <li className="navi-item">
                                <a href="#" className="navi-link">
                                  <span className="navi-icon"><i className="flaticon2-gear"></i></span>
                                  <span className="navi-text">Settings</span>
                                </a>
                              </li>

                              <li className="navi-separator my-3"></li>

                              <li className="navi-item">
                                <a href="#" className="navi-link">
                                  <span className="navi-icon"><i className="flaticon2-magnifier-tool"></i></span>
                                  <span className="navi-text">Help</span>
                                </a>
                              </li>
                              <li className="navi-item">
                                <a href="#" className="navi-link">
                                  <span className="navi-icon"><i className="flaticon2-bell-2"></i></span>
                                  <span className="navi-text">Privacy</span>
                                  <span className="navi-link-badge">
                                    <span className="label label-light-danger label-rounded font-weight-bold">5</span>
                                  </span>
                                </a>
                              </li>
                            </ul>
                            {/* <!--end::Navigation--> */}
                          </div>
                      </div>
                      {/* <!--end::Dropdown Menu--> */}
                    </div>
                  </div>
                  {/* <!--end::Header--> */}

                  {/* <!--begin::Body--> */}
                  <div className="card-body">
                    {/* <!--begin::Scroll--> */}
                    <div className="scroll scroll-pull ps ps--active-y body-height" data-mobile-height="350" >
                      {/* <!--begin::Messages--> */}
                      <div className="messages">
                        {/* <!--begin::Message In--> */}
                        <div className="d-flex flex-column mb-3 align-items-start">
                          <div className="d-flex align-items-center">
                            <div className="symbol symbol-circle symbol-40 mr-3">
                              <img alt="Pic" src="/images/300_12.jpg" />
                            </div>
                            <div>
                              <a href="#" className="text-dark-75 text-hover-primary font-weight-bold font-size-h6">Matt Pears</a>
                              <span className="text-muted font-size-sm">2 Hours</span>
                            </div>
                          </div>
                          <div className="mt-2 rounded p-2 bg-light-success text-dark-50 font-weight-bold font-size-lg text-left max-w-400px">
                            How likely are you to recommend our company
                            to your friends and family?
                          </div>
                        </div>
                        {/* <!--end::Message In--> */}

                        {/* <!--begin::Message Out--> */}
                        <div className="d-flex flex-column mb-5 align-items-end">
                          <div className="d-flex align-items-center">
                            <div>
                              <span className="text-muted font-size-sm">3 minutes</span>
                              <a href="#" className="text-dark-75 text-hover-primary font-weight-bold font-size-h6">You</a>
                            </div>
                            <div className="symbol symbol-circle symbol-40 ml-3">
                              <img alt="Pic" src="/images/300_12.jpg" />
                            </div>
                          </div>
                          <div className="mt-2 rounded p-2 bg-light-primary text-dark-50 font-weight-bold font-size-lg text-right max-w-400px">
                            Hey there, we’re just writing to let you know
                            that you’ve been subscribed to a repository on GitHub.
                          </div>
                        </div>
                        {/* <!--end::Message Out--> */}
                      </div>
                      {/* <!--end::Messages--> */}
                      {/* <div className="ps__rail-x left-0 bottom-0">
                        <div className="ps__thumb-x left-0 width-0"></div>
                        </div>
                        <div className="ps__rail-y top-0 h-289">
                          <div className="ps__thumb-y top-0 h-79"></div>
                          </div> */}
                          </div>
                    {/* <!--end::Scroll--> */}
                  </div>
                  {/* <!--end::Body--> */}

                  {/* <!--begin::Footer--> */}
                  <div className="card-footer align-items-center position-relative">
                    {/* <!--begin::Compose--> */}
                    <textarea className="form-control mb-2" rows={2} placeholder="Type a message"></textarea>
                    <div className="d-flex align-items-center justify-content-between mt-5+2 chat-textarea">
                      <div className="mr-3">
                        <a href="#" className="btn btn-clean btn-icon btn-md mr-1">
                         <ImageIcon color="action" />
                        </a>
                        <a href="#" className="btn btn-clean btn-icon btn-md">
                        <CameraAltIcon color="action" />
                          </a>
                      </div>
                      <div>
                        <button type="button" className="btn btn-primary btn-md text-uppercase font-weight-bold chat-send py-2 px-6">
                        Send
                          </button>
                      </div>
                    </div>
                    {/* <!--begin::Compose--> */}
                  </div>
                  {/* <!--end::Footer--> */}
                </div>
                {/* <!--end::Card--> */}
              </div>
              {/* <!--end::Content--> */}
            </div>
            {/* <!--end::Chat--> */}
          </div>
          {/* <!--end::Container--> */}
        </div>
        {/* <!--end::Entry--> */}
      </div>
      </>
  )
}

export default ChatAndMeet
