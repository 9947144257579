import { Form, Formik } from 'formik';
import { Box, Grid } from '@mui/material';
import CustomGrid from 'components/uiComponents/CustomGrid';
import CustomButtonGrid from 'components/uiComponents/CustomButtonGrid';
import FormikControl from 'components/wrapperComponents/FormikControl';
import { useEffect, useState } from 'react';
import { UploadFileService } from 'services/shared/UploadFileService';
import { msgTypes } from 'constants/msgTypes';
import { InvoiceManagementModel } from 'model/screensModels/InvoiceManagementModel';
import { InvoiceManagementService } from 'services/menu/invoiceManagementService/InvoiceManagementService';
import { InvoiceManagementSchema } from 'schemas/menu/invoiceManagementSchema/InvoiceManagementSchema';
import './createInvoice.scss';
import { UtilService } from 'services/shared/UtilService';
import { LocalStorageService } from 'services/auth/LocalStorageService';




const CreateInvoice = ({ setFlag, updateRecord }) => {
  const [viewMode, setViewMode] = useState(false);
  const [initialValue, setInitialValue] = useState(new InvoiceManagementModel());

  const [companyList, setCompanyList] = useState([]);

  const loadCompany = async () => {
    const res = await UtilService.getCompanyList();
    if (res.statusMsg === msgTypes.SUCCESS.msg) {
      setCompanyList(res.data);
    }
  }


  const InvoiceList = [
    { "key": msgTypes.INVOICE_STATUS.PAID, "value": msgTypes.INVOICE_STATUS.PAID },
    { "key": msgTypes.INVOICE_STATUS.OUTSTANDING, "value": msgTypes.INVOICE_STATUS.OUTSTANDING },
    { "key": msgTypes.INVOICE_STATUS.OVERDUE, "value": msgTypes.INVOICE_STATUS.OVERDUE }
  ]


  useEffect(() => {
    loadCompany();
    if (updateRecord) {
      setInitialValue(updateRecord)
      //values = updateRecord;
    }
  }, [updateRecord])



  //Prepare Data for API
  const prepareRequestBody = async (values: InvoiceManagementModel) => {
    const prepareData = new InvoiceManagementModel();

    //to assing id based on action 
    if (("" + initialValue.invoiceId)?.length > 0) {
      prepareData.invoiceId = initialValue.invoiceId;
    } else {
      prepareData.invoiceId = values.invoiceId ? values.invoiceId : '';
    }

    prepareData.fromCompanyId = values.fromCompanyId ? values.fromCompanyId : '';
    prepareData.subject = values.subject ? values.subject : '';
    prepareData.status = values.status ? values.status : '';

    const loginDetails = JSON.parse(await LocalStorageService.getUserDetails());
    prepareData.toCompanyId = loginDetails.companyId;

    prepareData.uploadedInvoice = values.uploadedInvoice ? values.uploadedInvoice : "";

    // if (values.uploadedInvoice) {
    //   const formData = new FormData();
    //   formData.append("file", values.uploadedInvoice);
    //   const imageurlData = await UploadFileService.getImagePath(formData);
    //   prepareData.uploadedInvoice = imageurlData?.data?.fileName;
    // } else {
    //   prepareData.uploadedInvoice = '';
    // }

    return prepareData;

  }

  const addInvoice = async (values: InvoiceManagementModel) => {
    const requestData = await prepareRequestBody(values);
    const res = await InvoiceManagementService.addInvoice(requestData);
    if (res.statusMsg === msgTypes.SUCCESS.msg) {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      <div className='createinvoice-card'>
        <Formik
          enableReinitialize={true}
          initialValues={initialValue}
          validationSchema={InvoiceManagementSchema}
          onSubmit={async (values, { resetForm }) => {
            const res = await addInvoice(values);
            if (res) {
              resetForm();
              setFlag(false);
            }
          }}
        >
          {
            ({ isValid, setFieldValue, dirty, isSubmitting, values }) => (
              <Form>
                <Box bgcolor={'#fff'} height={'74vh'}>
                  <Grid container spacing={1} paddingTop={4} marginTop={'1px'} paddingBottom={2} paddingRight={'5px'} paddingLeft={'5px'}>

                    <CustomGrid
                      item={
                        <FormikControl control='autocomplete'
                          label="Company Name"
                          name="fromCompanyId"
                          options={companyList}
                          keyfield="companyName"
                          valuefield="companyId"
                        />
                      } />

                    <CustomGrid
                      item={
                        <FormikControl control='textfield'
                          label="Subject"
                          name="subject"
                          placeholder="Enter subject"
                          disabled={viewMode}
                        />
                      } />

                    <CustomGrid
                      item={
                        <FormikControl
                          control='autocomplete'
                          label="Invoice Status"
                          name="status"
                          placeholder="Choose invoice status"
                          requiredField
                          options={InvoiceList}
                          keyfield="key"
                          valuefield="value"

                        />
                      }
                    />
                    <CustomGrid
                      item={
                        <FormikControl control='uploadImage'
                          label="Upload invoice"
                          requiredField
                          name="uploadedInvoiceFile"
                          nameUrl="uploadedInvoice"
                          disabled={viewMode}
                        />
                      } />

                    {!viewMode ? (
                      <CustomButtonGrid
                        item={
                          <>
                            <FormikControl control='button' color="primary" label={values.invoiceId === '' ? "Save" : "Update"} type="submit" disabled={!dirty || !isValid} />
                            <FormikControl control='button' color="warning" label="Back" type="reset" onClick={() => { setFlag(false) }} />
                            {/* <div className="form-btn">
                              <a href="#" className="btn btn-danger" onClick={resetScreen}>Cancel</a>
                            </div> */}
                          </>
                        } />
                    ) : null}

                  </Grid>

                </Box>
              </Form>
            )
          }
        </Formik>
      </div >
    </>
  )
}
export default CreateInvoice;


