import React, { useContext, useState } from 'react'
import { ErrorMessage, Field, useFormikContext } from "formik";
import MuiLabel from "./MuiLabel";
import ReactQuill from "react-quill";
import { Box } from "@mui/material";
import TextError from './TextError';
//import LabsContext from '../../providers/LabsProvider';


function RichTextEditor(props) {
  const { label, name, ...rest } = props;
  const [textValue, setTextValue] = useState('');
  const { setFieldValue } = useFormikContext();

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      ["blockquote", "code-block"],
      ["link", "image", "video", "formula"],
      [{ header: 1 }, { header: 2 }],
      [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ direction: "rtl" }],
      [{ size: ["small", false, "large", "huge"] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ align: [] }],
      ["clean"],
    ],
  };

  const handleChange =(value) =>{
    setFieldValue(name, value)
  }

  return (
    <>
     <Field name={name} {...rest}>
      {({ field }) => {
        return (
          <React.Fragment>
            <MuiLabel {...props} />
            <ReactQuill id={name} {...rest} {...field}
              theme="snow"
              modules={modules}
              fullWidth 
              size='small' 
              autoComplete='off' 
              value={field.value} 
              onChange={handleChange}
            />
            <Box className='error-box'>
              <ErrorMessage name={name} component={TextError} />
            </Box>
          </React.Fragment>
        );
      }}

    </Field>

    </>
  );
}

export default RichTextEditor