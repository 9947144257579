import { Form, Formik } from "formik";
import { Box, Button, Grid } from "@mui/material";
import CustomGrid from "components/uiComponents/CustomGrid";
import CustomButtonGrid from "components/uiComponents/CustomButtonGrid";
import FormikControl from "components/wrapperComponents/FormikControl";
import { useEffect, useRef, useState } from "react";
import { UploadFileService } from "services/shared/UploadFileService";
import { BlogService } from "services/menu/blogService/BlogService";
import { msgTypes } from "constants/msgTypes";
import { CertificateModel } from "model/screensModels/CertificateModel";
import { CertificateSchema } from "schemas/menu/certificate/CertificateSchema";
import { UploadCertificateService } from "services/menu/uploadCertificate/UploadCertificateService";
import { UtilService } from "services/shared/UtilService";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import "./UploadCertificate.scss";
import GeneratedCertificate from "./GeneratedCertificate";

const UploadCertificate = ({ setIndex, updateRecord, setFlag }) => {
  const [viewMode, setViewMode] = useState(false);
  const [initialValue, setInitialValue] = useState(new CertificateModel());
  const [companyList, setCompanyList] = useState([]);
  const [pdfFile, setPdfFile] = useState(null);
  const [selectedCompanyName, setSelectedCompanyName] = useState("");
  const [flags, setFlags] = useState(false);

  const reportTemplateref = useRef(null);

  // const handleGeneratePdf = async () => {
  //   const input = reportTemplateref.current;
  //   try {
  //     const canvas = await html2canvas(input);
  //     const imgData = canvas.toDataURL("image/png");
  //     const pdf = new jsPDF({
  //       orientation: "landscape",
  //       unit: "px",
  //       format: "a4",
  //     });
  //     const width = pdf.internal.pageSize.getWidth();
  //     const height = (canvas.height * width) / canvas.width;

  //     pdf.addImage(imgData, "PNG", 0, 0, width, height);
  //     const file = new File([pdf.output("blob")], "certificate.pdf");
  //     setPdfFile(file);
  //   } catch (error) {
  //     console.error("Error generating PDF:", error);
  //   }
  // };

  const onSubmit = async (values) => {
    await uploadCertificate(values);
  };

  useEffect(() => {
    loadCompany();
  }, []);

  useEffect(() => {
    if (updateRecord) {
      setInitialValue(updateRecord);
    }
  }, [updateRecord]);

  const loadCompany = async () => {
    const res = await UtilService.getCompanyList();
    if (res.statusMsg === msgTypes.SUCCESS.msg) {
      setCompanyList(res.data);
    }
  };

  const changeTabIndex = () => {
    setIndex(0);
  };

  const prepareRequestBody = async (values) => {
    const prepareData = new CertificateModel();

    prepareData.certificateName = values.certificateName || "";
    prepareData.expireDate = values.expireDate || "";
    prepareData.image = "";

    if (pdfFile) {
      const formData = new FormData();
      formData.append("file", pdfFile);
      const imageurlData = await UploadFileService.getImagePath(formData);
      prepareData.image = imageurlData.data.fileName;
    }
    
    return prepareData;
  };

  const uploadCertificate = async (values) => {
    const requestData = await prepareRequestBody(values);
    const res = await UploadCertificateService.uploadCertificate(requestData);
    if (res.statusMsg === msgTypes.SUCCESS.msg) {
      changeTabIndex();
      setInitialValue(new CertificateModel());
    }
  };

  const resetScreen = () => {
    setInitialValue(new CertificateModel());
  };

  const generatePdf = () => {
    // handleGeneratePdf();
    setFlags(true);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValue}
      validationSchema={CertificateSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, dirty, isValid, values, errors }) => (
        <Form>
          <Grid
            container
            spacing={1}
            bgcolor={"#fff"}
            paddingTop={3}
            paddingBottom={50}
            paddingRight={"5px"}
            height={"74vh"}
            marginTop={"1px"}
            width={"100%"}
            marginLeft={"2px"}
          >
            <CustomGrid
              item={
                <FormikControl
                  control="textfield"
                  name="certificateName"
                  label="Certificate Name"
                  placeholder="Enter Certificate Name"
                  requiredField
                  disabled={viewMode}
                />
              }
            />
            <CustomGrid
              item={
                <FormikControl
                  control="autocomplete"
                  label="Company Name"
                  name="companyName"
                  placeholder="Select Debtor Company"
                  onChange={(value) => { setSelectedCompanyName(value.companyName) }}
                  options={companyList}
                  keyfield="companyName"
                  valuefield="companyId"
                />
              }
            />
            <CustomGrid
              item={
                <FormikControl
                  control="datepicker"
                  name="expireDate"
                  label="Certificate Expiry Date"
                  requiredField
                  disabled={viewMode}
                />
              }
            />

            {flags ? (
              <>
                <Box className="certificate-container">
                  <GeneratedCertificate
                    selectedCompanyName={selectedCompanyName}
                    values={values}
                  />
                </Box>

                <CustomButtonGrid
                  item={
                    <FormikControl
                      control="button"
                      color="primary"
                      label={values.certificateId === "" ? "Save" : "Update"}
                      type="submit"
                      disabled={!dirty || !isValid}
                    />
                  }
                />
              </>
            ) : (
              <CustomGrid lg={4} md={6}
                item={
                  <Box display="flex" alignItems="center">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={generatePdf}
                    >
                      Generate Certificate
                    </Button>
                  </Box>
                }
              />
            )}
          </Grid>
          <div className="d-none" ref={reportTemplateref}>
            <GeneratedCertificate
              values={values}
              selectedCompanyName={selectedCompanyName}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default UploadCertificate;
