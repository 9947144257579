import { RouteType } from "constants/RouteType";
import { NewsLettersModel } from "model/screensModels/NewsLettersModel";
import { AuthService } from "services/auth/AuthService";
import { PaginatorModel } from "model/utilModels/PaginatorModel";
import { msgTypes } from "constants/msgTypes";

export class NewsLettersService {
  // constructor() { }

  // Save News Letters

  static getNewsLettersList = async (requestBody: PaginatorModel) => {
    const url = RouteType.ADMIN + RouteType.NEWS_LETTERS;
    const response = AuthService.postCryptoRequest(url, JSON.stringify(requestBody));
    return response;
  };


  static addNewsLetters = async (requestBody: NewsLettersModel) => {
    const url = RouteType.ADMIN + RouteType.NEWS_LETTERS+ RouteType.STORE;
    const response = AuthService.postCryptoRequest(url, JSON.stringify(requestBody));
    return response;

  }

  
  static deleteNewsLetters = async (requestBody: NewsLettersModel) => {
    const url = RouteType.ADMIN + RouteType.NEWS_LETTERS+ RouteType.DELETE;
    const response = AuthService.postCryptoRequest(url, JSON.stringify(requestBody));
    return response;

    
  }

  static recentlyAddedNewsletters = async () => {
    const url = RouteType.ADMIN + RouteType.RECENT_NEWSLETTERS;
    const response = AuthService.postCryptoRequest(url, JSON.stringify(msgTypes.EMPTY_STR));
    return response;
    
  }
}