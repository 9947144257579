import { Box, Button, Grid, Stack } from '@mui/material';
import CustomGrid from 'components/uiComponents/CustomGrid';
import CustomButtonGrid from 'components/uiComponents/CustomButtonGrid';
import { useEffect, useMemo, useState } from 'react';
import { ResponseModel } from 'model/utilModels/ResponseModel';
import CustomTableGrid from 'components/uiComponents/CustomTableGrid';
import { PaginatorModel } from 'model/utilModels/PaginatorModel';
import showAlert, { conformationAlert } from 'services/shared/alert';
import { msgTypes } from 'constants/msgTypes';
import { Formik, Form } from 'formik'
import FormikControl from 'components/wrapperComponents/FormikControl';
import NoRecord from 'components/sharedComponent/noRecord/NoRecord';
import { BlogModel } from 'model/screensModels/BlogModel';
import { BlogService } from 'services/menu/blogService/BlogService';
import { BlogSchema } from 'schemas/menu/blog/BlogSchema';
import ApiUrl from 'services/shared/ApiUrl';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';


const BlogList = ({ setFlag, updateRecord }) => {

  const [blogList, setBlogList] = useState(new ResponseModel());
  const paginatorModel: PaginatorModel = new PaginatorModel();
  const [pagination, setPagination] = useState({
    pageSize: paginatorModel.pageSize,
    pageNumber: paginatorModel.pageNumber,
    filter: "",
    orderBy: "",
    desc:"",
  })

  const columns = useMemo(() =>
    [
      {
        header: 'Image',
        Cell: ({ renderedCellValue, row }) => (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <img alt="avatar" height={35} width={35} src={row.original.image ? ApiUrl.fileBaseUrl+row.original.image+"/" : "/image/userimage.jpg"} loading="lazy" />
          </Box>
        ),
        size: 50
      },
      {
        accessorKey: "title",
        header: "Title",
        size:150
      },
      {
        accessorKey: "shortDescription",
        header: "Short Description",
        size: 200
      },
      {
        accessorKey: "description",
        header: "Description"
       
      },
    ], []);

  // //Begin:: UseEffect Hooks 

  useEffect(() => {
      getBlogList();
  }, [pagination.pageNumber, pagination.pageSize, pagination.filter, pagination.orderBy, pagination.desc])

  //End:: UseEffect Hooks 


  //Prepare request body to get blog list
  const prepareRequest = () => {
    const requestBody = new PaginatorModel();
    requestBody.pageNumber = pagination.pageNumber;
    requestBody.pageSize = pagination.pageSize;
    requestBody.filter = pagination.filter;
    requestBody.orderBy = pagination.orderBy;
    requestBody.desc = pagination.desc;
    return requestBody;
  }

  //Get Blog list
  const getBlogList = async () => {
    const requestBody = prepareRequest();
     const res = await BlogService.getBlogList(requestBody);  
      if (res.statusMsg === msgTypes.SUCCESS.msg) {
         setBlogList(res);  
         //setBlogList(undefined)
      }  
   }


  

  //Open Form To edit Record
  const editRecord = (record: BlogModel) => {
    updateRecord(record);
    setFlag(true)
  }

  const deleteRecord = async (record: BlogModel) => {
    const requestData: BlogModel = new BlogModel();
    requestData.blogId = record.blogId

    const deleteFlag = await conformationAlert(msgTypes.CONFORMATION_MESSAGE.DELETE_RECORD, msgTypes.CONFORMATION_ICON.ERROR);
    if(deleteFlag) {
      const res = await BlogService.deleteBlog(requestData);
      if (res.statusMsg === msgTypes.SUCCESS.msg) {
        getBlogList();
      }
    }
  }


  const initialValues = {}
  const onSubmit = () => {
    getBlogList();
  }

  

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={BlogSchema}
      onSubmit={onSubmit}
    >
      {
        formik =>
        (
          <Form>
                    <CustomTableGrid
                      item={
                      <FormikControl 
                          control='materialTable'
                          tableColumn={columns}
                          tableData={blogList}
                          formikValue={formik}
                          setPagination={setPagination}
                          actions={[{ type: "icon", icon: <EditIcon />, actionFunc: editRecord },
                            { type: "icon", icon: <DeleteIcon color='error' />, actionFunc: deleteRecord }
                          ]}
                       />
                      }
                    />
          </Form>
        )
      }
    </Formik>
  )
}
export default BlogList;
