import { RouteType } from "constants/RouteType";
import { AnnouncementModel } from "model/screensModels/AnnouncementModel";
import { PaginatorModel } from "model/utilModels/PaginatorModel";
import { AuthService } from "services/auth/AuthService";

export class AnnouncementService {
    
     //Save Announcement
     static saveAnnouncement = async (requestBody: AnnouncementModel) => {
      const url = RouteType.ADMIN + RouteType.ANNOUNCEMENT + RouteType.STORE;
      const response = AuthService.postCryptoRequest(url, JSON.stringify(requestBody));
      return response;
    };


     static getAnnouncementList = async (requestBody: PaginatorModel) => {
      const url = RouteType.ADMIN + RouteType.ANNOUNCEMENT;
      const response = AuthService.postCryptoRequest(url, JSON.stringify(requestBody));
      return response;
    };


     static deleteAnnouncement = async (requestBody: AnnouncementModel) => {
      const url = RouteType.ADMIN + RouteType.ANNOUNCEMENT + RouteType.DELETE;
      const response = AuthService.postCryptoRequest(url, JSON.stringify(requestBody));
      return response;
    };
}