import React from "react";
import { Box, Button, Grid, Stack } from '@mui/material';

import { useEffect, useMemo, useState } from 'react';
import { ResponseModel } from 'model/utilModels/ResponseModel';
import CustomTableGrid from 'components/uiComponents/CustomTableGrid';
import { PaginatorModel } from 'model/utilModels/PaginatorModel';
import showAlert, { conformationAlert } from 'services/shared/alert';
import { msgTypes } from 'constants/msgTypes';
import { Formik, Form } from 'formik'
import FormikControl from 'components/wrapperComponents/FormikControl';
import NoRecord from 'components/sharedComponent/noRecord/NoRecord';

import ApiUrl from 'services/shared/ApiUrl';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { NewsLettersModel } from "model/screensModels/NewsLettersModel";
import { NewsLettersService } from "services/menu/newsLettersService/NewsLettersService";
import { NewsLettersSchema } from "schemas/menu/newsLetters/NewsLettersSchema";


const NewsLettersList = ({setFlag , updateRecord}) => {

  const [newsLetterList, setNewsLetterList] = useState(new ResponseModel());
  const [noRecord, setNoRecord] = useState(false);
  const paginatorModel: PaginatorModel = new PaginatorModel();
  const [pagination, setPagination] = useState({
    pageSize: paginatorModel.pageSize,
    pageNumber: paginatorModel.pageNumber,
    filter: "",
    orderBy: "",
    desc:"",
  })



  useEffect(() => {
    getNewsLettersList();

}, [pagination.pageNumber, pagination.pageSize, pagination.filter, pagination.orderBy, pagination.desc])

const prepareRequest = () => {
  const requestBody = new PaginatorModel();
  requestBody.pageNumber = pagination.pageNumber;
  requestBody.pageSize = pagination.pageSize;
  requestBody.filter = pagination.filter;
  requestBody.orderBy = pagination.orderBy;
  requestBody.desc = pagination.desc;
  return requestBody;
}



const getNewsLettersList = async () => {
  const requestBody = prepareRequest();
   const res = await NewsLettersService.getNewsLettersList(requestBody);  
    if (res.statusMsg === msgTypes.SUCCESS.msg) {
      setNewsLetterList(res);  
    
    }  
 }


 const editRecord = (record: NewsLettersModel) => {
  updateRecord(record);
  setFlag(true)
}

const deleteRecord = async (record: NewsLettersModel) => {
  const requestData: NewsLettersModel = new NewsLettersModel();
  requestData.newsLetterId = record.newsLetterId

  const deleteFlag = await conformationAlert(msgTypes.CONFORMATION_MESSAGE.DELETE_RECORD, msgTypes.CONFORMATION_ICON.ERROR);
  if(deleteFlag) {
    const res = await NewsLettersService.deleteNewsLetters(requestData);
    if (res.statusMsg === msgTypes.SUCCESS.msg) {
      getNewsLettersList();
    }
  }
}



const initialValues = {}
const onSubmit = () => {
  getNewsLettersList();
}


const columns = useMemo(() =>
  [
    {
      header: 'Image',
      Cell: ({ renderedCellValue, row }) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <img alt="avatar" height={35} width={35} src={row.original.image ? ApiUrl.fileBaseUrl+row.original.image+"/" : "/image/userimage.jpg"} loading="lazy" />
        </Box>
      ),
      size: 50
    },
    {
      accessorKey: "title",
      header: "Title",
      size:150
    },
    {
      accessorKey: "description",
      header: "Description"
     
    },
 
   
  ], []);
  return (
    <>
      <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={NewsLettersSchema}
      onSubmit={onSubmit}
    >
      {
        formik =>
        (
          <Form>
                    <CustomTableGrid
                      item={
                      <FormikControl 
                          control='materialTable'
                          tableColumn={columns}
                          tableData={newsLetterList}
                          formikValue={formik}
                          setPagination={setPagination}
                          actions={[{ type: "icon", icon: <EditIcon />, actionFunc: editRecord },
                            { type: "icon", icon: <DeleteIcon color='error' />, actionFunc: deleteRecord }
                          ]}
                       />
                      }
                    />
          </Form>
        )
      }
    </Formik>
    </>
  );
};

export default NewsLettersList;
