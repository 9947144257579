import { Box, styled } from '@mui/material'
import React from 'react'

const ErrorDiv = styled(Box)({
    fontSize: "12px",
    color: "#f1416c",
    margin: 0,
    height: "14px",
    textAlign: "left"
  })
  
export const CustomErrorDiv = (props) => {
  return (
    <ErrorDiv >
        {props.propsValue.error && props.propsValue.touched ? props.propsValue.error : undefined}
    </ErrorDiv>
  )
}
