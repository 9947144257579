import React, { useState } from 'react'
import Box from '@mui/material/Box';
import { AnnouncementModel } from 'model/screensModels/AnnouncementModel';
import { AnnouncementList } from './AnnouncementList';
import { CreateAnnouncement } from './CreateAnnouncement';


export const Announcement = () => {

    const [updateValue, setUpdateValue] = React.useState(new AnnouncementModel());
    const [flag, setFlag] = useState(false)

   
    const getUpdateRecordValue = (announcementModel: AnnouncementModel) =>{
      setUpdateValue(announcementModel);
    }

    const addAnnouncement = () =>{
      setUpdateValue(new AnnouncementModel());
      setFlag(true)
    }

  return (
    
    <Box sx={{ width: '100%' }} className="container-subheaders">
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <div className='content-subheader'>
        {!flag ?<h4 className='mr-3'> Announcement List</h4> :<h4 className='mr-3'> Create Announcement</h4>}
          {!flag ?
          (<button className='btn primary' onClick={addAnnouncement}>Add Announcement</button>)
          :""}
        </div>
      </Box>
      <div className="sub-content-sec">
      {flag ? 
      (<CreateAnnouncement setFlag= {setFlag} updateRecord={updateValue}></CreateAnnouncement> )
      :(<AnnouncementList setFlag= {setFlag} updateRecord={getUpdateRecordValue}></AnnouncementList>)
       }
       </div>
       
    </Box>
    
  )
}
