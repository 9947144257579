export class CertificateModel {
  certificateId: string;
  certificateName: string;
  expireDate: string;
  image: any;
  companyName: string;

  constructor() {
    this.certificateId = "";
    this.certificateName = "";
    this.expireDate = "";
    this.image = null;
    this.companyName = "";
  }
}