
import { Box, Button, Grid, Stack } from '@mui/material';
import CustomGrid from 'components/uiComponents/CustomGrid';
import CustomButtonGrid from 'components/uiComponents/CustomButtonGrid';
import { useEffect, useState ,useMemo } from 'react';
import { ResponseModel } from 'model/utilModels/ResponseModel';
import CustomTableGrid from 'components/uiComponents/CustomTableGrid';
import { PaginatorModel } from 'model/utilModels/PaginatorModel';
import showAlert, { conformationAlert } from 'services/shared/alert';
import { msgTypes } from 'constants/msgTypes';
import { Formik, Form } from 'formik'
import FormikControl from 'components/wrapperComponents/FormikControl';
import NoRecord from 'components/sharedComponent/noRecord/NoRecord';
import { useAuth } from 'util/auth';
import ReactTable from 'components/wrapperComponents/ReactTable';
import { BlogModel } from 'model/screensModels/BlogModel';
import { BlogService } from 'services/menu/blogService/BlogService';
import ApiUrl from 'services/shared/ApiUrl';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { UploadCertificateService } from 'services/menu/uploadCertificate/UploadCertificateService';
import { CertificateModel } from 'model/screensModels/CertificateModel';
import { CertificateSchema } from 'schemas/menu/certificate/CertificateSchema';


const CertificateList = ({ setIndex, updateRecord, setFlag }) => {

  const [certificateList, setCertificateList] = useState(new ResponseModel());
  const [loading, setLoading] = useState(false);
  const [noRecord, setNoRecord] = useState(false);
  const [selectedValues, setSelectedValues] = useState(undefined)
  const paginatorModel: PaginatorModel = new PaginatorModel();
  const [pagination, setPagination] = useState({
    pageSize: paginatorModel.pageSize,
    pageNumber: paginatorModel.pageNumber,
    filter: "",
    orderBy: "",
    desc:"",
  })



  
  
  const columns = useMemo(() =>
    [
      {
        header: 'Image',
        Cell: ({ renderedCellValue, row }) => (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <img alt="avatar" height={35} width={35} src={row.original.image ? ApiUrl.fileBaseUrl+row.original.image+"/" : "/image/userimage.jpg"} loading="lazy" />
          </Box>
        ),
        size: 50
      },
      {
        accessorKey: "certificateName",
        header: "Certificate Name",
        size:150
      },
      {
        accessorKey: "expireDate",
        header: "Expire Date",
        size: 200
      },
      {
        accessorKey: "uploadingDate",
        header: "Uploading Date",
        size: 200
      },
     
      {
        accessorKey: "companyName",
        header: "Company Name"
       
      },
    ], []);

  //Begin:: UseEffect Hooks 

  useEffect(() => {
    getCertificateList();
}, [pagination.pageNumber, pagination.pageSize, pagination.filter, pagination.orderBy, pagination.desc])



  useEffect(() => {
    if (selectedValues)
      getCertificateList();
  }, [pagination.pageNumber, pagination.pageSize])

  //End:: UseEffect Hooks 


  const changeTabIndex = () => {
    setIndex(1);
  }


  //Prepare request body to get blog list
  const prepareRequest = () => {
    const requestBody = new PaginatorModel();
    requestBody.pageNumber = pagination.pageNumber;
    requestBody.pageSize = pagination.pageSize;
    requestBody.filter = pagination.filter;
    requestBody.orderBy = pagination.orderBy;
    requestBody.desc = pagination.desc;
    return requestBody;
  }

  //Get Certificate list
  const getCertificateList = async () => {
    const requestBody = prepareRequest();
    const res = await UploadCertificateService.getCertificateList(requestBody);
    if (res.statusMsg === msgTypes.SUCCESS.msg) {
      setCertificateList(res)
    }
  }







  //Open Form To edit Record
  const editRecord = (record: CertificateModel) => {
    updateRecord(record);
    changeTabIndex();
  }

  const deleteRecord = async (record: CertificateModel) => {
    const requestData: CertificateModel = new CertificateModel();
    requestData.certificateId = record.certificateId

    const deleteFlag = await conformationAlert(msgTypes.CONFORMATION_MESSAGE.DELETE_RECORD, msgTypes.CONFORMATION_ICON.ERROR);
    if(deleteFlag) {
      const res = await UploadCertificateService.deleteCertificate(requestData);
      if (res.statusMsg === msgTypes.SUCCESS.msg) {
        getCertificateList();
      }
    }
  }


  const initialValues = {}
  const onSubmit = () => {
    getCertificateList();
  }

  return (
<Formik
enableReinitialize={true}
initialValues={initialValues}
validationSchema={CertificateSchema}
onSubmit={onSubmit}
>
{
  formik =>
  (
    <Form>
              <CustomTableGrid
                item={
                <FormikControl 
                    control='materialTable'
                    tableColumn={columns}
                    tableData={certificateList}
                    formikValue={formik}
                    setPagination={setPagination}
                    actions={[{ type: "icon", icon: <EditIcon />, actionFunc: editRecord },
                      { type: "icon", icon: <DeleteIcon color='error' />, actionFunc: deleteRecord }
                    ]}
                 />
                }
              />
    </Form>
  )
}
</Formik>
  )
}
export default CertificateList;
