import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CompanyServicesModel } from "model/screensModels/CompanyServicesModel";
import { CompanyServicesService } from "services/menu/companyServicesService/CompanyServicesService";
import { msgTypes } from "constants/msgTypes";
import CardActionArea from '@mui/material/CardActionArea';
import './CompanyServices.scss'

const TRUNCATE_LENGTH = 150; 

function CompanyServices({ companyId }) {
  const [serviceList, setServiceList] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  useEffect(() => { 
    if(companyId!=undefined)
    getCompanyServiceList();
  }, [companyId]);


  
  const getCompanyServiceList = async () => {
    const requestBody = new CompanyServicesModel();
    requestBody.companyId = companyId;
    const res = await CompanyServicesService.getCompanyServiceListById(requestBody);
   
    if (res.statusMsg === msgTypes.SUCCESS.msg) {
      setServiceList(res.data);
    }
  };

  const slidesToShow = 4;
  const hasEnoughSlides = serviceList.length >= slidesToShow;

  const settings = {
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: hasEnoughSlides ? slidesToShow : serviceList.length,
    slidesToScroll: hasEnoughSlides ? 1 : 0,
    arrows: false,
  };

  const handleOpenDialog = (service) => {
    setSelectedService(service);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedService(null);
  };

  return (
    <>
    {serviceList?(
    <Box className="featured-member">
      <Grid item className="main-grid">
        {serviceList.length === 0 ? (
          <Typography variant="h6" align="center">
           Company services Not Avilable !
          </Typography>
        ) : (
          <Slider {...settings}>
            {serviceList.map((data, index) => (
              <div key={index}>
                <Card sx={{ maxWidth: 340, height: 150 }} className="mr-2 carddesign">
                  <CardActionArea className="mr-2 carddesign" onClick={() => handleOpenDialog(data)}>
                    <CardContent style={{ height: "150px" }}>
                      <Typography
                        className="d-flex justify-content-center text-decoration-underline"
                        gutterBottom
                        variant="h5"
                        component="div"
                      >
                        {data.title}
                      </Typography>
                      <Typography
                        className="heading-2-text"
                        style={{ marginLeft: "10px" }}
                      >
                        {data.description.length > TRUNCATE_LENGTH
                          ? `${data.description.substring(0, TRUNCATE_LENGTH)}...`
                          : data.description}
                      </Typography>
                      {data.description.length > TRUNCATE_LENGTH && (
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleOpenDialog(data);
                          }}
                          style={{ marginLeft: "10px", textTransform: "none" }}
                        >
                          Read More
                        </Button>
                      )}
                    </CardContent>
                  </CardActionArea>
                </Card>
              </div>
            ))}

<Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
          <DialogTitle className="d-flex justify-content-center">
            {selectedService?.title}
          </DialogTitle>
          <DialogContent>
            <Typography>{selectedService?.description}</Typography>
          </DialogContent>
        </Dialog>
          </Slider>
        )}

       
      </Grid>
    </Box>
  ):""}
  </>
  );
}

export default CompanyServices;
