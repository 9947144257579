import { InputLabel } from '@mui/material';
import React from 'react'

export const CustomInputLabel = (props) => {
    const propsValue = props.propsValue;
    const requiredColour = () =>{
      return (
        <span style={{color: 'Red', fontWeight: 'bold', fontSize: 15}}> *</span>
      )
    }
  return (
    <InputLabel 
                variant="standard"
                sx={{textAlign: "left", fontWeight: "500" , color: "#000000"}}>
                {propsValue.label} 
                {propsValue.requiredField ? requiredColour() : ''}
    </InputLabel>
  )
}
