import { Box } from "@material-ui/core";
import { Button, styled } from "@mui/material";

const StyledButton = styled(Button)`
  ${({ theme }) => `
  cursor: pointer;
  transition: ${theme.transitions.create(['background-color', 'transform'], {
    duration: theme.transitions.duration.standard,
  })};
  &:hover {
    transform: scale(1.03);
  }
  `}
`;

const CustomButton = (props) => {
    return (
        <StyledButton
            variant={props.variant? props.variant: "contained"}
            color={props.color}
            fullWidth = {props.fullWidth ? props.fullWidth : false}
            disabled = {props.disabled}
            type = {props.submitType ? ( props.submitType===true? "submit": props.submitType) : undefined}
            onClick = {props.onClick}
            sx={props.style}
            >
            {props.label}
        </StyledButton>
    )
}
export default CustomButton;