import React from 'react'
import { useState, useEffect } from 'react';
import {
  MRT_GlobalFilterTextField,
  MRT_TableContainer,
  MRT_TablePagination,
  MRT_Row,
  useMaterialReactTable
} from 'material-react-table';
import { Box, Button, Grid, IconButton, Paper, Typography } from '@mui/material';
import CustomSwitch from 'components/uiComponents/CustomSwitch';
import TablePagination from '@mui/material/TablePagination';
import NoRecord from 'components/sharedComponent/noRecord/NoRecord';
import MuiSpinner from './MuiSpinner';
import { msgTypes } from 'constants/msgTypes';
import { green } from '@material-ui/core/colors';
import CustomButtonGrid from 'components/uiComponents/CustomButtonGrid';

import { PaginatorModel } from 'model/utilModels/PaginatorModel';
import FormikControl from './FormikControl';

function ReactTable(props) {
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [loaderFlag, setLoaderFlag] = useState(true);
  const [blankData, setBlankData] = useState(true);
  const [displayPriority, setDisplayPriority] = useState(false);
  const { loading } = props;


  useEffect(() => {
    if (props.formikValue)
      props.onPageChange(page, rowsPerPage, props.formikValue.values)
    else
      props.onPageChange(page, rowsPerPage)
  }, [page, rowsPerPage])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  useEffect(() => {
    try {
      setBlankData(props.tableData.data.tbody.length !== 0 ? false : true);
      if (props.tableData.data.thead) {
        setColumns([])
        setData(props.tableData.data.tbody);
        setColumns(props.tableData.data.thead);
        if (props.tableData.data.tbody || props.tableData.data.thead) {
          setLoaderFlag((loader) => loader = false)
        }
      }
      if (props.tableData.data.tfoot) {
        setRowCount(props.tableData.data.tfoot.totalListSize);
      }
    } catch (e) {
      console.log("error", e);
    }
  }, [props.tableData]);
  columns.forEach((obj, index) => {
    obj.accessorKey = obj.columnDef;
  });

  //To add serial number in table
  let count = 0;
  columns.forEach(head => {
    if (head.columnDef === 'sno') {
      count++;
    }
  })
  if (count === 0)
    columns.push({ header: "S.No.", columnDef: "sno", accessorKey: "sno", id: "sno", columnDefType: "data", size: 100, })

  for (let i = 0; i < data.length; i++) {
    data[i].sno = (i + 1) + (rowsPerPage * page);
  }
  //End serial number 



  //Set Priority related works
  const setPriorityClick = () => {
    setDisplayPriority(previousValue => !previousValue);
  }


  const createAction = (row) => {
    if (props.actions) {
      const { actions } = props;
      return (
        <React.Fragment>
          <Box display={"Flex"}>
            {actions.map(element => {
              if (element.type === msgTypes.LOADMONEY && row.status === msgTypes.APPROVED && element.columnDef === msgTypes.COLUMNDEF_APPROVE) {
                return <></>
              } else if (element.type === msgTypes.LOADMONEY && row.status === msgTypes.REJECTED) {
                return <></>
              } else if (element.type === 'switch') {
                return (
                  <Box onClick={() => { if (!element.disabled) element.actionFunc(row) }}>
                    <CustomSwitch
                      name="isActive"
                      disabled={element.disabled}
                      value={row.isActive}
                      formikValue={props.formikValue}
                    />
                  </Box>
                )
              }
              else if (element.type === 'checkbox') {
                return (
                  <Box onClick={() => { if (!element.disabled) element.actionFunc(row) }}>
                    <input
                      type="checkbox"
                      name="selected"
                      value={row.selected}
                    />
                  </Box>
                )
              }
              else {
                return (
                  // <Box onClick={() => element.actionFunc(row)} > {element.icon} </Box>
                  <IconButton
                    color="primary"
                    sx={{ padding: "1px" }}
                    onClick={() =>
                      element.actionFunc(row)
                    }
                  >
                    {element.icon}
                  </IconButton>
                )
              }
            })}
          </Box>
        </React.Fragment>
      )
    }
  }

  const table = useMaterialReactTable({
    columns,
    data,

    //Apply loading if data is not loaded
    state: { isLoading: loaderFlag },

    enableColumnActions: false,

    //to apply ordering 
    enableColumnOrdering: true,

    //To make our header sticky
    enableStickyHeader: true,

    //stop default pagination because we use MUI table pagination to navigate the page.
    enablePagination: false,

    //resize the column
    enableColumnResizing: true,
    columnResizeMode: 'onEnd',

    layoutMode: "semantic",
    //to disable drag option of column header
    enableColumnDragging: false,

    //to display serial number
    // enableRowNumbers: true,
    // rowNumberDisplayMode: 'dynamic',

    //to display Action buttons
    enableRowActions: props.actions ? true : false,
    positionActionsColumn: props.positionActionsColumn,
    renderRowActions: ({ row }) => [createAction(row.original)],


    layoutMode: 'grid-no-grow',

    //To make column sticky
    //enableColumnPinning: false,

    //To apply basic initial values
    initialState: {
      showGlobalFilter: true,
      columnPinning: props.positionActionsColumn === 'last' ? { right: ['mrt-row-actions'] } : "",
      density: 'compact',
    },


    autoResetPageIndex: props.dragAndDrop ? false : true,
    enableRowOrdering: props.dragAndDrop ? props.dragAndDrop : false,
    muiRowDragHandleProps: ({ table, row }) => props.dragAndDrop ? ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = table.getState();
        if (hoveredRow && draggingRow) {
          draggingRow.color = "red"
          data.splice(
            (hoveredRow).index, 0, data.splice(draggingRow.index, 1)[0],);
          setData([...data]);
        }
      },
      color: 'warning',
      disabled: displayPriority ? false : true
    }) : null,

    // onDraggingRowChange:{
    //   color: 'warning',
    // },
    muiTableHeadCellProps: {
      sx: {
        border: '1px solid rgba(81, 81, 81, 0)',
      },
    },

    muiTableBodyCellProps: {
      sx: {
        border: '1px solid rgba(81, 81, 81, .1)',
        boxShadow: 'none'
      },
    }

  });

  return (

    <>
      {!loading ? (
        <>
          {
            !blankData ? (
              <Paper sx={{
                backgroundColor: 'inherit',
                justifyContent: 'space-between',
                padding: '20px 14px',
                flexDirection: 'row',
                gap: '16px',
                boxShadow: 'none',
                backgroundColor: '#ffffff',
                '@media max-width: 768px': {
                  flexDirection: 'column',
                }
              }}>
                {/* Our Custom External Top Toolbar */}
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6} display={"flex"}>
                    <Box display={"flex"} paddingBottom={"10px"}>
                      <MRT_GlobalFilterTextField table={table} />
                      {props.dragAndDrop ? <Button variant='contained' color='primary' sx={{ marginLeft: 2 }} onClick={setPriorityClick} disabled={displayPriority}>Set Priority</Button> : null}
                    </Box>
                  </Grid>
                  {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                          {props.dragAndDrop && displayPriority ? <Typography color={"red"} textAlign={"right"}> Drag and Drop the table row to set the priority.</Typography> : null}
                    </Grid> */}
                </Grid>
                <MRT_TableContainer sx={{
                  width: "100%",
                  height: props.height ? props.height : "53.5vh",
                  overflowY: "auto",
                  "& th": {
                    backgroundColor: "#dbdbf7",
                    fontSize: "1.1rem"
                  },
                  "& td": {
                    fontSize: "1rem",
                    // padding: "7px"
                  }
                }} table={table} />

                <TablePagination
                  component="div"
                  rowsPerPageOptions={new PaginatorModel().largePageSizeOptions}
                  count={rowCount}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  showFirstButton={true}
                  showLastButton={true}

                />

                {/* priority related works */}
                {/* { (props.dragAndDrop && displayPriority) ? 
                <CustomButtonGrid
								item={
									<>
										<Button variant='contained' color='primary' onClick={() => {props.priorityFunction(data); setPriorityClick();}}>Save</Button>
										<Button variant='contained' color='warning' onClick={() => { setPriorityClick(); props.listFunction(); }}>Cancel</Button>
									</>
								}
              />: null} */}

              </Paper>
            ) : (
              props.noRecordFound ? (
                <NoRecord />
              ) : null
            )
          }
        </>
      ) : <Box className="flex justify-center" >
        <img src="/images/loader.gif" alt="Loading" max-width="100%" />
      </Box>}
    </>
  );
}

export default ReactTable