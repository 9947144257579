import { msgTypes } from "constants/msgTypes";
import { Validation } from "constants/Validation";
import * as Yup from "yup";

export const AnnouncementSchema = Yup.object({
    announcementId: Yup.string(),
    title: Yup.string().required(Validation.required.message.replaceAll("^","Title"))
                    .matches(Validation.plaintext.regex , Validation.plaintext.message),
    message: Yup.string().matches(Validation.plaintext.regex , Validation.plaintext.message),
    attachment: Yup.string().required(Validation.required.message.replaceAll("^","Attachment")),
    attachmentFile: Yup.mixed().nullable()
    .test(
        "FILE_FORMAT",
        "Uploaded file has unsupported format",
        (value) => !value || (value && msgTypes.SUPPORTED_ALL_FORMAT.includes(value?.type))
    )
    .test(
        "FILE_SIZE",
        "Uploaded file is too big",
        (value) =>!value || (value && value.size <=1024 * 1024)
    )
   
})
