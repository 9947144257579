import React from 'react'
import './GeneratedCertificate.scss'

const GeneratedCertificate = ({values, selectedCompanyName}) => {
  return (
    <>
    
    <div>
    <div className='certification-section'>
      <div className="container certification-image">
      <div className='container verctor-img'>
         <div className='row'>
            <div className='col-12'>
                 <h1>Certifications</h1>
                 <h3>of Membership</h3>
                 <h4>This is to Certify that</h4>
                 <h5>
                  {selectedCompanyName}
                 </h5>
                 {/* <h2>{values.certificateName}</h2> */}
            </div>
         </div>
         <div className='row vector-content'>
          <div className='col-md-12'>
             <div className='date-sec'>
             <h4>{values.expireDate}</h4>
             <h6>Expiry Date</h6>
             </div>
          </div>
         </div>
      </div>
      </div>
    </div>
      
    </div>
    </>
  )
}

export default GeneratedCertificate
