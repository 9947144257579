import { Box, Button, Grid, Paper, Typography ,  Dialog, DialogContent, DialogTitle } from "@mui/material";
import { FieldArray, Form, Formik } from "formik";
import FormikControl from "components/wrapperComponents/FormikControl";
import CustomButtonGrid from "components/uiComponents/CustomButtonGrid";
import { NewsAnnouncementsModel } from "model/screensModels/NewsAnnouncementsModel";
import React, { useState, useEffect } from "react";
import { NewsAnnouncementsSchema } from "schemas/menu/newsAnnouncements/NewsAnnouncementsSchema";
import DeleteIcon from "@mui/icons-material/Delete";
import "./NewsAnnouncements.scss";
import { UploadFileService } from "services/shared/UploadFileService";
import { msgTypes } from "constants/msgTypes";
import { NewsAnnouncementsService } from "services/menu/newsAnnouncementsService/NewsAnnouncementsService";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PaginatorModel } from "model/utilModels/PaginatorModel";
import { NewsLettersService } from "services/menu/newsLettersService/NewsLettersService";
import { Description } from "@mui/icons-material";
import { image } from "html2canvas/dist/types/css/types/image";
import ApiUrl from "services/shared/ApiUrl";


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
  width: 1,
});


const TRUNCATE_LENGTH = 70; 

const NewsAnnouncements = () => {
  const [viewMode, setViewMode] = useState(false);
  const [newsAnnouncements, setNewsAnnouncements] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  const [initialValues, setInitialValues] = useState(
    new NewsAnnouncementsModel()
  );

  const paginatorModel = new PaginatorModel();
  const [pagination, setPagination] = useState({
    pageSize: paginatorModel.pageSize,
    pageNumber: paginatorModel.pageNumber,
  });

  const handleOpenDialog = (service) => {
    setSelectedService(service);
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedService(null);
  };
  useEffect(() => {
    recentlyAddedNewsletters();
  }, []);

  const recentlyAddedNewsletters = async () => {
    const res = await NewsLettersService.recentlyAddedNewsletters();
    if (res.statusMsg === msgTypes.SUCCESS.msg) {
      // Extract All The Data Of Newsletter
      const newsAnnouncements = res.data.tbody.map((data) => ({
        image: data.image,
        title: data.title,
        description: data.description,
      }));


      setNewsAnnouncements(newsAnnouncements);
    }
  };

  
  const handleImageChange = async (event, index, setFieldValue, push) => {
    const localFile = event.target.files?.[0];
    if (localFile) {
      const reader = new FileReader();
      reader.onload = (preImage) => {
        setFieldValue(`images.${index}.placeholder`, preImage.target?.result);
        setFieldValue(`images.${index}.file`, localFile);
        // Push a new empty image object for the next file upload
        push({
          placeholder: `/sampleImages/ChooseImage.png`,
          file: null,
        });
      };
      reader.readAsDataURL(localFile);
    }
  };

  const prepareRequestBody = async (values: NewsAnnouncementsModel) => {
    const prepareData = new NewsAnnouncementsModel();

    prepareData.messages = values.messages ? values.messages : "";
    prepareData.additionalComments = values.additionalComments
      ? values.additionalComments
      : "";

    prepareData.images = [];

    // To Get Images URL
    if (values.images && values.images.length > 0) {
      for (let i = 0; i < values.images.length; i++) {
        const imageObj = values.images[i];
        if (imageObj.file) {
          const formData = new FormData();
          formData.append("file", imageObj.file as any);
          const imageurlData = await UploadFileService.getImagePath(formData);
          if (imageurlData && imageurlData.data.fileName) {
            prepareData.images.push(imageurlData.data.fileName);
          }
        }
      }
    }
    return prepareData;
  };

  const onSubmit = async (values: NewsAnnouncementsModel) => {
    const requestData = await prepareRequestBody(values);
    const res = await NewsAnnouncementsService.addNewsAnnouncements(
      requestData
    );
    if (res.statusMsg === msgTypes.SUCCESS.msg) {
      setInitialValues(new NewsAnnouncementsModel());
    }
  };

  const slidesToShow = 4;
  const hasEnoughSlides = newsAnnouncements.length >= slidesToShow;

  const settings = {
    autoplay: true,

    infinite: true,
    speed: 500,
    slidesToShow: hasEnoughSlides ? slidesToShow : newsAnnouncements.length,
    slidesToScroll: hasEnoughSlides ? 1 : 0,
    arrows: false,

  };


  return (
    <>
    <div className='content-subheader'>
        <h4 className='mr-3'> News Submission</h4>
      </div>
    <Box className="main-box-1">
      <Grid container className="main-grid-1 bg-white">
        <Box className="heading-1-main-box">
          <Box>
            <Grid item>
              <Grid item className="content-p">
                <Typography className="heading-1-text">
                  We encourage members to submit content and images for
                  inclusion in our 1WN Quarterly Newsletter and Network News. If
                  you have an outstanding shipment or cargo movement that you
                  were involved with, please provide us with as much information
                  as possible and related photos using the form below.
                </Typography>
                <Typography className="text">
                  We will provide you with a draft article for verification to
                  ensure that all facts are covered and that we haven't omitted
                  anything that you find important.
                </Typography>
                <Typography className="text">
                  Thank you for sharing your company developments with 1WN!
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>

      <Grid container className="main-grid-1 bg-white">
        <Box className="heading-1-main-box">
          <Grid item className="heading-1-grid-1">
            <Typography className="heading-1">Recently Added Newsletter</Typography>
          </Grid>
          <Box>
            <Grid item>
              <Grid item className="content-p">
                <Typography className="heading-1-text">
                <Slider {...settings}>
      {newsAnnouncements.map((data, index) => (
                <div >
                <Card sx={{ maxWidth: 340, height: 150 }} className="mt-2 mr-3">
                <CardActionArea onClick={() => handleOpenDialog(data)}>
                <CardMedia style={{height:"58px" , objectFit:"contain"} }
                 image={ApiUrl.fileBaseUrl + data.image}/>
                    <CardContent style={{ height: "80px" }}>
                    <Typography
                      className="d-flex justify-content-center"
                   
                      gutterBottom
                      variant="h5"
                      component="div"
                    >
                      {data.title}
                    </Typography>
                    <Typography
                      className="heading-2-text"
                      style={{ marginLeft: "10px" }}
                    >
                      {data.description.length > TRUNCATE_LENGTH
                        ? `${data.description.substring(0, TRUNCATE_LENGTH)}...`
                        : data.description}
                    </Typography>
                    {data.description.length > TRUNCATE_LENGTH && (
                      <Button
                        onClick={(e) => {
                          e.stopPropagation(); 
                          handleOpenDialog(data);
                        }}
                        style={{ marginLeft: "10px", textTransform: "none" }}
                      >
                        Read More....
                      </Button>
                    )}
                  </CardContent>
                </CardActionArea>
              </Card>
                </div>
              ))}
      
   
    </Slider>
                </Typography>
              
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>

     
   


          <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
  <DialogTitle className="d-flex justify-content-center">
    {selectedService?.title}
  </DialogTitle>
  <DialogContent>
    {selectedService?.image && (
      <img
        src={ApiUrl.fileBaseUrl + selectedService.image} 
        alt={selectedService?.title}
        style={{ width: '100%', height: 'auto', marginBottom: '10px' }}
      />
    )}
    <Typography>{selectedService?.description}</Typography>
  </DialogContent>
</Dialog>



<Grid container className="main-grid-1 bg-white mt-2">
        <Box className="heading-1-main-box">
        
          <Box>
            <Grid item>
              <Grid item className="content-p">
                <Typography className="heading-1-text">
                <Box className="form-main-box-1">
            <Formik
              initialValues={initialValues}
              validationSchema={NewsAnnouncementsSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, isValid, dirty, values, setFieldValue }) => (
                <Form>
                  <Box className="form-main-box-2">
                    <Paper className="form-paper">
                      <Grid




                      >



                        <div className="row">
                          <div className="col-md-6  position-relative">



                            <FormikControl

                              control="textfield"
                              name="messages"
                              label="Message"
                              placeholder="Your Message"
                              type="text"
                              variant="outlined"
                              requiredField
                              multiline
                              disabled={viewMode}
                              rows={8}
                              fullWidth
                            />




                          </div>
                          <div className="col-md-6  position-relative">

                            <FormikControl

                              control="textfield"
                              name="additionalComments"
                              label="Additional Comments to the 1WN team"
                              placeholder="Additional Details Such As POL, POD, Commodity, Size, Any challenges"
                              type="text"
                              variant="outlined"
                              requiredField
                              multiline
                              disabled={viewMode}
                              rows={8}
                              fullWidth
                            />

                          </div>

                          <div className="col-md-12  position-relative ">
                            <Grid xs={12} sx={{ margin: "10px 0px 0px 12px" }}>
                              Upload Images
                            </Grid>

                            <FieldArray name="images">
                              {({ push, remove }) => (
                                <>
                                  {values.images.map((image, index) => (
                                    <Grid
                                      item
                                      xs={6}
                                      sm={4}
                                      md={3}
                                      lg={2}
                                      key={index}
                                    >
                                      <Box>
                                        <img
                                          src={
                                            image.placeholder ||
                                            `/sampleImages/ChooseImage.png`
                                          }
                                          alt="Uploaded Preview"
                                          className="image-preview  "
                                        />
                                      </Box>

                                      <Grid
                                        container
                                        spacing={1}
                                        className="upload-image-btn-grid"
                                      >
                                        <Grid item>
                                          <Button
                                            component="label"
                                            variant="contained"
                                            startIcon={<CloudUploadIcon />}
                                            className="choose-file-button"
                                          >
                                            Choose File
                                            <VisuallyHiddenInput
                                              type="file"
                                              onChange={(e) =>
                                                handleImageChange(
                                                  e,
                                                  index,
                                                  setFieldValue,
                                                  push
                                                )
                                              }
                                            />
                                          </Button>
                                        </Grid>

                                        {image.file && (
                                          <Grid item>
                                            <DeleteIcon
                                              onClick={() => remove(index)}
                                              color="error"
                                              className="delete-image-btn"
                                            />
                                          </Grid>
                                        )}
                                      </Grid>
                                    </Grid>
                                  ))}
                                </>
                              )}
                            </FieldArray>
                          </div>
                        </div>
                      </Grid>




                      <Grid item xs={12} className="submit-parent-grid">
                        <CustomButtonGrid
                          item={
                            <FormikControl
                              control="button"
                              color="primary"
                              label="Submit"
                              type="submit"
                              disabled={!dirty || !isValid || isSubmitting}
                            />
                          }
                        />
                      </Grid>

                    </Paper>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
                </Typography>
             
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>

     
    </Box>
    </>
  );
};
export default NewsAnnouncements;
