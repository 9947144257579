import { Box, Typography } from "@mui/material";

const NoRecord = () => {
    return (
        <Box m={1} border={1} borderColor={'#ecebeb'} textAlign={"center"}>
            <img src="/images/noRecordFound.avif"  alt="No Record Found" className="noRecordFound" />
            <Typography fontWeight={600} fontSize={18} marginTop={2} paddingBottom={2}>No Records Found!</Typography>
        </Box>
    )

}

export default NoRecord;