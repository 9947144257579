export class BlogModel {
    blogId: string;
    title: string;
    metaTitle: string;
    shortDescription: string;
    description: string;
    metaDescription: string;
    altTag: string;
    image: string;
    imageFile: any;
   
    constructor() {
        this.blogId="";
        this.title="";
        this.metaTitle="";
        this.shortDescription="";
        this.description="";
        this.metaDescription="";
        this.altTag="";
        this.image="";
        this.imageFile=null;
    }
}
