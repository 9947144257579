import { Box, Grid, Paper, Typography } from "@mui/material";
import CustomButtonGrid from "components/uiComponents/CustomButtonGrid";
import FormikControl from "components/wrapperComponents/FormikControl";
import { Form, Formik } from "formik";
import { MailRequestModel } from "model/utilModels/MailRequestModel";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { MailRequestSchema } from "schemas/utilSchema/MailRequestSchema";
import './MailRequestForm.scss';

const MailRequestForm = (props) => {
  const { mailrequestModel } = props;
  const [initialValue, setInitialValue] = useState(new MailRequestModel())
  const [viewMode, setViewMode] = useState(false);


  useEffect(() => {
    setInitialValue(mailrequestModel)
  }, [mailrequestModel])

  const sendRequest = (values: MailRequestModel) => {
    
  };

  const styles = {
    typo: {
      marginBottom: "5px",
      fontWeight: "bold",
      color: "blue",
    },
    label: {
      fontWeight: "bold",
      fontSize: "15px",
      marginBottom: "5px",
      marginLeft: "20px",
    },
    textField: {
      marginBottom: "10px",
    },
    paper: {
      padding: "10px",
      margin: "10px 0",
      height: "auto",
    },
  };


  const labelDimension = { xs: 12, sm: 2, md: 1, lg: 1 };

  const textfieldDimension = { xs: 12, sm: 10, md: 11, lg: 11 };

  return (
    <Box className="main-box" width="100%" margin="0 auto" padding="5px">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h4 className="mail-request-heading">
              Your Message
            </h4>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Formik
              enableReinitialize={true}
              initialValues={initialValue}
              //validationSchema={MailRequestSchema}
              onSubmit={sendRequest}>
              {({ isSubmitting, dirty, isValid, values, setFieldValue }) => (
                <Form>
                  <div className="row message-request-form pl-5">
                    <div className="col-lg-12">
                      <div className="row align-items-baseline">
                        <div className="col-md-1 col-lg-2 col-xl-1">
                          <label>From</label>
                        </div>
                        <div className="col-md-6">
                          <FormikControl
                            control="textfield"
                            name="from"
                            placeholder="From"
                            disabled={true}
                            sx={styles.textField}
                            value={mailrequestModel.from}
                          />
                        </div>
                      </div>
                      <div className="row align-items-baseline">
                        <div className="col-md-1 col-lg-2 col-xl-1">
                          <label>CC</label>
                        </div>
                        <div className="col-md-6">
                          <FormikControl
                            control="textfield"
                            name="cc"
                            placeholder="CC"
                            disabled={viewMode}
                            fullWidth
                            sx={styles.textField}
                          />
                        </div>
                      </div>
                      <div className="row align-items-baseline">
                        <div className="col-md-1 col-lg-2 col-xl-1">
                          <label>BCC</label>
                        </div>
                        <div className="col-md-6">
                          <FormikControl
                            control="textfield"
                            name="bcc"
                            placeholder="BCC"
                            disabled={true}
                            fullWidth
                            sx={styles.textField}
                          />
                        </div>
                      </div>
                      <div className="row align-items-baseline">
                        <div className="col-md-1 col-lg-2 col-xl-1">
                          <label>Subject</label>
                        </div>
                        <div className="col-md-6">
                          <FormikControl
                            control="textfield"
                            name="subject"
                            placeholder="Subject"
                            disabled={viewMode}
                            fullWidth
                            sx={styles.textField}
                          />

                        </div>
                      </div>
                      <div className="row align-items-baseline">
                        <div className="col-md-1 col-lg-2 col-xl-1">
                          <label>Body</label>
                        </div>
                        <div className="col-md-10">
                          <FormikControl
                            control="richTextEditor"
                            name="body"
                            fullWidth
                          />
                        </div>
                      </div>

                      <div className="row my-3">
                        <div className="col-md-11 text-end">
                          <FormikControl
                            control="button"
                            color="primary"
                            label="Send Request"
                            type="submit"
                          // disabled={!dirty || !isValid}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      {/* <Grid container justifyContent="center">
        <Box className="message-box" width="100%" margin="0 auto" padding="5px">
          
          <Grid container justifyContent="center">
            <Paper sx={styles.paper}>
              <Formik
                enableReinitialize={true}
                initialValues={initialValue}
                //validationSchema={MailRequestSchema}
                onSubmit={sendRequest}>
                {({ isSubmitting, dirty, isValid, values, setFieldValue }) => (
                  <Form>
                    <Grid container spacing={2} marginTop="10px">
                      {/* From Grid */}

      {/* <Grid item xs={12}>
                        <Grid container alignItems="center">
                          <Grid item {...labelDimension}>
                            <Typography sx={styles.label}>From:</Typography>
                          </Grid>
                          <Grid item {...textfieldDimension}>
                            <FormikControl
                              control="textfield"
                              name="from"
                              placeholder="From"
                              disabled={true}
                              fullWidth
                              sx={styles.textField}
                              value={mailrequestModel.from}
                            />
                          </Grid>
                        </Grid>
                      </Grid> */}


      {/* CC Grid */}

      {/* <Grid item xs={12}>
                        <Grid container alignItems="center">
                          <Grid item {...labelDimension}>
                            <Typography sx={styles.label}>CC:</Typography>
                          </Grid>
                          <Grid item {...textfieldDimension}>
                            <FormikControl
                              control="textfield"
                              name="cc"
                              placeholder="CC"
                              disabled={viewMode}
                              fullWidth
                              sx={styles.textField}
                            />
                          </Grid>
                        </Grid>
                      </Grid> */}

      {/* BCC Grid */}

      {/* <Grid item xs={12}>
                        <Grid container alignItems="center">
                          <Grid item {...labelDimension}>
                            <Typography sx={styles.label}>BCC:</Typography>
                          </Grid>
                          <Grid item {...textfieldDimension}>
                            <FormikControl
                              control="textfield"
                              name="bcc"
                              placeholder="BCC"
                              disabled={true}
                              fullWidth
                              sx={styles.textField}
                            />
                          </Grid>
                        </Grid>
                      </Grid> */}

      {/* Subject */}

      {/* <Grid item xs={12}>
                        <Grid container alignItems="center">
                          <Grid item {...labelDimension}>
                            <Typography sx={styles.label}>Subject:</Typography>
                          </Grid>
                          <Grid item {...textfieldDimension}>
                            <FormikControl
                              control="textfield"
                              name="subject"
                              placeholder="Subject"
                              disabled={viewMode}
                              fullWidth
                              sx={styles.textField}
                            />
                          </Grid>
                        </Grid>
                      </Grid> */}

      {/* <Grid item xs={12} marginTop="15px">
                      <CustomButtonGrid
                          item={
                            <FormikControl
                              control="richTextEditor"
                              name="body"
                              label="Body"
                              fullWidth
                            />
                          }
                        />
                      </Grid> */}
      {/* <Grid item xs={12} marginTop="20px">
                        <CustomButtonGrid
                          item={
                            <FormikControl
                              control="button"
                              color="primary"
                              label="Send Request"
                              type="submit"
                              // disabled={!dirty || !isValid}
                            />
                          }
                        />
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Paper>
          </Grid>
        </Box>
      </Grid> */}
    </Box>
  );
};

export default MailRequestForm;
