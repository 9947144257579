
import * as Yup from 'yup';
import { Validation } from "constants/Validation";
import { msgTypes } from "constants/msgTypes";

export const GrievenceRedressalSchema = Yup.object().shape({

  redressalId: Yup.string(),
  companyName: Yup.string(),
  address: Yup.string(),
  debtorCompany: Yup.string(),
  debtorCompanyAddress: Yup.string(),

  invoiceData: Yup.array().of(
    Yup.object().shape({
      invoiceNumber: Yup.string()
        //.required(Validation.required.message.replaceAll("^", "Invoice Number"))
        .matches(Validation.plaintext.regex, Validation.plaintext.message),
      invoice: Yup.mixed().nullable()//.required(Validation.required.message.replaceAll("^","Image"))
        .test(
          "FILE_FORMAT",
          "Uploaded file has unsupported format",
          (value) => !value || (value && msgTypes.SUPPORTED_ALL_FORMAT.includes(value?.type))
        )
        .test(
          "FILE_SIZE",
          "Uploaded file is too big",
          (value) => !value || (value && value.size <= 1024 * 1024)
        ),
      currency: Yup.string(),
      //.required(Validation.required.message.replaceAll("^", "Currency")),
      invoiceAmount: Yup.string()
        //.required(Validation.required.message.replaceAll("^", "Invoice Amount"))
        .matches(Validation.amount.regex, Validation.amount.message),
      issueDate: Yup.date(),
      //.required(Validation.required.message.replaceAll("^", "Issue Date")),
      dueDate: Yup.date()
      //.required(Validation.required.message.replaceAll("^", "Due Date")),
    })
  ),

  currencyData: Yup.array().of(
    Yup.object().shape({
      currency: Yup.string(),
      //.required(Validation.required.message.replaceAll("^", "Currency")),
      unpaidAmount: Yup.string()
        //.required(Validation.required.message.replaceAll("^", "Total Amount Unpaid"))
        .matches(Validation.amount.regex, Validation.amount.message)
    })
  ),

  networkData: Yup.array().of(
    Yup.object().shape({

      networkMembership: Yup.string()
        //.required(Validation.required.message.replaceAll("^", "Other Network Membership"))
        .matches(Validation.plaintext.regex, Validation.plaintext.message),
      contactPerson: Yup.string()
        //.required(Validation.required.message.replaceAll("^", "Contact Person"))
        .matches(Validation.plaintext.regex, Validation.plaintext.message),
      personEmail: Yup.string()
        .email(Validation.email.regex, Validation.email.message)
      //  .required(Validation.required.message.replaceAll("^", "Contact Person's E-mail"))
    })
  ),

  communication: Yup.array().of(
    Yup.object().shape({
      file: Yup.mixed().nullable()//.required(Validation.required.message.replaceAll("^","Image"))
        .test(
          "FILE_FORMAT",
          "Uploaded file has unsupported format",
          (value) => !value || (value && msgTypes.SUPPORTED_ALL_FORMAT.includes(value?.type))
        )
        .test(
          "FILE_SIZE",
          "Uploaded file is too big",
          (value) => !value || (value && value.size <= 1024 * 1024)
        ),
    })
  ),

  accountStatement: Yup.array().of(
    Yup.object().shape({
      file: Yup.mixed().nullable()//.required(Validation.required.message.replaceAll("^","Image"))
        .test(
          "FILE_FORMAT",
          "Uploaded file has unsupported format",
          (value) => !value || (value && msgTypes.SUPPORTED_ALL_FORMAT.includes(value?.type))
        )
        .test(
          "FILE_SIZE",
          "Uploaded file is too big",
          (value) => !value || (value && value.size <= 1024 * 1024)
        ),
    })
  ),

  shippingDocument: Yup.array().of(
    Yup.object().shape({
      file: Yup.mixed().nullable()//.required(Validation.required.message.replaceAll("^","Image"))
        .test(
          "FILE_FORMAT",
          "Uploaded file has unsupported format",
          (value) => !value || (value && msgTypes.SUPPORTED_ALL_FORMAT.includes(value?.type))
        )
        .test(
          "FILE_SIZE",
          "Uploaded file is too big",
          (value) => !value || (value && value.size <= 1024 * 1024)
        ),
    })
  ),

  deliveryReceipt: Yup.array().of(
    Yup.object().shape({
      file: Yup.mixed().nullable()//.required(Validation.required.message.replaceAll("^","Image"))
        .test(
          "FILE_FORMAT",
          "Uploaded file has unsupported format",
          (value) => !value || (value && msgTypes.SUPPORTED_ALL_FORMAT.includes(value?.type))
        )
        .test(
          "FILE_SIZE",
          "Uploaded file is too big",
          (value) => !value || (value && value.size <= 1024 * 1024)
        ),
    })
  ),

  addentialRevelentDocoument: Yup.array().of(
    Yup.object().shape({
      file: Yup.mixed().nullable()//.required(Validation.required.message.replaceAll("^","Image"))
        .test(
          "FILE_FORMAT",
          "Uploaded file has unsupported format",
          (value) => !value || (value && msgTypes.SUPPORTED_ALL_FORMAT.includes(value?.type))
        )
        .test(
          "FILE_SIZE",
          "Uploaded file is too big",
          (value) => !value || (value && value.size <= 1024 * 1024)
        ),
    })
  ),

  claimForm: Yup.mixed().nullable()//.required(Validation.required.message.replaceAll("^","Image"))
    .test(
      "FILE_FORMAT",
      "Uploaded file has unsupported format",
      (value) => !value || (value && msgTypes.SUPPORTED_ALL_FORMAT.includes(value?.type))
    )
    .test(
      "FILE_SIZE",
      "Uploaded file is too big",
      (value) => !value || (value && value.size <= 1024 * 1024)
    ),

  lastDate: Yup.date(),
    //.required(Validation.required.message.replaceAll("^", "Last Contact Date")),

  formContact: Yup.string().matches(Validation.plaintext.regex, Validation.plaintext.message),

  additionalNotes: Yup.string().matches(Validation.plaintext.regex, Validation.plaintext.message),
    //.required(Validation.required.message.replaceAll("^", "Additional Notes")),
  formDeclare: Yup.boolean(),
  termAndCondition: Yup.boolean()

});


