import { Box, Grid } from "@mui/material";

const CustomGrid = props => {
    if(props.breakpoints){
        const size = props.breakpoints;
        return (
            <Grid item xs={size.xs} sm={size.sm} md={size.md} lg={size.lg} xl={size.xl} paddingLeft={'8px'} paddingRight={'8px'}>
                <Box ml={1} mr={1} paddingLeft={'8px'} paddingRight={'8px'}>
                    {props.item}
                </Box>
            </Grid>
        )
    }
    else{
        return (
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                <Box ml={1} mr={1}>
                    {props.item}
                </Box>
            </Grid>
        )
    }
    
}
export default CustomGrid;