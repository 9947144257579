import { msgTypes } from "constants/msgTypes";
import { Validation } from "constants/Validation";
import * as Yup from "yup";

export const NewsAnnouncementsSchema = Yup.object().shape({
  images: Yup.array()
    .of(
      Yup.object().shape({
        file: Yup.mixed()
          .nullable()
          .test(
            "FILE_FORMAT",
            "Uploaded file has unsupported format",
            (value) => !value || (value && msgTypes.SUPPORTED_IMAGE_FORMAT.includes(value?.type))
          )
          .test(
            "FILE_SIZE",
            "Uploaded file is too big",
            (value) => !value || (value && value.size <= 1024 * 1024)
          ),
        placeholder: Yup.string().required("Image placeholder is required")
      })
    )
    .min(1, "At least one image is required"),
  messages: Yup.string().required(
    Validation.required.message.replaceAll("^", "Message")
  ),
  additionalComments: Yup.string().required(
    Validation.required.message.replaceAll(
      "^",
      "Additional Comments"
    )
  ),
});
