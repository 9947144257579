import { useState, createContext, useContext } from "react";

const AuthContext = createContext(null);

export const AuthProvider = ({children}) =>{
    const [user, setUser] = useState(null);
    const [role, setRole] = useState(null);
    const [collapsed, setCollapsed] = useState(false);
    

    const login = user =>{
        setUser(user);
    }

    const logout = () =>{
        setUser(null);
    }

    const setUserRole = role =>{
            setRole(role);
    }

    const getUserRole = () =>{
            return role;
    }

    const setMenuCollapsed = collapsed =>{
        setCollapsed(collapsed);
    }

    const getMenuCollapsed = () =>{
        return collapsed;
    }

    const getUser = () => {
        return user;
    }

    return (
        <AuthContext.Provider value={{user, role, collapsed, login, logout, setUserRole, getUserRole, setMenuCollapsed, getMenuCollapsed, getUser }}>{children}</AuthContext.Provider>
    )
}

export const useAuth = () =>{
    return useContext(AuthContext);
}
