import { RouteType } from "constants/RouteType";
import { PaginatorModel } from "model/utilModels/PaginatorModel";
import { AuthService } from "services/auth/AuthService";

export  class FeaturedInNewsLetterService {

  // To Get Featured In News Letter List

  static getfeaturedInNewsLetterList = async(requestBody: PaginatorModel) => {
    const url = RouteType.ADMIN + RouteType.FEATURED_IN_NEWSLETTER;
    const response = AuthService.postCryptoRequest(url, JSON.stringify(requestBody));
    return response;
  };
}