
export class GrievenceRedressalModel {
      redressalId:string;
      companyName: string;
      address: string;
      debtorCompany: string;
      debtorCompanyAddress:string;
      
      
      invoiceData:{
        invoiceAmount: number;
        issueDate: string;
        dueDate: string;
        invoiceNumber: string;
        invoice: any;
        currency: string;
      }[];
      currencyData: {
        currency: string;
        unpaidAmount: number;
      }[];
      networkData: {
        networkMembership: string;
        contactPerson: string;
        personEmail: string;
      }[];

      communication: {}[];
      accountStatement: {}[];
      shippingDocument: {}[];
      deliveryReceipt: {}[];
      addentialRevelentDocoument: {}[];

      claimForm: string;
      lastDate: string;
      formContact: string;
      protectionSystem: string;
      additionalNotes: string;
      formDeclare: boolean;
      termAndCondition: boolean;
  
      constructor() {
        this.redressalId = "";
        this.companyName = "";
        this.address = "";
        this.debtorCompany = "";
        this.debtorCompanyAddress="";
        this.invoiceData=[{
          invoiceAmount: undefined,
          issueDate: "",
          dueDate: "",
          invoiceNumber: "",
          invoice: null,
          currency: "",
        }];
    
        this.currencyData = [{
          currency: "",
          unpaidAmount: undefined
        }];
        this.networkData= [{
          networkMembership: "",
          contactPerson: "",
          personEmail: ""
        }];
      
        this.communication= [{}];
        this.accountStatement = [{}];
        this.shippingDocument = [{}];
        this.deliveryReceipt = [{}];
        this.addentialRevelentDocoument = [{}];
    
        this.claimForm = "";
        this.lastDate = "";
        this.formContact = "";   
        this.protectionSystem= "No";    
        this.additionalNotes = "";
        this.termAndCondition = false;
        this.formDeclare=false;
      }
    
    }
    