import { RouteType } from "constants/RouteType";
import { CertificateModel } from "model/screensModels/CertificateModel";
import { PaginatorModel } from "model/utilModels/PaginatorModel";
import { AuthService } from "services/auth/AuthService";

export class UploadCertificateService {
  //upload certificate
   static uploadCertificate = async (requestBody: CertificateModel) => {
    const url = RouteType.ADMIN + RouteType.CERTIFICATE + RouteType.STORE;
    const response = AuthService.postCryptoRequest(url, JSON.stringify(requestBody));
    return response;
  };

  static getCertificateList = async (requestBody: PaginatorModel) => {
    const url = RouteType.ADMIN + RouteType.CERTIFICATE;
    const response = AuthService.postCryptoRequest(url, JSON.stringify(requestBody));
    return response;
  };

  static deleteCertificate = async (requestBody: CertificateModel) => {
    const url = RouteType.ADMIN + RouteType.CERTIFICATE + RouteType.DELETE;
    const response = AuthService.postCryptoRequest(url, JSON.stringify(requestBody));
    return response;
  };

}