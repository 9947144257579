import * as React from 'react';
import { useState } from 'react'
import Box from '@mui/material/Box';
import { InvoiceManagementModel } from 'model/screensModels/InvoiceManagementModel';
import InvoiceList from './InvoiceList';
import CreateInvoice from './CreateInvoice';




export default function InvoiceManagement() {
  const [updateValue, setUpdateValue] = React.useState(new InvoiceManagementModel());
  const [flag, setFlag] = useState(false)

  //to get update record value
  const getUpdateRecordValue = (invoiceManagementModel: InvoiceManagementModel) => {
    setUpdateValue(invoiceManagementModel);
  }

  const addInvoice = () =>{
    setFlag(true)
    setUpdateValue(new InvoiceManagementModel());
  }

  return (
    <Box sx={{ width: '100%' }} className="container-subheaders">
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <div className='content-subheader'>
       {!flag ? <h4 className='mr-3'> Invoices</h4> : <h4 className='mr-3'>Create Invoice</h4>}
        {!flag ?
          <button className='btn primary' onClick={addInvoice}>Add Invoice</button>
          :""}
        </div>
      </Box>
      <div className="sub-content-sec">
      {!flag ?
        <InvoiceList setFlag= {setFlag} updateRecord={getUpdateRecordValue} />
        :
        <CreateInvoice setFlag= {setFlag} updateRecord={updateValue} />
      }
        </div>
    </Box>
  );
}