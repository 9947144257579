// import { Box, Container, IconButton, Stack, Typography } from '@mui/material'
// import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
// import { useEffect, useMemo, useState } from 'react';
// import CustomSwitch from 'components/uiComponents/CustomSwitch';
// import React from 'react';

// const MaterialTable = (props) => {
//     const {
//       responseData,
//       tableHeader, 
//       enableRowNumbers,
//       enableRowSelection,
//       enableRowActions,
//      } = props;
//     const [data, setData] = useState(responseData ? responseData.tbody : []);
//     const [columns, setColumns] = useState(tableHeader)
//     const [rowSelection, setRowSelection] = useState({}); //ts type available
//     const [showProgressbar, setShowProgressbar] = useState(true);
//     const [rowCount, setRowCount] = useState(responseData?.tfoot.totalListSize);
//     const [pagination, setPagination] = useState({
//       pageIndex: 0,
//       pageSize: 10, //customize the default page size
//     });

//     // useEffect(()=>{
//     //   setRowCount(responseData.data.tfoot.totalListSize)
//     // },[responseData])

//     // useEffect(()=>{
//     //   setColumns(tableHeader)
//     // },[tableHeader])

//     const createAction = (row) => {
//       if (props.actions) {
//         const { actions } = props;
//         return (
//           <React.Fragment>
//             <Box display={"Flex"}>
//               {actions.map(element => {

//                if (element.type === 'switch') {
//                   return (
//                     <Box onClick={() => { if (!element.disabled) element.actionFunc(row) }}>
//                       <CustomSwitch
//                         name="isActive"
//                         disabled={element.disabled}
//                         value={row.isActive}
//                         formikValue={props.formikValue}
//                       />
//                     </Box>
//                   )
//                 }else {
//                   return (
//                     <IconButton
//                       color="primary"
//                       sx={{ padding: "1px" }}
//                       onClick={() =>
//                         element.actionFunc(row)
//                       }
//                     >
//                       {element.icon}
//                     </IconButton>
//                   )
//                 }
//               })}
//             </Box>
//           </React.Fragment>
//         )
//       }
//     }

//     //For row selections
//     useEffect(() => {
//       console.log(rowSelection);

//     }, [rowSelection])

//     //For pagination change
//     useEffect(() => {
//       props.setPagination({
//         pageSize: pagination.pageSize,
//         pageNumber: pagination.pageIndex
//       })
//       //do something when the pagination state changes
//     }, [pagination.pageIndex, pagination.pageSize]);

// const table = useMaterialReactTable({
//       columns,
//       data,
//       enableRowNumbers: enableRowNumbers!=undefined ? enableRowNumbers : true,
//       enableStickyHeader: true,
//       enableStickyFooter: true,
//       enableFullScreenToggle: false,

//       //give checkbox and get selected id
//       enableRowSelection: enableRowSelection !=undefined ? enableRowSelection : true,
//       positionToolbarAlertBanner: 'none',
//       getRowId: (row) => row.id,
//       onRowSelectionChange: setRowSelection,
//       state: { rowSelection, pagination},
//       //**************End************* */

//       initialState: {
//         columnPinning: { right: ['mrt-row-actions'] },
//         density: "compact",
//         // showProgressBars: showProgressbar,
//         //showSkeletons: {showProgressbar}
//       },

//       //Pagination
//       rowCount,
//       paginationDisplayMode: "pages",
//       muiPaginationProps: {
//         variant: 'outlined',
//         shape: 'rounded',
//         sx: (theme) => ({
//           "& .MuiBurronBase-root-MuiPaginationItem-root.Mui-selected": {
//             background: `${theme.palette.primary.main} !important`,
//             color: theme.palette.common.white
//           }
//         }),
//       },
//       onPaginationChange: setPagination, //hoist pagination state to your state when it changes internally
//       //state: { pagination },
//       //*************End********** */

//       //row Actions
//       enableRowActions: enableRowActions!=undefined ? enableRowActions : true,
//       positionActionsColumn: "last",
//       renderRowActions: ({ row }) => [createAction(row.original)],
//       //*****************end */

//       //progess bar

//       //Search Box
//       //enableFilterMatchHighlighting: true,
//       //positionGlobalFilter:"left"

//       //Record not found
//       renderEmptyRowsFallback: ({ table }) => (
//         <Box alignSelf={"center"} alignItems={"center"} height={250}>
//             <img alt='noRecord' height={100} src='/image/recordNotFound.png' />
//         </Box>
//       ),

//       muiTableHeadCellProps: {
//         sx: {
//           border: '1px solid rgba(81, 81, 81, 0)',
//         },
//       },

//       muiTableBodyCellProps: {
//         sx: {
//           border: '1px solid rgba(81, 81, 81, .1)',
//           boxShadow: 'none'
//         },
//       }
//     })

//     return (
//       <>
//         <Box sx={{ pt: 5 }}>
//           <Container>
//             <MaterialReactTable table={table}></MaterialReactTable>
//           </Container>
//         </Box>
//       </>
//     );
//  }


// export default MaterialTable


import React from 'react'
import { useState, useEffect } from 'react';
import {
  MRT_GlobalFilterTextField,
  MRT_TableContainer,
  useMaterialReactTable,
  
} from 'material-react-table';
import { Box, Button, Grid, IconButton, Paper, Typography } from '@mui/material';
import CustomSwitch from 'components/uiComponents/CustomSwitch';
import TablePagination from '@mui/material/TablePagination';
import NoRecord from 'components/sharedComponent/noRecord/NoRecord';
import { PaginatorModel } from 'model/utilModels/PaginatorModel';


function ReactTable(props) {
  const { tableColumn, enableRowNumbers } = props
  const [columns, setColumns] = useState(tableColumn);
  const [data, setData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [blankData, setBlankData] = useState(false);
  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    props.setPagination({
      pageSize: rowsPerPage,
      pageNumber: newPage,
      filter: globalFilter!=undefined ? globalFilter : "",
      orderBy: sorting.length>0 ? sorting[0]?.id : "",
      desc: sorting.length>0 ? sorting[0]?.desc : ""
    })
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
    props.setPagination({
      pageSize: (parseInt(event.target.value)),
      pageNumber: 0,
      filter: globalFilter!=undefined ? globalFilter : "",
      orderBy: sorting.length>0 ? sorting[0]?.id : "",
      desc: sorting.length>0 ? sorting[0]?.desc : ""
    })
  };

  useEffect(() => {
      props.setPagination({
        pageSize: rowsPerPage,
        pageNumber: 0,
        filter: globalFilter!=undefined ? globalFilter : "",
        orderBy: sorting.length>0 ? sorting[0]?.id : "",
        desc: sorting.length>0 ? sorting[0]?.desc : ""
      })
  }, [globalFilter]);

  useEffect(() => {
    props.setPagination({
      pageSize: rowsPerPage,
      pageNumber: 0,
      filter: globalFilter!=undefined ? globalFilter : "",
      orderBy: sorting.length>0 ? sorting[0]?.id : "",
      desc: sorting.length>0 ? sorting[0]?.desc : ""
    })    
  }, [sorting]);

  useEffect(() => {
    try {
      setBlankData((props?.tableData?.data?.tbody?.length !== 0 && props?.tableData?.data?.tbody !=undefined ) ? false : true);
      if (props?.tableData?.data?.thead) {
        setData(props?.tableData?.data?.tbody);
      }
      if (props?.tableData?.data?.tfoot) {
        setRowCount(props?.tableData?.data?.tfoot?.totalListSize);
      }
    } catch (e) {
      console.log("error", e);
    }
  }, [props?.tableData.data]);

  
  
  const createAction = (row) => {
    if (props.actions) {
      const { actions } = props;
      return (
        <React.Fragment>
          <Box display={"Flex"}>
            {actions.map(element => {
              if (element.type === 'switch') {
                return (
                  <Box onClick={() => { if (!element.disabled) element.actionFunc(row) }}>
                    <CustomSwitch
                      name="isActive"
                      disabled={element.disabled}
                      value={row.isActive}
                      formikValue={props.formikValue}
                    />
                  </Box>
                )
              } else {
                return (
                  <IconButton
                    color="primary"
                    sx={{ padding: "1px" }}
                    onClick={() =>
                      element.actionFunc(row)
                    }
                  >
                    {element.icon}
                  </IconButton>
                )
              }
            })}
          </Box>
        </React.Fragment>
      )
    }
  }

  const table = useMaterialReactTable({
    columns,
    data,

    enableRowNumbers: enableRowNumbers != undefined ? enableRowNumbers : true,
    //Apply loading if data is not loaded
    state: { isLoading:(props?.tableData?.data?.tbody && props?.tableData?.data!='') ? false: true,
      sorting
      },

    enableColumnActions: false,

    //to apply ordering 
    enableColumnOrdering: true,

    //To make our header sticky
    enableStickyHeader: true,

    //stop default pagination because we use MUI table pagination to navigate the page.
    //enablePagination: false,

    //resize the column
    // enableColumnResizing: true,
    // columnResizeMode: 'onEnd', 
    //columnResizeMode: 'onEnd',

    //layoutMode: "semantic",
    //to disable drag option of column header
    enableColumnDragging: false,

    //to display serial number
    // enableRowNumbers: true,
    // rowNumberDisplayMode: 'dynamic',

    //to display Action buttons
    enableRowActions: props.actions ? true : false,
    positionActionsColumn: 'last',
    renderRowActions: ({ row }) => [createAction(row.original)],
   // layoutMode: 'grid-no-grow',
    //To make column sticky
    //enableColumnPinning: false,

    //To apply basic initial values
    initialState: {
      showGlobalFilter: true,
      columnPinning: { right: ['mrt-row-actions'] },
      density: 'compact',
    },
    
    //Filter options
    manualFiltering: true,
    onGlobalFilterChange: setGlobalFilter,

    manualPagination: true,

    onSortingChange: setSorting,

    // defaultColumn:{
    //   size:200,
    // },

    muiCircularProgressProps:{
      color: 'primary',
      thickness: 3,
      size: 45,
    },
    muiSkeletonProps:{
      animation: 'wave',
      height: 28,
    },

    muiTableBodyCellProps: {
      sx: {
        border: '1px solid rgba(81, 81, 81, .1)',
        boxShadow: 'none'
      },
    },
    muiTableHeadCellProps: {
      sx: {
        border: '1px solid rgba(81, 81, 81, 0)',
      },
    },
  });

  return (

    <>
      <Paper sx={{
                backgroundColor: 'inherit',
                justifyContent: 'space-between',
                padding: '20px 14px',
                flexDirection: 'row',
                gap: '16px',
                boxShadow: 'none',
                backgroundColor: '#ffffff',
                '@media max-width: 768px': {
                  flexDirection: 'column',
                }
              }}>
        {/* Our Custom External Top Toolbar */}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6} display={"flex"}>
            <Box display={"flex"} paddingBottom={"10px"}>
              <MRT_GlobalFilterTextField table={table} />
            </Box>
          </Grid>
        </Grid>
        {!blankData && props.tableData!=undefined  ? (
        <Box>
        <MRT_TableContainer  table={table} sx={{
                  width: "100%",
                  height: props.height ? props.height : "53.5vh",
                  overflowY: "auto",
                  "& th": {
                    backgroundColor: "#dbdbf7",
                    fontSize: "1.1rem"
                  },
                  "& td": {
                    fontSize: "1rem",
                    // padding: "7px"
                  }
                }}/>
        <TablePagination
          component="div"
          rowsPerPageOptions={new PaginatorModel().largePageSizeOptions}
          count={rowCount}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton={true}
          showLastButton={true}
        />
        </Box>
        ):(
          <>
          <NoRecord></NoRecord>
          </>
        )}
      </Paper>
    </>
  );
}

export default ReactTable