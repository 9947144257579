import { Box } from "@material-ui/core";
import { Grid } from "@mui/material";
import NoRecord from "components/sharedComponent/noRecord/NoRecord";
import CustomButtonGrid from "components/uiComponents/CustomButtonGrid";
import CustomGrid from "components/uiComponents/CustomGrid";
import CustomTableGrid from "components/uiComponents/CustomTableGrid";
import FormikControl from "components/wrapperComponents/FormikControl";
import ReactTable from "components/wrapperComponents/ReactTable";
import { msgTypes } from "constants/msgTypes";
import { Form, Formik } from "formik";
import { MembershipModel } from "model/screensModels/membership/MembershipModel";
import { MailRequestModel } from "model/utilModels/MailRequestModel";
import { PaginatorModel } from "model/utilModels/PaginatorModel";
import { ResponseModel } from "model/utilModels/ResponseModel";
import React, { useEffect, useState } from "react";
import { BlogSchema } from "schemas/menu/blog/BlogSchema";
import MailRequestForm from "screens/mailRequestForm/MailRequestForm";
import { LocalStorageService } from "services/auth/LocalStorageService";
import { MemberSearchService } from "services/menu/memberSearchService/MemberSearchService";
import { UtilService } from "services/shared/UtilService";
import "./MemberSearch.scss";

const MemberSearch = () => {
  const [initialValue, setInitialValue] = useState(new MembershipModel());
  const [viewMode, setViewMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [memberList, setMemberList] = useState(new ResponseModel());
  const [noRecord, setNoRecord] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(0);
  const [mailRequestModel, setMailRequestModel] = useState(
    new MailRequestModel()
  );
  const [companyList, setCompanyList] = useState([]);

  const [selectedValues, setSelectedValues] = useState(undefined);
  const paginatorModel: PaginatorModel = new PaginatorModel();
  const [pagination, setPagination] = useState({
    pageSize: paginatorModel.pageSize,
    pageNumber: paginatorModel.pageNumber,
  });

  useEffect(() => {
    loadCountry();
    loadCompany();
    getLoginMemberDetails();
  }, []);

  //load country
  const loadCountry = async () => {
    const res = await UtilService.getCountryList();
    if (res.statusMsg === msgTypes.SUCCESS.msg) {
      setCountryList(res.data);
    }
  };

  // Load Company
  const loadCompany = async () => {
    const res = await UtilService.getCompanyList();
    if (res.statusMsg === msgTypes.SUCCESS.msg) {
      setCompanyList(res.data);
    }
  };

  const getLoginMemberDetails = async () => {
    //const res=await LocalStorageService.getUserDetails();
    const companyDetails = JSON.parse(
      await LocalStorageService.getUserDetails()
    );
    const mailObject = new MailRequestModel();
    mailObject.from = companyDetails.companyEmail;
    mailObject.to = [];
    mailObject.cc.push(companyDetails.accountingEmail);
    mailObject.bcc = companyDetails.contactPerson.map((option) => option.email);
    mailObject.subject = "";
    mailObject.body = "";
    setMailRequestModel(mailObject);
  };

  const handleSubmit = async (values: MembershipModel) => {
    const requestData = await prepareRequestBody(values);
    const res = await MemberSearchService.memberSearch(requestData);
    if (res.statusMsg === msgTypes.SUCCESS.msg) {
      setMemberList(res);
    }
  };

  //Prepare Data for API
  const prepareRequestBody = async (values: MembershipModel) => {
    const prepareData = new MembershipModel();

    prepareData.companyName = values?.companyName;
    prepareData.country = values?.country;

    return prepareData;
  };

  const onPageChangeHandler = (
    page: number,
    rowsPerPage: number,
    values: MembershipModel
  ) => {
    setSelectedValues(values);
    setPagination({
      pageNumber: page,
      pageSize: rowsPerPage,
    });
  };

  const checkboxSelected = (row) => {
    setSelectedCompany(0);
    let count = 0;
    memberList.data.tbody.map((data) => {
      if (row?.companyName === data?.companyName) {
        data.isSelected = !data.isSelected;
        if (data.isSelected === true) count++;
      } else if (data.status === true) {
        count++;
      }
    });
    setSelectedCompany(count);
    // if(count>0){
    //     getLoginmemberdetails();
    // }
  };

  // const getLoginmemberdetails = async() =>{

  //     initialValues.companyName = companyDetails.companyId;
  //     initialValues.address = companyDetails.addressLine1+","+companyDetails.city+","+companyDetails.countryName;
  // }

  return (
    <>
      <div className="memberSearch-card">
        <Formik
          enableReinitialize={true}
          initialValues={initialValue}
          //validationSchema={}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form>
              <Box marginLeft={1.5} marginRight={0.5} height={"80vh"}>
                <Grid
                  container
                  spacing={1}
                  bgcolor={"#fff"}
                  paddingTop={4}
                  paddingBottom={2}
                  paddingRight={"5px"}
                >
                  <CustomGrid
                    item={
                      <FormikControl
                        control="autocomplete"
                        name="companyName"
                        label="Company Name"
                        // placeholder="Company Name"
                        options={companyList}
                        keyfield="companyName"
                        valuefield="companyId"
                        disabled={viewMode}
                      />
                    }
                  />

                  <CustomGrid
                    item={
                      <FormikControl
                        control="autocomplete"
                        label="Category"
                        name="country"
                        placeholder="Select Category"
                        options={[]}
                        // keyfield="name"
                        // valuefield="countryIdentifier"
                      />
                    }
                  />

                  <CustomGrid
                    item={
                      <FormikControl
                        control="autocomplete"
                        label="Country"
                        name="country"
                        placeholder="Any Country"
                        requiredField
                        options={countryList}
                        keyfield="name"
                        valuefield="countryIdentifier"
                      />
                    }
                  />

                  <CustomGrid
                    item={
                      <Box display="flex" alignItems="center">
                        <FormikControl
                          control="button"
                          color="primary"
                          label="Search"
                          type="submit"
                        />
                      </Box>
                    }
                  />
                  {/* <CustomGrid className="button-submit"
                                            item={
                                                <>
                                                    <FormikControl control='button' color="primary" label="Search" type="submit" />
                                                </>
                                            }
                                        /> */}
                  {/* <div className="col-lg-12 col-md-6 search-button"> */}

                  {/* <FormikControl control='button' color="primary" label="Search" type="submit" /> */}
                  {/* //disabled={!formik.dirty || !formik.isValid || formik.isSubmitting} /> */}
                </Grid>
              </Box>

              <>
                {memberList.data.tbody && memberList.data.tbody.length ? (
                  <>
                    <CustomTableGrid
                      item={
                        <ReactTable
                          tableData={memberList}
                          formikValue={formik}
                          onPageChange={onPageChangeHandler}
                          loading={loading}
                          noRecordFound={true}
                          actions={[
                            { type: "checkbox", actionFunc: checkboxSelected },
                          ]}
                          positionActionsColumn="first"
                        />
                      }
                    />
                  </>
                ) : (
                  noRecord && <NoRecord />
                )}
              </>
            </Form>
          )}
        </Formik>

        {selectedCompany > 0 ? (
          <MailRequestForm mailrequestModel={mailRequestModel} />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default MemberSearch;
