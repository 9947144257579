import { Box } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import CreateNewsLetters from "./CreateNewsLetters";
import NewsLettersList from "./NewsLettersList";
import { NewsLettersModel } from "model/screensModels/NewsLettersModel";

export default function NewsLetters( ) {
  const [value, setValue] = React.useState(0);
  const [flag, setFlag] = useState(false);
  const [updateValue, setUpdateValue] = React.useState(new NewsLettersModel());

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setUpdateValue(new NewsLettersModel())

  };
  const getUpdateRecordValue = (newsLettersModel: NewsLettersModel) => {
    setUpdateValue(newsLettersModel);
  }

  const addNewsLetters = () =>{
    setFlag(true)
    setUpdateValue(new NewsLettersModel());
  }

  return (
    <Box sx={{ width: "100%" }} className="container-subheaders">
      <Box sx={{ borderBottom: 1, borderColor: "#ebedf3" }}>
        <div className="content-subheader">
          {!flag ? (
            <h4 className="mr-3">News Letters List</h4>
          ) : (
            <h4 className="mr-3">Add News Letters</h4>
          )}
          {!flag ? (
            <button className="btn primary" onClick={addNewsLetters}>
              Add News Letters
            </button>
          ) : (
            ""
          )}
        </div>
      </Box>

      <div className="sub-content-sec">
        {!flag ? (
          <NewsLettersList setFlag={setFlag} updateRecord={getUpdateRecordValue}></NewsLettersList>
        ) : (
          <CreateNewsLetters setFlag={setFlag} updateRecord={updateValue}></CreateNewsLetters>
        )}
      </div>
    </Box>
  );
}
