export const RouteType = {
  FILE: "/file",

  //Crud routes
  STORE: "/store",
  DELETE: "/delete",

  //login
  ADMIN: "/admin",
  AUTH: "/auth",
  LOGIN: "/login",

  HOME: "/home",
  DASHBOARD: "/dashboard",
  MENU: "/menu",

  //Blog
  BLOG: "/blog",

  // Events
  EVENT: "/event",
  UPCOMING_EVENTS: "/upcoming-events",
  PAST_EVENTS: "/past-events",

  GET_COUNTRY: "/get-country",
  GET_STATE: "/get-state",
  GET_CITY: "/get-city",

  COMPANY_LIST: "/company-list",

  //member Search
  MEMBER_SEARCH: "/member-search",

  //upload certificate
  UPLOAD_CERTIFICATE: "/upload-certificate",

  //Grievance Redressal
  GRIEVANCE_REDRESSAL: "/grievance-redressal",

  //UPLOAD_CERTIFICATE: '/upload-certificate',
  CERTIFICATE: "/certificate",
  //DELETE_CERTIFICATE: '/delete-certificate',

  //Invoice management
  INVOICE_MANAGEMENT: "/invoice-management",

  //FounderMember
  RECENT_MEMBERS: "/recent-members",

  // News Announcements
  NEWS_ANNOUNCEMENTS: "/newsubmission",
  ANNOUNCEMENT: "/announcement",

  // Member Login
  MEMBER_LOGIN: "/get-loginlog",

  // Announcement Display
  ANNOUNCEMENT_DISPLAY: "/view-announcement",

  // Company Profile
  COMPANY_PROFILE: "/company-profile",

  // Company Services
  COMPANY_SERVICES: "/company-services",

  // News Letters
  NEWS_LETTERS: "/newsletter",
  RECENT_NEWSLETTERS: "/recent-newsletter",

  // Contact Person List By Company Id
  COMPANY_LIST_BY_ID: "/company-list-by-id",


  COMPANY_SERVICES_LIST_BY_ID: "/company-services-list-by-id",

  INVOICE_MANAGEMENT_STATUS_COUNT: "/invoice-management/status-count",


  // Contact Enquiry
  CONTACT_ENQUIRY: "/contact-enquiry",

  // Featured In News Letter
  FEATURED_IN_NEWSLETTER: "/featured-in-newsletter",

  // News Letter Subscribers
  NEWSLETTER_SUBSCRIBERS: "/newsletter-subscribers",

  // Registered Member List
  MEMBER: "/member",

  // Membership Status
  UPDATE_MEMBER_STATUS: "/update-member-status",

  // Get Company List By Country Id
  COMPANY_LIST_BY_COUNTRYID: "/company-list-by-countryid",

  // Get Contact Person List By Company ID
  CONTACTPERSON_LIST_BY_COMPANYID: "/contactPerson-list-by-companyid",

  // Meet Online
  MEMBER_MEET_ONLINE: "/member-meet-online",
};
