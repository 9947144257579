import { useEffect, useRef } from 'react';
import { Form, Formik } from "formik";
import { Box, Grid, Typography } from "@mui/material";
import CustomGrid from "components/uiComponents/CustomGrid";
import CustomButtonGrid from "components/uiComponents/CustomButtonGrid";
import FormikControl from "components/wrapperComponents/FormikControl";
import { useState } from "react";
import { NewsLettersModel } from "model/screensModels/NewsLettersModel";
import { NewsLettersSchema } from "schemas/menu/newsLetters/NewsLettersSchema";
import { UploadFileService } from "services/shared/UploadFileService";
import { NewsLettersService } from "services/menu/newsLettersService/NewsLettersService";
import { msgTypes } from "constants/msgTypes";

const CreateNewsLetters = ({ setFlag, updateRecord }) => {
  const [viewMode, setViewMode] = useState(false);
  const [initialValues, setInitialValues] = useState(new NewsLettersModel());
  const onSubmit = async (values: NewsLettersModel) => {
    addNewsLetters(values);
  };

  // Prepare Data For API
  useEffect(() => {
    if (updateRecord) {
      setInitialValues(updateRecord)
    }
  }, [updateRecord])


  const prepareRequestBody = async (values: NewsLettersModel) => {
    const prepareData = new NewsLettersModel();

    if ((initialValues.newsLetterId)?.length > 0) {
      prepareData.newsLetterId = initialValues.newsLetterId;
    } else {
      prepareData.newsLetterId = values.newsLetterId ? values.newsLetterId : '';
    }

    prepareData.title = values.title ? values.title : "";
    prepareData.image = values.image ? values.image : "";
    prepareData.description = values.description ? values.description : "";

    return prepareData;
  };

  const addNewsLetters = async (values: NewsLettersModel) => {
    const requestData = await prepareRequestBody(values);
    const res = await NewsLettersService.addNewsLetters(requestData);
    if (res.statusMsg === msgTypes.SUCCESS.msg) {
      setInitialValues(new NewsLettersModel());
      setFlag(false);
    }
  };



  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={NewsLettersSchema}
        onSubmit={onSubmit}
      >
        {({ isValid, dirty, isSubmitting, values }) => (
          <Form>
            <div className='container p-2'>
                <div className="row">
                  <div className="col-md-5   position-relative">
                    <FormikControl
                      control="textfield"
                      label="Title"
                      name="title"
                      placeholder="Enter Title"
                      disabled={viewMode}
                      requiredField
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-5  position-relative ">
                    <FormikControl
                      control="uploadImage"
                      label="Upload File"
                      name="imageFile"
                      nameUrl="image"
                      disabled={viewMode}
                      requiredField
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-5 textarea-sec   position-relative">
                    <FormikControl
                      control="textfield"
                      name="description"
                      label="Description"
                      placeholder="Enter Description"
                      type="text"
                      variant="outlined"
                      multiline
                      disabled={viewMode}
                      rows={8}
                      requiredField
                    />
                  </div>
                </div>

                <div className="row">
                <div className="col-md-5">
                  {!viewMode ? (
                    <div className="mt-3 d-flex justify-content-end">
                      <div className='pr-2'>
                        <FormikControl
                          control="button"
                          color="primary"
                          label={values.newsLetterId === '' ? "Save" : "Update"}
                          type="submit"
                          disabled={!dirty || !isValid || isSubmitting}
                        />
                      </div>
                      <div>
                        <FormikControl
                          control="button"
                          className="btn btn-danger"
                          color="warning"
                          label="Back"
                          type="reset"
                          onClick={() => {
                            setFlag(false);
                          }}
                        />
                      </div>
                    </div>
                  ) : null}
                  </div>
                </div>
            </div>
            </Form>
        )}
      </Formik>
    </>
  );
};

export default CreateNewsLetters;
