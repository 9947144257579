import CustomTableGrid from "components/uiComponents/CustomTableGrid";
import FormikControl from "components/wrapperComponents/FormikControl";
import { msgTypes } from "constants/msgTypes";
import { Form, Formik } from "formik";
import { MembershipModel } from "model/screensModels/membership/MembershipModel";
import { PaginatorModel } from "model/utilModels/PaginatorModel";
import { ResponseModel } from "model/utilModels/ResponseModel";
import React, { useEffect, useMemo, useState } from "react";
import { RegisteredMembersService } from "services/menu/registeredMembersService/RegisteredMembersService";
import { conformationAlert } from "services/shared/alert";

const RegisteredMembersList = () => {
  const [registeredMembersList, setRegisteredMembersList] = useState(
    new ResponseModel()
  );

  const [noRecord, setNoRecord] = useState(false);
  const paginatorModel: PaginatorModel = new PaginatorModel();
  const [pagination, setPagination] = useState({
    pageSize: paginatorModel.pageSize,
    pageNumber: paginatorModel.pageNumber,
    filter: "",
    orderBy: "",
    desc: "",
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: "companyName",
        header: "Company Name",
        size: 100,
      },
      {
        accessorKey: "companyEmail",
        header: "Company Email",
        size: 100,
      },
      {
        accessorKey: "membershipType",
        header: "Membership Type",
        size: 50,
      },
      {
        accessorKey: "memberStatus",
        header: "Membership Status",
        size: 50,
      },
    ],
    []
  );

  useEffect(() => {
    getregisteredMembersList();
  }, [
    pagination.pageNumber,
    pagination.pageSize,
    pagination.filter,
    pagination.orderBy,
    pagination.desc,
  ]);

  // Prepare Request Body To Get Registered Members List
  const prepareRequest = () => {
    const requestBody = new PaginatorModel();
    requestBody.pageNumber = pagination.pageNumber;
    requestBody.pageSize = pagination.pageSize;
    requestBody.filter = pagination.filter;
    requestBody.orderBy = pagination.orderBy;
    requestBody.desc = pagination.desc;
    return requestBody;
  };

  //Get Featured Registered Member List
  const getregisteredMembersList = async () => {
    const requestBody = prepareRequest();
    const res = await RegisteredMembersService.getregisteredMembersList(
      requestBody
    );
    if (res.statusMsg === msgTypes.SUCCESS.msg) {
      setRegisteredMembersList(res);
    }
  };

  const initialValues = {};
  const onSubmit = () => {
    getregisteredMembersList();
  };

  const updateStatus = async (record: MembershipModel) => {
    const requestData: MembershipModel = new MembershipModel();
    requestData.companyId = record.companyId;

    const updateStatusFlag = await conformationAlert(
      msgTypes.CONFORMATION_MESSAGE.UPDATE_RECORD,
      msgTypes.CONFORMATION_ICON.ERROR
    );
    if (updateStatusFlag) {
      const res = await RegisteredMembersService.updateMembershipStatus(
        requestData
      );
      if (res.statusMsg === msgTypes.SUCCESS.msg) {
        getregisteredMembersList();
      }
    }
  };
  return (
    <>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {(formik) => (
          <Form>
            <CustomTableGrid
              item={
                <FormikControl
                  control="materialTable"
                  tableColumn={columns}
                  tableData={registeredMembersList}
                  formikValue={formik}
                  setPagination={setPagination}
                  actions={[{ type: "switch", actionFunc: updateStatus }]}
                />
              }
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default RegisteredMembersList;
