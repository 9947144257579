import { msgTypes } from "constants/msgTypes";
import { Validation } from "constants/Validation";
import * as Yup from "yup";

export const InvoiceManagementSchema = 

  Yup.object().shape({
    invoiceId: Yup.string(),
    fromCompanyId: Yup.string().required(Validation.required.message.replaceAll("^","Company Name"))
                .matches(Validation.plaintext.regex , Validation.plaintext.message),

    subject: Yup.string().required(Validation.required.message.replaceAll("^","Subject"))
                .matches(Validation.plaintext.regex , Validation.plaintext.message),
    status: Yup.string().required(Validation.required.message.replaceAll("^","status"))
                .matches(Validation.plaintext.regex , Validation.plaintext.message),
    uploadedInvoice: Yup.string().required(Validation.required.message.replaceAll("^","Upload Invoice")),
    uploadedInvoiceFile: Yup.mixed().nullable().test(
        "FILE_FORMAT",
        "Uploaded file has unsupported format",
        (value) => !value || (value && msgTypes.SUPPORTED_IMAGE_FORMAT.includes(value?.type))
    ).test(
        "FILE_SIZE",
        "Uploaded file is too big",
        (value) =>!value || (value && value.size <=1024 * 1024)
    )

  })






