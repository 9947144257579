import { msgTypes } from "constants/msgTypes";
import { Validation } from "constants/Validation";
import * as Yup from "yup";

export const BlogSchema = Yup.object({
    blogId: Yup.string(),
    title: Yup.string().required(Validation.required.message.replaceAll("^","Blog title"))
                    .matches(Validation.plaintext.regex , Validation.plaintext.message),
    metaTitle: Yup.string()
                    .matches(Validation.plaintext.regex , Validation.plaintext.message),
    shortDescription: Yup.string()
                    .matches(Validation.plaintext.regex , Validation.plaintext.message),
    description: Yup.string().required(Validation.required.message.replaceAll("^","Blog description"))
                    .matches(Validation.plaintext.regex , Validation.plaintext.message),
    metaDescription: Yup.string().matches(Validation.plaintext.regex , Validation.plaintext.message),
    altTag: Yup.string().matches(Validation.plaintext.regex , Validation.plaintext.message),
    image: Yup.string().required(Validation.required.message.replaceAll("^","Image")),
    imageFile: Yup.mixed().nullable()
    .test(
        "FILE_FORMAT",
        "Uploaded file has unsupported format",
        (value) => !value || (value && msgTypes.SUPPORTED_IMAGE_FORMAT.includes(value?.type))
    )
    .test(
        "FILE_SIZE",
        "Uploaded file is too big",
        (value) =>!value || (value && value.size <=1024 * 1024)
    )
   
})
