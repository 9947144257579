import { Formik, Form } from 'formik';
import React, { useState, useEffect, useMemo } from 'react';
import CustomTableGrid from 'components/uiComponents/CustomTableGrid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ResponseModel } from 'model/utilModels/ResponseModel';
import { InvoiceManagementModel } from 'model/screensModels/InvoiceManagementModel';
import { InvoiceManagementService } from 'services/menu/invoiceManagementService/InvoiceManagementService';
import { conformationAlert } from 'services/shared/alert';
import { msgTypes } from 'constants/msgTypes';
import { PaginatorModel } from 'model/utilModels/PaginatorModel';
import Box from '@mui/material/Box';
import { LocalStorageService } from 'services/auth/LocalStorageService';
import FormikControl from 'components/wrapperComponents/FormikControl';
import ApiUrl from 'services/shared/ApiUrl';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
interface MyInvoiceProps {
  setFlag: (flag: boolean) => void;
  updateRecord: (record: InvoiceManagementModel) => void;
}

const OwedInvoice: React.FC<MyInvoiceProps> = ({ setFlag, updateRecord }) => {
  const [owedInvoiceList, setOwedInvoiceList] = useState<ResponseModel>(new ResponseModel());
  const [pagination, setPagination] = useState({
    pageSize: new PaginatorModel().pageSize,
    pageNumber: new PaginatorModel().pageNumber,
    filter: "",
    orderBy: "",
    desc: "",
  });
  const [activeTab, setActiveTab] = useState(0);
  const [status, setStatus] = React.useState(msgTypes.INVOICE_STATUS.PAID);

  const columns = useMemo(() =>
    [
      {
        accessorKey: "fromCompanyName",
        header: "From Company Name",
        size: 150
      },
      {
        accessorKey: "toCompanyName",
        header: "To Company Name",
        size: 200
      },
      {
        accessorKey: "subject",
        header: "Subject"
      },
      {
        accessorKey: "status",
        header: "Status"
      },
      {
        accessorKey: "uploadingDate",
        header: "Invoice Uploading Date"
      },
      {
        header: 'Uploaded Invoice',
        Cell: ({ renderedCellValue, row }) => (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <img alt="avatar" height={35} width={35} src={row.original.uploadInvoice ? ApiUrl.fileBaseUrl + row.original.uploadInvoice + "/" : "/image/userimage.jpg"} loading="lazy" />
          </Box>
        ),
        size: 50
      },


    ], []);


  useEffect(() => {
    getInvoiceList();
  }, [activeTab, pagination.pageNumber, pagination.pageSize, pagination.filter, pagination.orderBy, pagination.desc])

  const prepareRequest = async () => {
    const requestBody = new InvoiceManagementModel();
    requestBody.pageNumber = pagination.pageNumber;
    requestBody.pageSize = pagination.pageSize;
    requestBody.filter = pagination.filter;
    requestBody.orderBy = pagination.orderBy;
    requestBody.desc = pagination.desc;

    if (activeTab === 0) {
      requestBody.status = msgTypes.INVOICE_STATUS.PAID
    } else if (activeTab === 1) {
      requestBody.status = msgTypes.INVOICE_STATUS.OUTSTANDING
    } else {
      requestBody.status = msgTypes.INVOICE_STATUS.OVERDUE
    }

    const userDetails = JSON.parse(await LocalStorageService.getUserDetails());
    requestBody.toCompanyId = userDetails.companyId;
    
    return requestBody;
  };


  const getInvoiceList = async () => {
    const requestBody = await prepareRequest();
    try {
      const res = await InvoiceManagementService.getInvoiceList(requestBody);
      if (res.statusMsg === msgTypes.SUCCESS.msg) {
        setOwedInvoiceList(res);
      }
    } catch (error) {
      console.error('Failed to fetch invoice list:', error);
    }
  };

  const handleTabChange = (index: number) => {
    setActiveTab(index);
  };

  const editRecord = (record) => {
    updateRecord(record);
    setFlag(true)
  }

  const deleteRecord = async (record: InvoiceManagementModel) => {
    const requestData: InvoiceManagementModel = new InvoiceManagementModel();
    requestData.invoiceId = record.invoiceId;

    const deleteFlag = await conformationAlert(msgTypes.CONFORMATION_MESSAGE.DELETE_RECORD, msgTypes.CONFORMATION_ICON.ERROR);
    if (deleteFlag) {
      const res = await InvoiceManagementService.deleteInvoice(requestData);
      if (res.statusMsg === msgTypes.SUCCESS.msg) {
        getInvoiceList();
      }
    }
  };

  const initialValues = {};
  const onSubmit = () => {
    getInvoiceList();
  };



  const toogleHandleChange = (
    event: React.MouseEvent<HTMLElement>,
    newStatus: string,
  ) => {
    setStatus(newStatus);
  };

  return (
    <>
      <ToggleButtonGroup
        color="primary"
        value={status}
        exclusive
        onChange={toogleHandleChange}
        aria-label="Platform"
      >
        <ToggleButton value={msgTypes.INVOICE_STATUS.PAID} onClick={() => handleTabChange(0)}>{msgTypes.INVOICE_STATUS.PAID}</ToggleButton>
        <ToggleButton value={msgTypes.INVOICE_STATUS.OUTSTANDING} onClick={() => handleTabChange(1)}>{msgTypes.INVOICE_STATUS.OUTSTANDING}</ToggleButton>
        <ToggleButton value={msgTypes.INVOICE_STATUS.OVERDUE} onClick={() => handleTabChange(2)}>{msgTypes.INVOICE_STATUS.OVERDUE}</ToggleButton>
      </ToggleButtonGroup>


      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {formik => (
          <Form>
            <CustomTableGrid
              item={
                <FormikControl
                  control='materialTable'
                  tableColumn={columns}
                  tableData={owedInvoiceList}
                  formikValue={formik}
                  setPagination={setPagination}
                  actions={[{ type: "icon", icon: <EditIcon />, actionFunc: editRecord },
                  { type: "icon", icon: <DeleteIcon color='error' />, actionFunc: deleteRecord }
                  ]}
                />
              }
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default OwedInvoice;
