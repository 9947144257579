export class AnnouncementModel {
    announcementId: string;
    title: string;
    message: string;
    attachment: string;
    attachmentFile: any;
   
    constructor() {
        this.announcementId="";
        this.title="";
        this.message="";
        this.attachment="";
        this.attachmentFile =null;
    }
}
