import React, { useEffect, useState } from 'react';
import { usePrevious } from "@uidotdev/usehooks";
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import { useNavigate } from "react-router-dom";
import { Avatar, Box, IconButton, Stack } from '@mui/material';
import { useAuth } from 'util/auth';
import showAlert from 'services/shared/alert';
import { msgTypes } from 'constants/msgTypes';
import { useMediaQuery } from '@material-ui/core';
import { AuthService } from 'services/auth/AuthService';
import "./SideMenu.scss";

// import { menuItems } from 'constants/menuItems';

const rootSubmenuKeys = ['sub1', 'sub2', 'sub3', 'sub4'];
type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  danger?: boolean,
  type?: 'group',
): MenuItem {
  return { key, icon, children, label, type, danger } as MenuItem;
}

const authenticateMenuItems = (userRole: string, menuItems: any) => {
  userRole = 'ADMIN'
  items = [];

  menuItems.forEach(menu => {

    //authenticate menu items  
    if (menu.children.length==0) {
      if (menu.role.includes(userRole))
         items.push(getItem(menu.label, menu.key, <i className={menu.icon} aria-hidden="true"></i>, undefined, menu.danger));
      } else {
      //authenticate children items
      const childmenu: MenuItem[] = [];
      menu.children?.forEach(element => {
        if (element.role.includes(userRole)) {
          // const item = getItem(element.label, element.key, <i className="fa fa-repeat" />)
          const item = getItem(element.label, element.key, <i className={menu.icon} aria-hidden="true"></i>)
          childmenu.push(item);
        }
      });
      if (childmenu.length > 0) {
        // const itemWithChildren = getItem(menu.label, menu.key, <i className="fa fa-repeat" />, childmenu)
        const itemWithChildren = getItem(menu.label, menu.key, <i className={menu.icon} aria-hidden="true"></i>, childmenu)
        items.push(itemWithChildren);
      }
    }
  })
  return items;
}

let items: MenuItem[] = [];



const SideMenu = () => {
  const auth = useAuth();

  const navigate = useNavigate();
  const [openKeys, setOpenKeys] = useState(['']);
  const toggleCollapsed = () => {
    auth.setMenuCollapsed(!auth.getMenuCollapsed())
  };
  const [menuList, setMenuList] = useState([]);
  

  useEffect(() => {
    async function fetchMenu() {
      const res = await AuthService.getMenuList();
      if (res.statusMsg === msgTypes.SUCCESS.msg) {
        setMenuList(res.data);
      }
    }
    fetchMenu();
  }, []);

  const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const isBigScreen = useMediaQuery("(min-width: 1140px)");
  const prevBigScreen = usePrevious(isBigScreen);
  React.useEffect(() => {
    if (!(isBigScreen !== prevBigScreen && isBigScreen !== auth.getMenuCollapsed())) {
      auth.setMenuCollapsed((prev) => !prev);
    }
  }, [isBigScreen, prevBigScreen]);

  useEffect(() => {
    if (window.innerWidth < 1140) {
      auth.setMenuCollapsed(true);
    }
  }, [window.innerWidth]);

  return (
    <Box className='menu' marginTop={8} width={auth.getMenuCollapsed() ? { xs: 0, md: 80 } : 270} bgcolor={'black'} height={"91.3vh"}>
      <Stack direction={"column"} alignItems={auth.getMenuCollapsed() ? "center" : "end"}>
        <div className='logo-section'><img src='/images/logo2.png' className='logo-img' /></div>
        <IconButton onClick={toggleCollapsed} sx={{ color: 'white' }}>
          {!auth.getMenuCollapsed() ?
            <>
              <i className="fa fa-angle-double-left" aria-hidden="true"></i>
              <Box className="collapse-btn"> &nbsp;</Box>
            </>
            : <i className="fa fa-angle-double-right" aria-hidden="true"></i>}
        </IconButton>
      </Stack>
      <Menu
        className='menu-content'
        style={{ backgroundColor: "black", width: auth.getMenuCollapsed() ? 80 : 270 }}
        defaultSelectedKeys={['1']}
        mode="inline"
        theme="dark"
        items={authenticateMenuItems(auth.getUserRole(), menuList)}
        onClick={({ key }) => {
          if (key === 'logout') {
            localStorage.clear();
            auth.logout();
            navigate('/login')
            showAlert(msgTypes.SUCCESS.msg, msgTypes.LOGGED_OUT, msgTypes.success, msgTypes.OK_KEY);
          } else {
            navigate(key)
          }
        }}
        inlineCollapsed={auth.getMenuCollapsed()}
        //to open one children at a time
        onOpenChange={onOpenChange}
        openKeys={openKeys}
      />
    </Box>
  );
}
export default SideMenu;