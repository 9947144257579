import { RouteType } from "constants/RouteType";
import { PaginatorModel } from "model/utilModels/PaginatorModel";
import { AuthService } from "services/auth/AuthService";

export class NewsLetterSubscribersService {

  // To Get News Letter Subscribers List

  static getNewsLetterSubscribersList = async (requestBody: PaginatorModel) => {
    const url = RouteType.ADMIN + RouteType. NEWSLETTER_SUBSCRIBERS;
    const response = AuthService.postCryptoRequest(url, JSON.stringify(requestBody));
    return response;
  };
}