import { Box } from "@mui/material";
import * as React from "react";
import ContactEnquiryList from "./ContactEnquiryList";

export default function ContactEnquiry() {
  return (
    <Box sx={{ width: "100%" }} className="container-subheaders">
      <Box sx={{ borderBottom: 1, borderColor: "#ebedf3" }}>
        <div className="content-subheader">
          <h4 className="mr-3">Contact Enquiry List</h4>
        </div>
      </Box>
      <div className="sub-content-sec">
        <ContactEnquiryList></ContactEnquiryList>
      </div>
    </Box>
  );
}
