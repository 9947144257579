import { RouteType } from "constants/RouteType";
import { CompanyServicesModel } from "model/screensModels/CompanyServicesModel";
import { PaginatorModel } from "model/utilModels/PaginatorModel";
import { AuthService } from "services/auth/AuthService";

export class CompanyServicesService {
  // constructor() { }

  // To Get Company Services List
static getCompanyServicesList = async (requestBody: PaginatorModel) => 
  {
  const url = RouteType.ADMIN + RouteType.COMPANY_SERVICES;
  const response = AuthService.postCryptoRequest(url, JSON.stringify(requestBody));
  return response;
};


  // Save Company Services

  static addCompanyServices = async (requestBody: CompanyServicesModel) => {
    const url = RouteType.ADMIN + RouteType.COMPANY_SERVICES + RouteType.STORE;
    const response = AuthService.postCryptoRequest(url, JSON.stringify(requestBody));
    return response;
  };

  // Delete Company Service

  static deleteCompanyServices = async (requestBody: CompanyServicesModel) => {
    const url = RouteType.ADMIN + RouteType.COMPANY_SERVICES + RouteType.DELETE;
    const response = AuthService.postCryptoRequest(url, JSON.stringify(requestBody));
    return response;
  }


  static getCompanyServiceListById = async (requestBody: CompanyServicesModel) => {
    const url = RouteType.ADMIN + RouteType.COMPANY_SERVICES_LIST_BY_ID;
    const response = AuthService.postCryptoRequest(url, JSON.stringify(requestBody));
    return response;
  }
}