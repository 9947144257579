import { CardContent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Card, Box, Typography, Button, Grid } from '@mui/material';
import { Form, Formik, FieldArray } from 'formik';
import FormikControl from 'components/wrapperComponents/FormikControl';
import { Add } from '@mui/icons-material';
import { msgTypes } from 'constants/msgTypes';
import CustomButtonGrid from 'components/uiComponents/CustomButtonGrid';
import { GrievenceRedressalModel } from 'model/screensModels/GrievenceRedressalModel';
import { GrievenceRedressalService } from 'services/menu/grievencereRedressalService/GrievenceRedressalService';
import { GrievenceRedressalSchema } from 'schemas/menu/grievenceRedressalSchema/GrievenceRedressalSchema';
import { UtilService } from 'services/shared/UtilService';
import './GrievenceRedressal.scss';
import { LocalStorageService } from 'services/auth/LocalStorageService';
import { MembershipModel } from 'model/screensModels/membership/MembershipModel';

const GrievenceRedressal = () => {
    const [initialValues, setInitialValues] = useState(new GrievenceRedressalModel);
    const [companyList, setCompanyList] = useState([]);

    useEffect(() => {
        loadCompany();
        getLoginmemberdetails();
    }, [])

    //load country
    const loadCompany = async () => {
        const res = await UtilService.getCompanyList();
        if (res.statusMsg === msgTypes.SUCCESS.msg) {
            setCompanyList(res.data);
        }
    }

    const getLoginmemberdetails = async () => {
        const companyDetails = JSON.parse(await LocalStorageService.getUserDetails());
        initialValues.companyName = companyDetails.companyId;
        initialValues.address = companyDetails.addressLine1 + "," + companyDetails.city + "," + companyDetails.countryName;
    }

    const prepareRequestBody = async (values: GrievenceRedressalModel) => {
        const prepareData = new GrievenceRedressalModel();

        // Address and Company Information
        prepareData.companyName = values.companyName ? values.companyName : '';
        prepareData.address = values.address ? values.address : '';
        prepareData.debtorCompany = values.debtorCompany ? values.debtorCompany : '';
        prepareData.debtorCompanyAddress = values.debtorCompanyAddress ? values.debtorCompanyAddress : '';
        // Invoice Section
        prepareData.invoiceData = await Promise.all(values.invoiceData.map(async (invoice) => ({
            invoiceNumber: invoice.invoiceNumber ? invoice.invoiceNumber : '',
            currency: invoice.currency ? invoice.currency : '',
            invoiceAmount: invoice.invoiceAmount ? invoice.invoiceAmount : undefined,
            issueDate: invoice.issueDate ? invoice.issueDate : '',
            dueDate: invoice.dueDate ? invoice.dueDate : null,
            invoice: invoice.invoice ? await UtilService.getImagePath(invoice.invoice) : ""
        }))
        )

        // Additional Sections
        prepareData.currencyData = values.currencyData.map(section => ({
            currency: section.currency ? section.currency : '',
            unpaidAmount: section.unpaidAmount ? section.unpaidAmount : undefined
        }));


        // Networks Section
        prepareData.networkData = values.networkData.map(network => ({
            networkMembership: network.networkMembership ? network.networkMembership : '',
            contactPerson: network.contactPerson ? network.contactPerson : '',
            personEmail: network.personEmail ? network.personEmail : ''
        }));

        // Upload Sections

        prepareData.communication = await Promise.all(values.communication.map(async (data: any) =>
            data ? await UtilService.getImagePath(data.file) : ""
        ));

        prepareData.accountStatement = await Promise.all(values.accountStatement.map(async (data: any) =>
            data ? await UtilService.getImagePath(data.file) : ""
        ));

        prepareData.shippingDocument = await Promise.all(values.shippingDocument.map(async (data: any) =>
            data ? await UtilService.getImagePath(data.file) : ""
        ));

        prepareData.deliveryReceipt = await Promise.all(values.deliveryReceipt.map(async (data: any) =>
            data ? await UtilService.getImagePath(data.file) : ""
        ));

        prepareData.addentialRevelentDocoument = await Promise.all(values.addentialRevelentDocoument.map(async (data: any) =>
            data ? await UtilService.getImagePath(data.file) : ""
        ));


        // Other fields
        prepareData.lastDate = values.lastDate ? values.lastDate : null;
        prepareData.formContact = values.formContact ? values.formContact : '';
        prepareData.additionalNotes = values.additionalNotes ? values.additionalNotes : '';
        prepareData.formDeclare = values.formDeclare ? values.formDeclare : false;
        prepareData.termAndCondition = values.termAndCondition ? values.termAndCondition : false;
        prepareData.redressalId = values.redressalId ? values.redressalId : "";

        prepareData.claimForm = await UtilService.getImagePath(values.claimForm);

        return prepareData;
    };




    const onSubmit = async (values: GrievenceRedressalModel) => {
        const requestData = await prepareRequestBody(values);
        const res = await GrievenceRedressalService.addGrievanceRedressal(requestData);
        if (res.statusMsg === msgTypes.SUCCESS.msg) {
            return true
        } else {
            return false
        }
    };


    return (
        <>

            <div className='content-subheader'>
                <h4 className='mr-3'>Grievance Redressal</h4>

            </div>

            <Box className="outer-in">
                <Box className="outer-box pl-1 pr-7">
                    <Card sx={{ width: '100%', my: 2, padding: '10px', backgroundColor: 'lightwhite', marginLeft: '10px' }}>
                        <CardContent>
                            The grievance  redressal is administered by 1wn Logistics Networks management. it is a benefit for all enrolled offices of member companies of the 1wn Logistics Network.
                            Our belief and focus is on professionalism and our experience within this industry proves that if companies participating within a network are professional and communicate effectivly,many of the problems faced between agents that often turn into disputes can be reduced significantly.
                        </CardContent>
                    </Card>
                </Box>
            </Box>

            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <Box className="outer-in">
                            <Card className="form-container">
                                <Formik
                                    // enableReinitialize={true}
                                    initialValues={initialValues}
                                    validationSchema={GrievenceRedressalSchema}
                                    onSubmit={async (values, { resetForm }) => {
                                        const response = await onSubmit(values);
                                        if (response) {
                                            resetForm();
                                        }
                                    }}
                                >
                                    {({ isValid, values, isSubmitting, dirty, errors, }) => (
                                        <Form>
                                            <div className="company-profile-sec one">
                                                <div className="row mx-0">
                                                    <div className="col-12 status-sec">
                                                        <Typography className="title-2">
                                                            Enter Invoice
                                                        </Typography>
                                                    </div>
                                                </div>
                                                <div className="row p-4">
                                                    <div className="col-lg-4 col-md-6">
                                                        <FormikControl control='autocomplete'
                                                            label="Company Name"
                                                            name="companyName"
                                                            // placeholder="Select Debtor Company"
                                                            // requiredField
                                                            options={companyList}
                                                            keyfield="companyName"
                                                            valuefield="companyId"
                                                            disabled={true}
                                                        />
                                                    </div>
                                                    <div className="col-lg-4 col-md-6">
                                                        <FormikControl
                                                            control="textfield"
                                                            name="address"
                                                            label="Address"
                                                            disabled={true}
                                                        />
                                                    </div>
                                                    <div className="col-lg-4 col-md-6">
                                                        <FormikControl control='autocomplete'
                                                            label="Debtor Company"
                                                            name="debtorCompany"
                                                            placeholder="Select Debtor Company"
                                                            // requiredField
                                                            options={companyList}
                                                            keyfield="companyName"
                                                            valuefield="companyId"
                                                        />
                                                    </div>
                                                    <div className="col-lg-4 col-md-6">
                                                        <FormikControl
                                                            control="textfield"
                                                            name="debtorCompanyAddress"
                                                            label="Debtor company address"
                                                            placeholder=""
                                                            disabled={false}
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                            <Grid container spacing={2}>



                                                <FieldArray name="invoiceData">
                                                    {({ push }) => (
                                                        <>
                                                            {values.invoiceData.map((invoice, index) => (
                                                                <React.Fragment key={index}>
                                                                    {index === 0 && (
                                                                        <Box className="type-3"
                                                                            sx={{
                                                                                display: 'flex',
                                                                                justifyContent: 'space-between',
                                                                                alignItems: 'center',
                                                                                backgroundColor: '#f6f5f5',
                                                                                width: '100%',
                                                                                lineHeight: '3.5rem',

                                                                                marginLeft: 0,
                                                                                p: 0,
                                                                                height: '50px',

                                                                            }}
                                                                        >

                                                                            {/* <Grid item sm={12} lg={6} md={6}> */}
                                                                            <Button
                                                                                sx={{ backgroundColor: 'orange' }}
                                                                                type="button"
                                                                                variant="contained"
                                                                                color="primary"
                                                                                onClick={() =>
                                                                                    push({
                                                                                        invoiceNumber: '',
                                                                                        invoice: null,
                                                                                        currency: '',
                                                                                        invoiceAmount: '',
                                                                                        issueDate: null,
                                                                                        dueDate: null,
                                                                                    })
                                                                                }
                                                                                startIcon={<Add />}

                                                                            // sx={{ mt: 1 }}

                                                                            >
                                                                                Add More
                                                                            </Button>
                                                                            {/* </Grid> */}
                                                                        </Box>
                                                                    )}

                                                                    <Card
                                                                        sx={{
                                                                            width: '100%',
                                                                            textAlign: 'justify',
                                                                            boxShadow: 'unset !important',
                                                                            // mb: 2,
                                                                            // p: 2,
                                                                        }}
                                                                    >




                                                                        <div className="company-profile-sec two">
                                                                            <div className="row mx-0">
                                                                                <div className="col-12 status-sec">
                                                                                    <Typography className="title-2">
                                                                                        Enter Invoice
                                                                                    </Typography>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row p-4">
                                                                                <div className="col-lg-4 col-md-6">
                                                                                    <FormikControl
                                                                                        control="textfield"
                                                                                        name={`invoiceData[${index}].invoiceNumber`}
                                                                                        label="Invoice Number"
                                                                                        placeholder="Enter Invoice Number"
                                                                                    // requiredField
                                                                                    />
                                                                                </div>
                                                                                <div className="col-lg-4 col-md-6">
                                                                                    <FormikControl
                                                                                        control="uploadImage"
                                                                                        name={`invoiceData[${index}].invoice`}
                                                                                        label="Upload Invoice"
                                                                                    // requiredField
                                                                                    />
                                                                                </div>
                                                                                <div className="col-lg-4 col-md-6">
                                                                                    <FormikControl
                                                                                        control="select"
                                                                                        name={`invoiceData[${index}].currency`}
                                                                                        label="Select Currency"
                                                                                        // requiredField
                                                                                        options={[
                                                                                            { key: 'USD', value: 'USD' },
                                                                                            { key: 'EURO', value: 'EURO' },
                                                                                            { key: 'CHF', value: 'CHF' },
                                                                                            { key: 'GBP', value: 'GBP' },
                                                                                        ]}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-lg-4 col-md-6">
                                                                                    <FormikControl
                                                                                        control="textfield"
                                                                                        name={`invoiceData[${index}].invoiceAmount`}
                                                                                        label="Invoice Amount"
                                                                                        placeholder="Enter Invoice Amount"
                                                                                    // requiredField
                                                                                    />
                                                                                </div>
                                                                                <div className="col-lg-4 col-md-6">
                                                                                    <FormikControl
                                                                                        control="datepicker"
                                                                                        name={`invoiceData[${index}].issueDate`}
                                                                                        label="Issue Date"
                                                                                    // requiredField
                                                                                    />
                                                                                </div>
                                                                                <div className="col-lg-4 col-md-6">
                                                                                    <FormikControl
                                                                                        control="datepicker"
                                                                                        name={`invoiceData[${index}].dueDate`}
                                                                                        label="Due Date"
                                                                                    // requiredField
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>



                                                                        {/* <Grid container spacing={2} className='pt-3'>
                                                                                <Grid item xs={12} sm={6} md={4}>
                                                                                    <FormikControl
                                                                                        control="textfield"
                                                                                        name={`invoiceData[${index}].invoiceNumber`}
                                                                                        label="Invoice Number"
                                                                                        placeholder="Enter Invoice Number"
                                                                                    // requiredField
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={6} md={4}>
                                                                                    <FormikControl
                                                                                        control="uploadImage"
                                                                                        name={`invoiceData[${index}].invoice`}
                                                                                        label="Upload Invoice"
                                                                                    // requiredField
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={6} md={4}>
                                                                                    <FormikControl
                                                                                        control="select"
                                                                                        name={`invoiceData[${index}].currency`}
                                                                                        label="Select Currency"
                                                                                        // requiredField
                                                                                        options={[
                                                                                            { key: 'USD', value: 'USD' },
                                                                                            { key: 'EURO', value: 'EURO' },
                                                                                            { key: 'CHF', value: 'CHF' },
                                                                                            { key: 'GBP', value: 'GBP' },
                                                                                        ]}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={6} md={4}>
                                                                                    <FormikControl
                                                                                        control="textfield"
                                                                                        name={`invoiceData[${index}].invoiceAmount`}
                                                                                        label="Invoice Amount"
                                                                                        placeholder="Enter Invoice Amount"
                                                                                    // requiredField
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={6} md={4}>
                                                                                    <FormikControl
                                                                                        control="datepicker"
                                                                                        name={`invoiceData[${index}].issueDate`}
                                                                                        label="Issue Date"
                                                                                    // requiredField
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={6} md={4}>
                                                                                    <FormikControl
                                                                                        control="datepicker"
                                                                                        name={`invoiceData[${index}].dueDate`}
                                                                                        label="Due Date"
                                                                                    // requiredField
                                                                                    />
                                                                                </Grid>
                                                                            </Grid> */}
                                                                    </Card>
                                                                </React.Fragment>
                                                            ))}
                                                        </>
                                                    )}
                                                </FieldArray>





                                                {/* CrrencyData Sections */}

                                                <FieldArray name="currencyData">
                                                    {({ push, remove }) => (
                                                        <>
                                                            {values.currencyData.map((section, index) => (
                                                                <Grid container spacing={2} key={index}>
                                                                    <div className="company-profile-sec three container">
                                                                        <div className="row p-4">
                                                                            <div className="col-lg-4 col-md-6">
                                                                                <FormikControl
                                                                                    control="select"
                                                                                    name={`currencyData[${index}].currency`}
                                                                                    label="Select Currency"
                                                                                    // requiredField
                                                                                    options={[
                                                                                        { key: 'USD', value: 'USD' },
                                                                                        { key: 'EURO', value: 'EURO' },
                                                                                        { key: 'CHF', value: 'CHF' },
                                                                                        { key: 'GBP', value: 'GBP' }
                                                                                    ]}
                                                                                />
                                                                            </div>
                                                                            <div className="col-lg-4 col-md-6">
                                                                                <FormikControl
                                                                                    control="textfield"
                                                                                    name={`currencyData[${index}].unpaidAmount`}
                                                                                    label="Total Amount Unpaid"
                                                                                    placeholder="Enter Total Amount Unpaid"
                                                                                // requiredField
                                                                                />
                                                                            </div>
                                                                            {index === 0 && (
                                                                                <div className="col-lg-4 col-md-6 d-flex align-items-center">
                                                                                    <Button
                                                                                        type="button"
                                                                                        variant="contained"
                                                                                        color="primary"
                                                                                        onClick={() => push({
                                                                                            currency: "",
                                                                                            unpaidAmount: ""
                                                                                        })}
                                                                                        startIcon={<Add />}
                                                                                        className="add-moref-button"
                                                                                    >
                                                                                        Add More
                                                                                    </Button>
                                                                                </div>
                                                                            )}

                                                                        </div>
                                                                    </div>
                                                                </Grid>
                                                                // <Grid item xs={12} lg={4} md={6}>
                                                                //     <FormikControl
                                                                //         control="select"
                                                                //         name={`currencyData[${index}].currency`}
                                                                //         label="Select Currency"
                                                                //         // requiredField
                                                                //         options={[
                                                                //             { key: 'USD', value: 'USD' },
                                                                //             { key: 'EURO', value: 'EURO' },
                                                                //             { key: 'CHF', value: 'CHF' },
                                                                //             { key: 'GBP', value: 'GBP' }
                                                                //         ]}
                                                                //     />
                                                                // </Grid>

                                                                // <Grid item xs={12} lg={4} md={6}>
                                                                //     <FormikControl
                                                                //         control="textfield"
                                                                //         name={`currencyData[${index}].unpaidAmount`}
                                                                //         label="Total Amount Unpaid"
                                                                //         placeholder="Enter Total Amount Unpaid"
                                                                //     // requiredField
                                                                //     />
                                                                // </Grid>

                                                                // {index === 0 && (

                                                                //     <Grid item xs={12} lg={4} md={4}>
                                                                //         <Grid item container display="flex" >
                                                                //             <Button
                                                                //                 type="button"
                                                                //                 variant="contained"
                                                                //                 color="primary"
                                                                //                 onClick={() => push({
                                                                //                     currency: "",
                                                                //                     unpaidAmount: ""
                                                                //                 })}
                                                                //                 startIcon={<Add />}
                                                                //                 className="add-moref-button"
                                                                //             >
                                                                //                 Add More
                                                                //             </Button>
                                                                //         </Grid>
                                                                //     </Grid>
                                                                // )}


                                                            ))}

                                                        </>
                                                    )}
                                                </FieldArray>





                                                <Typography className="typo-1">A claimant must disclose to 1wn all other networks to which their company or any of its enrolled branches hold membership, the claimant will have to provide the full members list of which the company and their branches are enrolled. If the claimant does not provide this information, or if 1wn determines the information provided is incorrect or incomplete, the claim will be rejected.</Typography>

                                                {/* NetworkData Section */}
                                                <Grid container>
                                                    <FieldArray name="networkData">
                                                        {({ push, remove }) => (
                                                            <>
                                                                {values.networkData.map((network, index) => (

                                                                    <Grid container spacing={2} key={index}>
                                                                        <div className="company-profile-sec container pt-2 four">
                                                                            <div className="row p-4">
                                                                                <div className="col-lg-3 col-md-6">
                                                                                    <FormikControl
                                                                                        control="textfield"
                                                                                        name={`networkData[${index}].networkMembership`}
                                                                                        label="Other Network Membership"
                                                                                        placeholder="Enter Other Network Membership"
                                                                                    // requiredField
                                                                                    />
                                                                                </div>
                                                                                <div className="col-lg-3 col-md-6">
                                                                                    <FormikControl
                                                                                        control="textfield"
                                                                                        name={`networkData[${index}].contactPerson`}
                                                                                        label="Contact Person"
                                                                                        placeholder="Enter Contact Person"
                                                                                    // requiredField
                                                                                    />
                                                                                </div>
                                                                                <div className="col-lg-3 col-md-6">
                                                                                    <FormikControl
                                                                                        control="textfield"
                                                                                        name={`networkData[${index}].personEmail`}
                                                                                        label="Contact Person's Email"
                                                                                        placeholder="Enter Contact Person's Email"
                                                                                    // requiredField
                                                                                    />
                                                                                </div>
                                                                                {index === 0 && (
                                                                                    <div className="col-lg-3 col-md-6 d-flex align-items-center">
                                                                                        <Button
                                                                                            type="button"
                                                                                            variant="contained"
                                                                                            color="primary"
                                                                                            onClick={() => push({

                                                                                                networkMembership: "",
                                                                                                contactPerson: "",
                                                                                                personEmai: ""
                                                                                            })}
                                                                                            startIcon={<Add />}
                                                                                            className="add-more-button"
                                                                                        >
                                                                                            Add Network
                                                                                        </Button>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        {/* <Grid item xs={12} lg={3} md={4}>
                                                                                <FormikControl
                                                                                    control="textfield"
                                                                                    name={`networkData[${index}].networkMembership`}
                                                                                    label="Other Network Membership"
                                                                                    placeholder="Enter Other Network Membership"
                                                                                // requiredField
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} lg={3} md={4}>
                                                                                <FormikControl
                                                                                    control="textfield"
                                                                                    name={`networkData[${index}].contactPerson`}
                                                                                    label="Contact Person"
                                                                                    placeholder="Enter Contact Person"
                                                                                // requiredField
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} lg={3} md={4}>
                                                                                <FormikControl
                                                                                    control="textfield"
                                                                                    name={`networkData[${index}].personEmail`}
                                                                                    label="Contact Person's Email"
                                                                                    placeholder="Enter Contact Person's Email"
                                                                                // requiredField
                                                                                />
                                                                            </Grid>
                                                                            {index === 0 && (
                                                                                <Grid item xs={12} lg={3} md={4}>
                                                                                    <Button
                                                                                        type="button"
                                                                                        variant="contained"
                                                                                        color="primary"
                                                                                        onClick={() => push({

                                                                                            networkMembership: "",
                                                                                            contactPerson: "",
                                                                                            personEmai: ""
                                                                                        })}
                                                                                        startIcon={<Add />}
                                                                                        className="add-more-button"
                                                                                    >
                                                                                        Add Network
                                                                                    </Button>
                                                                                </Grid>
                                                                            )} */}
                                                                    </Grid>
                                                                ))}


                                                            </>
                                                        )}
                                                    </FieldArray>
                                                </Grid>


                                                {/* Upload Sections */}


                                                <Grid container >
                                                    <FieldArray name="communication">
                                                        {({ push }) => (
                                                            <>
                                                                {values.communication.map((file, index) => (
                                                                    <Grid container item xs={12} spacing={2} key={index}>
                                                                        <div className="company-profile-sec container five">
                                                                            <div className="row p-4">
                                                                                <div className="col-lg-4 col-md-6">
                                                                                    <FormikControl
                                                                                        control="uploadImage"
                                                                                        name={`communication[${index}].file`}
                                                                                        label="Upload Communication"

                                                                                    />
                                                                                </div>
                                                                                {index === 0 && (
                                                                                    <div className="col-lg-4 col-md-6 d-flex align-items-center">
                                                                                        <Button
                                                                                            type="button"
                                                                                            variant="contained"
                                                                                            color="primary"
                                                                                            onClick={() => push({ file: null })}
                                                                                            fullWidth
                                                                                            className="add-mores-button"
                                                                                        >
                                                                                            + Add More
                                                                                        </Button>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        {/* <Grid item xs={12} md={6} lg={6}>
                                                                                <FormikControl
                                                                                    control="uploadImage"
                                                                                    name={`communication[${index}].file`}
                                                                                    label="Upload Communication"

                                                                                />
                                                                            </Grid>
                                                                            {index === 0 && (
                                                                                <Grid container item xs={12} md={6} lg={6}  >
                                                                                    <Grid item display="flex" alignItems="center">
                                                                                        <Button
                                                                                            type="button"
                                                                                            variant="contained"
                                                                                            color="primary"
                                                                                            onClick={() => push({ file: null })}
                                                                                            fullWidth
                                                                                            className="add-mores-button"
                                                                                        >
                                                                                            + Add More
                                                                                        </Button>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            )} */}
                                                                    </Grid>
                                                                ))}
                                                            </>
                                                        )}
                                                    </FieldArray>
                                                </Grid>


                                                <Grid container >
                                                    <FieldArray name="accountStatement">
                                                        {({ push }) => (
                                                            <>
                                                                {values.accountStatement.map((file, index) => (
                                                                    <Grid container item xs={12} spacing={2} key={index}>
                                                                        <div className="company-profile-sec container six">
                                                                            <div className="row p-4">
                                                                                <div className="col-lg-4 col-md-6">
                                                                                    <FormikControl
                                                                                        control="uploadImage"
                                                                                        name={`accountStatement[${index}].file`}
                                                                                        label="Upload Statement Of Account"
                                                                                    // requiredField
                                                                                    />
                                                                                </div>
                                                                                {index === 0 && (
                                                                                    <div className="col-lg-4 col-md-6 d-flex align-items-center">
                                                                                        <Button
                                                                                            type="button"
                                                                                            variant="contained"
                                                                                            color="primary"
                                                                                            className="add-mores-button"
                                                                                            onClick={() => push({ file: null })}
                                                                                            fullWidth
                                                                                        >
                                                                                            + Add More
                                                                                        </Button>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        {/* <Grid item xs={12} md={6} lg={6}>
                                                                                <FormikControl
                                                                                    control="uploadImage"
                                                                                    name={`accountStatement[${index}].file`}
                                                                                    label="Upload Statement Of Account"
                                                                                // requiredField
                                                                                />
                                                                            </Grid>

                                                                            {index === 0 && (
                                                                                <Grid container item xs={12} md={6} lg={6}  >
                                                                                    <Grid item display="flex" alignItems="center">
                                                                                        <Button
                                                                                            type="button"
                                                                                            variant="contained"
                                                                                            color="primary"
                                                                                            className="add-mores-button"
                                                                                            onClick={() => push({ file: null })}
                                                                                            fullWidth
                                                                                        >
                                                                                            + Add More
                                                                                        </Button>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            )} */}
                                                                    </Grid>
                                                                ))}
                                                            </>
                                                        )}
                                                    </FieldArray>
                                                </Grid>



                                                <Grid container >
                                                    <FieldArray name="shippingDocument">
                                                        {({ push }) => (
                                                            <>
                                                                {values.shippingDocument.map((file, index) => (
                                                                    <Grid container item xs={12} spacing={2} key={index}>
                                                                        <div className="company-profile-sec container seven">
                                                                            <div className="row p-4">
                                                                                <div className="col-lg-4 col-md-6">
                                                                                    <FormikControl
                                                                                        control="uploadImage"
                                                                                        name={`shippingDocument[${index}].file`}
                                                                                        label="Upload Shipping Documents"

                                                                                    />
                                                                                </div>
                                                                                {index === 0 && (
                                                                                    <div className="col-lg-4 col-md-6 d-flex align-items-center">
                                                                                        <Button
                                                                                            type="button"
                                                                                            variant="contained"
                                                                                            color="primary"
                                                                                            className="add-mores-button"
                                                                                            onClick={() => push({ file: null })}
                                                                                            fullWidth
                                                                                        >
                                                                                            + Add More
                                                                                        </Button>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        {/* <Grid item xs={12} md={6} lg={6}>
                                                                                <FormikControl
                                                                                    control="uploadImage"
                                                                                    name={`shippingDocument[${index}].file`}
                                                                                    label="Upload Shipping Documents"

                                                                                />
                                                                            </Grid>
                                                                            {index === 0 && (
                                                                                <Grid container item xs={12} md={6} lg={6} spacing={0} >
                                                                                    <Grid item display="flex" alignItems="center">
                                                                                        <Button
                                                                                            type="button"
                                                                                            variant="contained"
                                                                                            color="primary"
                                                                                            className="add-mores-button"
                                                                                            onClick={() => push({ file: null })}
                                                                                            fullWidth
                                                                                        >
                                                                                            + Add More
                                                                                        </Button>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            )} */}
                                                                    </Grid>
                                                                ))}
                                                            </>
                                                        )}
                                                    </FieldArray>
                                                </Grid>


                                                <Grid container >
                                                    <FieldArray name="deliveryReceipt">
                                                        {({ push }) => (
                                                            <>
                                                                {values.deliveryReceipt.map((file, index) => (
                                                                    <Grid container item xs={12} spacing={2} key={index}>
                                                                        <div className="company-profile-sec container eight">
                                                                            <div className="row p-4">
                                                                                <div className="col-lg-4 col-md-6">
                                                                                    <FormikControl
                                                                                        control="uploadImage"
                                                                                        name={`deliveryReceipt[${index}].file`}
                                                                                        label="Upload Delivery Receipt"

                                                                                    />
                                                                                </div>
                                                                                {index === 0 && (
                                                                                    <div className="col-lg-4 col-md-6 d-flex align-items-center">
                                                                                        <Button
                                                                                            type="button"
                                                                                            variant="contained"
                                                                                            color="primary"
                                                                                            className="add-mores-button"
                                                                                            onClick={() => push({ file: null })}
                                                                                            fullWidth
                                                                                        >
                                                                                            + Add More
                                                                                        </Button>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        {/* <Grid item xs={12} md={6} lg={6}>
                                                                                <FormikControl
                                                                                    control="uploadImage"
                                                                                    name={`deliveryReceipt[${index}].file`}
                                                                                    label="Upload Delivery Receipt"

                                                                                />
                                                                            </Grid>
                                                                            {index === 0 && (
                                                                                <Grid container item xs={12} md={6} lg={6} spacing={0} >
                                                                                    <Grid item display="flex" alignItems="center">
                                                                                        <Button
                                                                                            type="button"
                                                                                            variant="contained"
                                                                                            color="primary"
                                                                                            className="add-mores-button"
                                                                                            onClick={() => push({ file: null })}
                                                                                            fullWidth
                                                                                        >
                                                                                            + Add More
                                                                                        </Button>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            )} */}
                                                                    </Grid>
                                                                ))}
                                                            </>
                                                        )}
                                                    </FieldArray>
                                                </Grid>


                                                <Grid container >
                                                    <FieldArray name="addentialRevelentDocoument">
                                                        {({ push }) => (
                                                            <>
                                                                {values.addentialRevelentDocoument.map((file, index) => (
                                                                    <Grid container item xs={12} spacing={2} key={index}>
                                                                        <div className="company-profile-sec container nine">
                                                                            <div className="row p-4">
                                                                                <div className="col-lg-4 col-md-6">
                                                                                    <FormikControl
                                                                                        control="uploadImage"
                                                                                        name={`addentialRevelentDocoument[${index}].file`}
                                                                                        label="Upload Additional Documentation"

                                                                                    />
                                                                                </div>
                                                                                {index === 0 && (
                                                                                    <div className="col-lg-4 col-md-6 d-flex align-items-center">
                                                                                        <Button
                                                                                            type="button"
                                                                                            variant="contained"
                                                                                            color="primary"
                                                                                            className="add-mores-button"
                                                                                            onClick={() => push({ file: null })}
                                                                                            fullWidth
                                                                                        >
                                                                                            + Add More
                                                                                        </Button>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>

                                                                        {/* <Grid item xs={12} md={6} lg={6}>
                                                                                <FormikControl
                                                                                    control="uploadImage"
                                                                                    name={`addentialRevelentDocoument[${index}].file`}
                                                                                    label="Upload Additional Documentation"

                                                                                />
                                                                            </Grid>
                                                                            {index === 0 && (
                                                                                <Grid container item xs={12} md={6} lg={6} spacing={0} >
                                                                                    <Grid item display="flex" alignItems="center">
                                                                                        <Button
                                                                                            type="button"
                                                                                            variant="contained"
                                                                                            color="primary"
                                                                                            className="add-mores-button"
                                                                                            onClick={() => push({ file: null })}
                                                                                            fullWidth
                                                                                        >
                                                                                            + Add More
                                                                                        </Button>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            )} */}
                                                                    </Grid>
                                                                ))}
                                                            </>
                                                        )}
                                                    </FieldArray>
                                                </Grid>


                                                <div className="company-profile-sec container eleven">
                                                    <div className="row px-4">
                                                        <div className="col-lg-4 col-md-6">
                                                            <FormikControl
                                                                control="uploadImage"
                                                                name="claimForm"
                                                                label="Protection Claim Form"
                                                            // requiredField
                                                            />
                                                        </div>
                                                        <div className="col-lg-4 col-md-6">
                                                            <FormikControl
                                                                control="datepicker"
                                                                name="lastDate"
                                                                label="Last Contact Date"
                                                            // requiredField
                                                            />
                                                        </div>
                                                        <div className="col-lg-4 col-md-6">
                                                            <FormikControl
                                                                control="textfield"
                                                                name="formContact"
                                                                label="Last Contact Date Description"
                                                                placeholder="Email,phone"

                                                            />
                                                        </div>
                                                        <div className="col-lg-6 col-md-6">
                                                            <FormikControl
                                                                control="radiogroup"
                                                                name="protectionSystem"
                                                                label="Has the invoice been uploaded to the 1wn Financial protection System?"
                                                                options={[
                                                                    { key: 'Yes', value: 'Yes' },
                                                                    { key: 'No', value: 'No' }
                                                                ]}
                                                            />
                                                        </div>
                                                        <div className="col-lg-6 col-md-6">
                                                            <FormikControl
                                                                control="textfield"
                                                                label="Additional Notes"
                                                                type="text"

                                                                // requiredField
                                                                multiline
                                                                rows={6}
                                                                fullWidth

                                                                name="additionalNotes"
                                                                placeholder="Additional Notes"
                                                            />
                                                        </div>
                                                        <div className="col-12">
                                                            <Typography className="typo-1">claims to 1wn Logistics Networks must be filled within 150 days of the date of the unpaid invoice(s) and during the coverage period. No claims will be accepted for invoice(s) dated LESS than 90 days old, or more than 150 days from the date of the invoice(s).</Typography>
                                                            <Typography className="typo-1">please read and understand the below 1wn Logistics Networks reserves the right to terminate membership of the party against whom the claim is paid and notify all other members of the action</Typography>
                                                        </div>
                                                        <div className="col-12">
                                                            <FormikControl
                                                                control="ckeckbox"
                                                                name="formDeclare"
                                                                label="I hereby certify that the above information given is true and correct as to the best of my knowledge."
                                                            />
                                                        </div>
                                                        <div className="col-12">
                                                            <FormikControl
                                                                control="ckeckbox"
                                                                name="termAndCondition"
                                                                label="I agree to the terms of the 1wn Logistics Networks Protection Plan"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <Grid container spacing={2}>
                                                        <Grid item xs={12} lg={6} md={6}>
                                                            <FormikControl
                                                                control="uploadImage"
                                                                name="claimForm"
                                                                label="Protection Claim Form"
                                                            // requiredField
                                                            />
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} lg={6} md={6}>
                                                            <FormikControl
                                                                control="datepicker"
                                                                name="lastDate"
                                                                label="Last Contact Date"
                                                            // requiredField
                                                            />
                                                        </Grid>
                                                    </Grid>


                                                    <Grid container className='last-date ' spacing={2}>
                                                        <Grid item xs={12} lg={6} md={6}>
                                                            <FormikControl
                                                                control="textfield"
                                                                name="formContact"
                                                                label="Last Contact Date Description"
                                                                placeholder="Email,phone"

                                                            />
                                                        </Grid>
                                                    </Grid>


                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} lg={6} md={6}>
                                                            <FormikControl
                                                                control="radiogroup"
                                                                name="protectionSystem"
                                                                label="Has the invoice been uploaded to the 1wn Financial protection System?"
                                                                options={[
                                                                    { key: 'Yes', value: 'Yes' },
                                                                    { key: 'No', value: 'No' }
                                                                ]}
                                                            />
                                                        </Grid>
                                                    </Grid>


                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} lg={6} md={6} >
                                                            <FormikControl
                                                                control="textfield"
                                                                label="Additional Notes"
                                                                type="text"

                                                                // requiredField
                                                                multiline
                                                                rows={6}
                                                                fullWidth

                                                                name="additionalNotes"
                                                                placeholder="Additional Notes"
                                                            />
                                                        </Grid>
                                                    </Grid>


                                                    <Typography className="typo-1">claims to 1wn Logistics Networks must be filled within 150 days of the date of the unpaid invoice(s) and during the coverage period. No claims will be accepted for invoice(s) dated LESS than 90 days old, or more than 150 days from the date of the invoice(s).</Typography>
                                                    <Typography className="typo-1">please read and understand the below 1wn Logistics Networks reserves the right to terminate membership of the party against whom the claim is paid and notify all other members of the action</Typography>


                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} lg={12} md={12} >
                                                            <FormikControl
                                                                control="ckeckbox"
                                                                name="formDeclare"
                                                                label="I hereby certify that the above information given is true and correct as to the best of my knowledge."
                                                            />
                                                        </Grid>
                                                    </Grid>


                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} lg={12} md={12}>
                                                            <FormikControl
                                                                control="ckeckbox"
                                                                name="termAndCondition"
                                                                label="I agree to the terms of the 1wn Logistics Networks Protection Plan"
                                                            />
                                                        </Grid>
                                                    </Grid> */}
                                            </Grid>


                                            <CustomButtonGrid
                                                item={
                                                    <FormikControl
                                                        control="button"
                                                        color="primary"
                                                        label="Submit"
                                                        type="submit"
                                                        className="submit-button"
                                                        disabled={!dirty || !isValid}
                                                    />
                                                }
                                            />
                                        </Form>
                                    )}
                                </Formik>
                            </Card>
                        </Box>
                    </div>
                </div>
            </div>
        </>
    )
}
export default GrievenceRedressal