import { RouteType } from "constants/RouteType";
import { BlogModel } from "model/screensModels/BlogModel";
import { PaginatorModel } from "model/utilModels/PaginatorModel";
import { AuthService } from "services/auth/AuthService";

export class BlogService {

  //To get blog list
  static getBlogList = async (requestBody: PaginatorModel) => {
    const url = RouteType.ADMIN + RouteType.BLOG;
    const response = AuthService.postCryptoRequest(url, JSON.stringify(requestBody));
    return response;
  };

   //save Blog
   static addBlog = async (requestBody: BlogModel) => {
    const url = RouteType.ADMIN + RouteType.BLOG + RouteType.STORE;
    const response = AuthService.postCryptoRequest(url, JSON.stringify(requestBody));
    return response;
  };

  //delete Blog
  static deleteBlog = async (requestBody: BlogModel) => {
    const url = RouteType.ADMIN + RouteType.BLOG + RouteType.DELETE;
    const response = AuthService.postCryptoRequest(url, JSON.stringify(requestBody));
    return response;
  };
}