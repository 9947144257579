export class CompanyServicesModel {
  companyServiceId: string;
  image: string;
  imageFile: any
  companyName:string;
  companyId:string
  title: string;
  description: string;

  constructor() {
    this.companyServiceId="";
    this.image = "";
    this.imageFile= null;
    this.companyName="";
    this.companyId="";
    this.title = "";
    this.description = "";
  }
}
