import { Box, Typography } from "@mui/material";
import "./Footer.scss"
import { useEffect, useState } from "react";

const Footer = () => {
    
    const [currentYear, setCurrentYear] = useState(0);

    useEffect(()=> {
        setCurrentYear(new Date().getFullYear());
    },[]);

    return (
        // <Box bgcolor={"goldenrod"} >
        // <Box sx={{ position: 'fixed', background:'goldenrod', bottom: 0, width: '100%', zIndex: 3 }}>
        <Box sx={{ background: '#ffffff', width: '100%', marginTop: '1.85vh' }}>
            <Typography margin={'12px'}>©{ currentYear } All rights reserved.</Typography>
        </Box>
        // </Box>
    )
}

export default Footer;