import { RouteType } from "constants/RouteType";
import { InvoiceManagementModel } from "model/screensModels/InvoiceManagementModel";
import { PaginatorModel } from "model/utilModels/PaginatorModel";
import { AuthService } from "services/auth/AuthService";

export class InvoiceManagementService {
  // constructor() { }

  static getInvoiceList = async (requestBody: InvoiceManagementModel) => {
    const url = RouteType.ADMIN + RouteType.INVOICE_MANAGEMENT;
    const response = AuthService.postCryptoRequest(url, JSON.stringify(requestBody));
    return response;
  };


  

   static addInvoice = async (requestBody: InvoiceManagementModel) => {
    const url = RouteType.ADMIN + RouteType.INVOICE_MANAGEMENT + RouteType.STORE;
    const response = AuthService.postCryptoRequest(url, JSON.stringify(requestBody));
    return response;
  };

  static deleteInvoice = async (requestBody: InvoiceManagementModel) => {
    const url = RouteType.ADMIN + RouteType.INVOICE_MANAGEMENT + RouteType.DELETE;
    const response = AuthService.postCryptoRequest(url, JSON.stringify(requestBody));
    return response;
  };

  static invoiceManagementstatus = async (requestBody: InvoiceManagementModel) => {
    const url = RouteType.ADMIN + RouteType.INVOICE_MANAGEMENT_STATUS_COUNT;
    const response = AuthService.postCryptoRequest(url, JSON.stringify(requestBody));
    return response;
  };

}