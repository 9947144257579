import { PaginatorModel } from "model/utilModels/PaginatorModel";

export class InvoiceManagementModel extends PaginatorModel {
  invoiceId: string;
  fromCompanyId: string;
  toCompanyId: string;
  subject: string;
  uploadedInvoiceFile: any;
  uploadedInvoice: string;
  status: string;

  constructor() {
    super();
    this.invoiceId = "";
    this.fromCompanyId = "";
    this.toCompanyId="";
    this.status="";
    this.subject = "";
    this.uploadedInvoiceFile = null;
    this.uploadedInvoice = "";
  }
}