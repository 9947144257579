import { RouteType } from "constants/RouteType";
import { AuthService } from "services/auth/AuthService";

export class MemberSearchService {
  // constructor() { }

  //To get member list
  static memberSearch = async (requestBody: any) => {
    const url = RouteType.ADMIN + RouteType.MEMBER_SEARCH;
    const response = AuthService.postCryptoRequest(url, JSON.stringify(requestBody));
    return response;
  };

}