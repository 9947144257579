import { RouteType } from "constants/RouteType";
import { NewsAnnouncementsModel } from "model/screensModels/NewsAnnouncementsModel";
import { AuthService } from "services/auth/AuthService";

export class NewsAnnouncementsService {
  // constructor() { }


  // Save News Announcements

  static addNewsAnnouncements = async (requestBody: NewsAnnouncementsModel) => {
    const url = RouteType.ADMIN + RouteType.NEWS_ANNOUNCEMENTS + RouteType.STORE;
    const response = AuthService.postCryptoRequest(url, JSON.stringify(requestBody));
    return response;
  }
}