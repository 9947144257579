import { Box, Grid, Stack } from "@mui/material";

const CustomButtonGrid = props => {
    return (
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box ml={1} mr={1} mt={2.5} sx={{ display: 'flex', justifyContent: 'flex-end' }} >
                <Stack spacing={1} direction={"row"}>
                    {props.item}
                </Stack>
            </Box>
        </Grid>
    )
}
export default CustomButtonGrid;