import { useState, useEffect } from 'react';
import { ResponseModel } from 'model/utilModels/ResponseModel';
import { PaginatorModel } from 'model/utilModels/PaginatorModel';
import { conformationAlert } from 'services/shared/alert';
import { msgTypes } from 'constants/msgTypes';
import Button from '@mui/material/Button';
import AddTaskIcon from '@mui/icons-material/AddTask';
import WrapTextIcon from '@mui/icons-material/WrapText';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import MyInvoice from './invoiceList/MyInvoice';
import { InvoiceManagementModel } from 'model/screensModels/InvoiceManagementModel';
import { InvoiceManagementService } from 'services/menu/invoiceManagementService/InvoiceManagementService';
import OwedInvoice from './invoiceList/OwedInvoice';

interface InvoiceListProps {
  setFlag: (flag: boolean) => void;
  updateRecord: (record: InvoiceManagementModel) => void;
}


interface TabPanelProps {
  children?: React.ReactNode;
  value: string;
  index: string;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};


const InvoiceList: React.FC<InvoiceListProps> = ({ setFlag, updateRecord }) => {
  const [value, setValue] = useState<string>('0');
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };


  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="invoice tabs"
        >
          <Tab sx={{ fontWeight: 'bold' }} value="0" label="Owed Invoice" />
          <Tab sx={{ fontWeight: 'bold' }} value="1" label="My Invoice" />
        </Tabs>
      </Box>

      <TabPanel value={value} index="0">
        <OwedInvoice
          setFlag={setFlag}
          updateRecord={updateRecord}
        />
      </TabPanel>

      <TabPanel value={value} index="1">
        <MyInvoice
          setFlag={setFlag}
          updateRecord={updateRecord}
        />
      </TabPanel>
    </>
  );
};

export default InvoiceList;
