import { msgTypes } from "constants/msgTypes";
import { Validation } from "constants/Validation";
import * as Yup from "yup";

export const CompanyServicesSchema = Yup.object({
  companyServiceId: Yup.string(),
  companyId: Yup.string().required(Validation.required.message.replaceAll("^", "Company Name"))
  .matches(Validation.plaintext.regex, Validation.plaintext.message),
  image: Yup.string().required(Validation.required.message.replaceAll("^", "Image")),
  imageFile: Yup.mixed().nullable()
    .test(
      "FILE_FORMAT",
      "Uploaded file has unsupported format",
      (value) => !value || (value && msgTypes.SUPPORTED_IMAGE_FORMAT.includes(value?.type))
    )
    .test(
      "FILE_SIZE",
      "Uploaded file is too big",
      (value) => !value || (value && value.size <= 1024 * 1024)
    ),
  title: Yup.string().required(Validation.required.message.replaceAll("^", "Company Service Title"))
    .matches(Validation.plaintext.regex, Validation.plaintext.message),
  description: Yup.string().required(Validation.required.message.replaceAll("^", "Company Service Description"))
    .matches(Validation.plaintext.regex, Validation.plaintext.message),
});
