import { msgTypes } from "constants/msgTypes";
import { RouteType } from "constants/RouteType";
import { AuthService } from "services/auth/AuthService";
import { UploadFileService } from "./UploadFileService";
import { MembershipModel } from "model/screensModels/membership/MembershipModel";



export class UtilService {
    // constructor() { }

    //load country
    static getCountryList = async () => {
        const url = RouteType.GET_COUNTRY;
        const response = AuthService.postCryptoRequest(url, msgTypes.EMPTY_STR);
        return response;
    };

    //load State List
    static getStateList = async (countryId: string) => {
        const url = RouteType.GET_STATE;
        const response = AuthService.postCryptoRequest(url, JSON.stringify(countryId));
        return response;
    };

    //load State List
    static getCityList = async (stateId: string) => {
        const url = RouteType.GET_CITY;
        const response = AuthService.postCryptoRequest(url, JSON.stringify(stateId));
        return response;
    };


    //load State List
    static getCompanyList = async () => {
        const url = RouteType.ADMIN + RouteType.COMPANY_LIST;
        const response = AuthService.postCryptoRequest(url, JSON.stringify(msgTypes.EMPTY_STR));
        return response;
    };

    // Load Contact Person By Company Id
    static getContactPersonsByCompanyId = async (companyId: string) => {
        const url = RouteType.ADMIN + RouteType.COMPANY_LIST_BY_ID;
        const data =  {"companyId":companyId};
        const response = AuthService.postCryptoRequest(url, JSON.stringify(data));
        return response;
    }

    static getImagePath = async(image: any) =>{
        if (image) {
          const formData = new FormData();
          formData.append("file", image);
          const imageurlData = await UploadFileService.getImagePath(formData);
          return await imageurlData.data.fileName;
        } else {
          return '';
        }
       }

       static getCompanyDetailsByCompanyId = async (requestData: MembershipModel) => {
        const url = RouteType.ADMIN + RouteType.COMPANY_LIST_BY_ID;
        const response = AuthService.postCryptoRequest(url, JSON.stringify(requestData));
        return response;
    }

    //Begin:: Change case
    static sentenceCase = (str) => {
        if ((str === null) || (str === ''))
            return false;
        else
            str = str.toString();

        return str.replace(/\w\S*/g,
            function (txt) {
                return txt.charAt(0).toUpperCase() +
                    txt.substr(1).toLowerCase();
            });
    }

    static upperCase = (str) => {
        if ((str === null) || (str === ''))
            return false;
        else
            str = str.toString();

        return str.replace(/\w\S*/g,
            function (txt) {
                return txt.toUpperCase();
            });
    }

    static lowerCase = (str) => {
        if ((str === null) || (str === ''))
            return false;
        else
            str = str.toString();

        return str.replace(/\w\S*/g,
            function (txt) {
                return txt.toLowerCase();
            });
    }

    //End:: change Case
}