import { Box, Stack } from "@mui/material";
import Footer from "../footer/Footer";
import { Breadcrumb } from "../breadcrumb/Breadcrumb";
import { Outlet } from "react-router-dom";
import "./ContentPane.scss";
const ContenetPane = () => {
    return (
        <Box marginTop={7.5}>
            <Stack direction={"column"} justifyContent={"space-between"}>
                {/* <Box className="breadcrumb">
                    <Breadcrumb />
                </Box> */}
                <Box className="content-box">
                    <Stack direction={"column"}>
                        <Box minHeight={'77vh'} width={'100%'} paddingInline={'10px'} marginBottom={'13px'}>
                        <Outlet />
                        </Box>
                        <Footer />
                    </Stack>
                </Box>
            </Stack>
        </Box>
    )
}



export default ContenetPane;

