import React from 'react'
import { Navigate } from 'react-router-dom'
import { LocalStorageService } from 'services/auth/LocalStorageService'

export const RequireAuth = ({children}) => {
  //const auth= useAuth();
  const auth = LocalStorageService.getToken();
  if(!auth){
    localStorage.clear();
    return <Navigate to='/login'/>
  }
  return children
}
