import { msgTypes } from 'constants/msgTypes';
import { useEffect, useState } from 'react'
import ErrorIcon from '@mui/icons-material/Error';
import ApiUrl from 'services/shared/ApiUrl';

const ImagePreview = ({ file, errorMessage }) => {
  return (
    <div>
        {errorMessage===undefined ? 
            (<img src={ApiUrl.fileBaseUrl+file} alt='preview' width="80px" height="80px" />) 
            : (<ErrorIcon color="error"  sx={{marginLeft:"42px"}}/>) }  
    </div>
  )
}

export default ImagePreview
