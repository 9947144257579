import { RouteType } from "constants/RouteType";
import { GrievenceRedressalModel } from "model/screensModels/GrievenceRedressalModel";
import { AuthService } from "services/auth/AuthService";

export class GrievenceRedressalService {
  // Save Resolution
  static addGrievanceRedressal = async (requestBody: GrievenceRedressalModel) => {
    const url = RouteType.ADMIN + RouteType.GRIEVANCE_REDRESSAL + RouteType.STORE;
    const response = AuthService.postCryptoRequest(url, JSON.stringify(requestBody));
    return response;
  };




}