import { msgTypes } from "constants/msgTypes";
import { Validation } from "constants/Validation";
import * as Yup from "yup";

export const NewsLettersSchema = Yup.object({
  newsLetterId: Yup.string(),
  title: Yup.string()
    .required(Validation.required.message.replaceAll("^", "Title"))
    .matches(Validation.plaintext.regex, Validation.plaintext.message),

    description: Yup.string().required(Validation.required.message.replaceAll("^","News description"))
    .matches(Validation.plaintext.regex , Validation.plaintext.message),

  image: Yup.string().required(Validation.required.message.replaceAll("^", "Image")),
  imageFile: Yup.mixed().nullable() 
    //.required(Validation.required.message.replaceAll("^", "Image"))
    .test(
      "FILE_FORMAT",
      "Uploaded file has unsupported format",
      (value) =>
        !value || (value && msgTypes.SUPPORTED_IMAGE_FORMAT.includes(value?.type))
    )
    .test(
      "FILE_SIZE",
      "Uploaded file is too big",
      (value) => !value || (value && value.size <= 1024 * 1024)
    ),
});
