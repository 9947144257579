import { RouteType } from "constants/RouteType";
import { EventsModel } from "model/screensModels/EventsModel";
import { PaginatorModel } from "model/utilModels/PaginatorModel";
import { AuthService } from "services/auth/AuthService";

export class EventsService {

  // Save Events
  static addEvents = async (requestBody: EventsModel) => {
    const url = RouteType.ADMIN + RouteType.EVENT + RouteType.STORE;
    const response = await AuthService.postCryptoRequest(url, JSON.stringify(requestBody));
    return response;
  };

   // get Upcoming Event List
   static getUpcomingEventList = async (requestBody: PaginatorModel) => {
    const url = RouteType.ADMIN + RouteType.EVENT + RouteType.UPCOMING_EVENTS ;
    const response = await AuthService.postCryptoRequest(url, JSON.stringify(requestBody));
    return response;
  };

  // get Past Event List
  static getPastEventList = async (requestBody: PaginatorModel) => {
    const url = RouteType.ADMIN + RouteType.EVENT + RouteType.PAST_EVENTS;
    const response = await AuthService.postCryptoRequest(url, JSON.stringify(requestBody));
    return response;
  };




}