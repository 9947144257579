export const msgTypes = {
    LOCALHOST: 'localhost',
    APP_SECRET_KEY:
    {
        KEY: "ACC62A67A3D055E6AF68BD9D3ED9A519",
        IV: "608538a015674f17"
    },

    GOOGLE_CAPTCHA_SITE_KEY_LOCAL: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
    GOOGLE_CAPTCHA_SITE_KEY_PROD: '6LfIXD4qAAAAAAWNjWp8RG4wSmM1x0mm5i8nAxZ5',
    GOOGLE_CAPTCHA_SECRET_KEY_LOCAL: '6LfIXD4qAAAAAMqs2lFdQov6uqsNWWdu45IEdWY6',


    SUCCESS: {
        code: "1000",
        msg: "SUCCESS"
    },


    DOCUMENT_TYPE: {
        JPG: "jpg",
        JPEG: "jpeg",
        PNG: "png",
        PDF: "pdf",
        TXT: "txt",
        XLSX: "xlsx",
        CSV: "csv"
    },

    SUPPORTED_IMAGE_FORMAT:["image/jpg","image/jpeg","image/png"],
    SUPPORTED_ALL_FORMAT:["image/jpg","image/jpeg","image/png","application/pdf", "application/msword"],
    IMAGE_EXTENSIONS:["jpg","jpeg","png"],

    APPLICATION_JSON: "application/json",
    ACCESS_TOKEN: "accessToken",
    USER_DETAILS: "userDetails",

    BASIC_ACCESS_TOKEN: "basicAccessToken",
    STORE_INFO: "userName",

    //status
    //SUCCESS : 'SUCCESS',

    //regex related constant
    RESPONSE_CODE_REGEX: new RegExp("^[1]{1}[0-9]{3}$"),
    RESPONSE_CODE_REGEX_THREE_DIGITS: new RegExp("^[0-1]{1}[0-9]{2}$"),

    // Login Page
    LOGIN_SUCCESS: "Login Successful",
    LOGGED_OUT: "Logout Successful",
    SOMETHING_WENT_WRONG: "Something went wrong",

    //Sweet alert 
    success: "success",
    error: "error",
    OK_KEY: 'OK',

    //Generate Form
    EMPTY_STR: '',

    //Curl strings
    HOST_URL: "HostUrl: ",
    METHOD_TYPE: "MethodType: ",
    REQUEST: "request: ",
    HEADERS_MAP: "HeadersMap: ",

    //Begin:: Table ColumnDef and Headers
    COLUMNDEF_APPROVE: 'approve',
    COLUMNDEF_REJECT: 'reject',
    HEADER_APPROVE: 'Approve',
    HEADER_REJECT: 'Reject',
    HEADER_ACTION: 'Action',

    //End:: Table ColumnDef and Headers
    
    
    VIEW: "view",
    EDIT: "edit",

    //Conformation Alert msg
    CONFORMATION_MESSAGE: {
        UPDATE_RECORD:'Update Record',
        DELETE_RECORD: 'Delete Record',
    },

    CONFORMATION_ICON:{
        WARNING: 'warning',
        ERROR: 'error'
    },


    CAROUSEL_SETTINGS: {
        // dots: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    // dots: true
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    infinite: true,
                    // dots: true
                }
            },
            {
              breakpoint: 992,
              settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                  initialSlide: 1,
                  infinite: true,
                //   dots: true
              }
          },
            {
              breakpoint: 768,
              settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  initialSlide: 1,
                  infinite: true,
                //   dots: true
              }
          }
        ]
    },


    INVOICE_STATUS : {
        PAID: "Paid",
        OUTSTANDING: "Outstanding",
        OVERDUE: "Overdue" 
    },

    MEMBERSHIP_TYPE:{
            EXCLUSIVE: "exclusive",
            FOUNDER: "founder"
            
    }




}