import { Box } from "@mui/material";
import * as React from "react";
import NewsLetterSubscribersList from "./NewsLetterSubscribersList";

export default function NewsLetterSubscribers() {
  return (
    <Box sx={{ width: "100%" }} className="container-subheaders">
      <Box sx={{ borderBottom: 1, borderColor: "#ebedf3" }}>
        <div className="content-subheader">
          <h4 className="mr-3">News Letter Subscribers List</h4>
        </div>
      </Box>
      <div className="sub-content-sec">
        <NewsLetterSubscribersList />
      </div>
    </Box>
  );
}
