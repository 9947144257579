export class PaginatorModel {
    largePageSizeOptions: number[] =[25, 50, 100, 1000];
    pageSizeOptions: number[] = [25, 50, 100];
    pageNumber = 0;
    pageSize = 10;
    totalPageNumber = 0;
    totalListSize = 0;
    filter = "";
    orderBy="";
    desc="";
}
