import { RouteType } from "constants/RouteType";
import { MembershipModel } from "model/screensModels/membership/MembershipModel";
import { PaginatorModel } from "model/utilModels/PaginatorModel";
import { AuthService } from "services/auth/AuthService";

export  class RegisteredMembersService {

  // To Get Registered Members List

  static getregisteredMembersList = async(requestBody: PaginatorModel) => {
    const url = RouteType.ADMIN + RouteType.MEMBER;
    const response = AuthService.postCryptoRequest(url, JSON.stringify(requestBody));
    return response;
  };

  // To Get The Update Of Membership Status

  static updateMembershipStatus = async (requestBody: MembershipModel) => {
    const url = RouteType.ADMIN + RouteType.UPDATE_MEMBER_STATUS;
    const response = AuthService.postCryptoRequest(url, JSON.stringify(requestBody));
    return response;
  } 
}