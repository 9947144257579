
import { Box, Grid, Paper, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import CustomGrid from "components/uiComponents/CustomGrid";
import FormikControl from "components/wrapperComponents/FormikControl";
import CustomButtonGrid from "components/uiComponents/CustomButtonGrid";
import { useEffect, useState } from 'react';
import { AnnouncementModel } from "model/screensModels/AnnouncementModel";
import { AnnouncementService } from "services/menu/announcementService/AnnouncementService";
import { msgTypes } from "constants/msgTypes";
import { AnnouncementSchema } from "schemas/menu/announcement/AnnouncementSchema";


export const CreateAnnouncement = ({ setFlag, updateRecord }) => {

  const [initialValue, setInitialValue] = useState(new AnnouncementModel());
  const [viewMode, setViewMode] = useState(false);
  const onSubmit = async (values: AnnouncementModel) => {
    addAnnouncement(values)
  }

  useEffect(() => {
    if (updateRecord) {
        setInitialValue(updateRecord)
    }
  }, [updateRecord])




    const prepareRequestBody = async (values: AnnouncementModel) => {
      const prepareData = new AnnouncementModel();

      prepareData.title = values.title ? values.title : '';
      prepareData.message = values.message ? values.message : '';
      prepareData.attachment = values.attachment ? values.attachment : "";

      return prepareData;
    }

    const handleSubmit = async (values: AnnouncementModel) => {
    const requestData = await prepareRequestBody(values);
    const res = await AnnouncementService.saveAnnouncement(requestData)
    if (res.statusMsg === msgTypes.SUCCESS.msg) {
      setInitialValue(new AnnouncementModel())
      setFlag(false)
    }
  };


  const addAnnouncement = async (values: AnnouncementModel) => {
    const requestData = await prepareRequestBody(values);
    const res = await AnnouncementService.saveAnnouncement(requestData);
    if (res.statusMsg === msgTypes.SUCCESS.msg) {
      setInitialValue(new AnnouncementModel())
     
    }
  }

  return (
    <>

     <div className="sub-content-sec">
     <Formik
        enableReinitialize={true}
        initialValues={initialValue}
        validationSchema={AnnouncementSchema}
        onSubmit={handleSubmit}
      >
        {({ dirty, isValid, values}) => (
          <Form>
            <Box className="form-box bg-white">
              <Grid container className="form-grid">
                <CustomGrid
                  item={
                    <FormikControl
                      control="textfield"
                      name="title"
                      label="Title"
                      placeholder="Enter Title"
                      requiredField
                      disabled={viewMode}
                    />
                  }
                />

                <CustomGrid
                  item={
                    <FormikControl
                      control="textfield"
                      name="message"
                      label="Message"
                      placeholder="Write Message"
                      requiredField
                      disabled={viewMode}
                    />
                  }
                />

                <CustomGrid
                  item={
                    <FormikControl
                      control="uploadImage"
                      name="attachmentFile"
                      nameUrl="attachment"
                      label="Attachment"
                      requiredField
                      disabled={viewMode}
                    />
                  }
                />

                <CustomButtonGrid
                  item={
                    <>
                    <FormikControl
                      control="button"
                      color="primary"
                      label={
                        values.announcementId === "" ? "Save" : "Update"
                      }
                      type="submit"
                      disabled={!dirty || !isValid}
                    />
                     <FormikControl
                          control="button"
                          className="btn btn-danger"
                          color="warning"
                          label="Back"
                          type="reset"
                          onClick={() => {
                            setFlag(false);
                          }}
                        />
                   </>
                  }
                 
                />
               
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
     </div>
    </>
  )
}
