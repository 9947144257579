import { Box, IconButton, InputAdornment, OutlinedInput } from '@mui/material'
import React from 'react'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { CustomErrorDiv } from './CustomErrorDiv';
import { CustomInputLabel } from './CustomInputLabel';

export const CustomPasswordInput = (props) => {
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event => {
          event.preventDefault();
    });
    const { handleBlur, handleChange} = props.formikValue;
 return (
        <Box>
          <CustomInputLabel propsValue = {props}/>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            autoComplete="off"
            variant="outlined"
            size="small"
            fullWidth
            name = {props.name}
            disabled={props.disabled}
            placeholder={props.placeholder}
            value={props.value}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={(event) => event.target.select()}
            endAdornment={
              <InputAdornment position="end" >               
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  // disabled={false}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
         />
         <CustomErrorDiv propsValue={props} />
        </Box>
  )
}
