
import { Box, InputAdornment, TextField } from "@mui/material";
import TextError from "./TextError";
import MuiLabel from "./MuiLabel";
import { ErrorMessage, Field, useFormikContext } from "formik";
import ImagePreview from "components/uiComponents/ImagePreview";
import React, { useEffect, useRef, useState } from "react";
import { UploadFileService } from "services/shared/UploadFileService";

function MuiUploadImage(props) {

    const { label, name, ...rest } = props;
    const { setFieldValue, values, errors } = useFormikContext();
    const [fileName, setFileName] = useState(null);
    const inputFile = useRef(null);

    const fileChange = (event) => {
        setFieldValue(props.name, event.target.files[0]);
        setFileName(event.target.files[0].name);
    }

    const onChange = async (e) => {
        //to get image url
        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append("file", file);
            const imageurlData = await UploadFileService.getImagePath(formData);
            setFieldValue(props.nameUrl, imageurlData.data.fileName);
        } else {
            setFieldValue(props.nameUrl, "");
        }
    }

    return (

        <Field name={name} {...rest}>
            {({ field }) => {
                const { value, ...restField } = field;
                return (
                    <React.Fragment>
                        <MuiLabel {...props} />
                        <TextField type="file"
                            id={name} {...rest} {...restField}
                            fullWidth
                            ref={inputFile}
                            size='small'
                            accept="image/*"
                            multiple={false}
                            name={name}
                            onChange={(e) => { onChange(e); fileChange(e) }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <Box width={55}> {values?.[props.nameUrl] == '' || values?.[props.nameUrl] == undefined ?
                                            "" : <ImagePreview file={values?.[props.nameUrl]} fileName={fileName} errorMessage={errors?.[props.name]} />}
                                        </Box>
                                    </InputAdornment>
                                ),
                            }}
                            inputProps={{
                                style: {
                                    padding: '5px 6px',
                                    paddingBottom: '12px'
                                }
                            }}
                        >
                        </TextField>

                        <Box className='error-box'>
                            <ErrorMessage name={name} component={TextError} />
                        </Box>
                    </React.Fragment>
                );
            }}

        </Field>

    )
}

export default MuiUploadImage