import NoRecord from "components/sharedComponent/noRecord/NoRecord";
import CustomTableGrid from "components/uiComponents/CustomTableGrid";
import ReactTable from "components/wrapperComponents/ReactTable";
import { msgTypes } from "constants/msgTypes";
import { Form, Formik } from "formik";
import { CompanyServicesModel } from "model/screensModels/CompanyServicesModel";
import { PaginatorModel } from "model/utilModels/PaginatorModel";
import { ResponseModel } from "model/utilModels/ResponseModel";
import React, { useEffect, useMemo, useState } from "react";
import { CompanyServicesService } from "services/menu/companyServicesService/CompanyServicesService";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { conformationAlert } from "services/shared/alert";
import { Box } from "@mui/material";
import ApiUrl from "services/shared/ApiUrl";
import FormikControl from "components/wrapperComponents/FormikControl";

const CompanyServicesList = ({ setFlag, updateRecord }) => {
  const [companyServicesList, setCompanyServicesList] = useState(
    new ResponseModel()
  );
  const paginatorModel: PaginatorModel = new PaginatorModel();
  const [pagination, setPagination] = useState({
    pageSize: paginatorModel.pageSize,
    pageNumber: paginatorModel.pageNumber,
    filter: "",
    orderBy: "",
    desc: "",
  });

  const columns = useMemo(() =>
    [
      {
        header: 'Image',
        Cell: ({ renderedCellValue, row }) => (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <img alt="avatar" height={35} width={35} src={row.original.image ? ApiUrl.fileBaseUrl + row.original.image + "/" : "/image/userimage.jpg"} loading="lazy" />
          </Box>
        ),
        size: 50
      },
      {
        accessorKey: "companyName",
        header: "CompanyName",
        size: 150
      },
      
      {
        accessorKey: "title",
        header: "Title",
        size: 150
      },
      {
        accessorKey: "description",
        header: "Description"

      },
    ], []);


  useEffect(() => {
    getCompanyServicesList();
  }, [pagination.pageNumber, pagination.pageSize, pagination.filter, pagination.orderBy, pagination.desc])

  //Begin:: UseEffect Hooks

  //Prepare request body to get blog list
  const prepareRequest = () => {
    const requestBody = new PaginatorModel();
    requestBody.pageNumber = pagination.pageNumber;
    requestBody.pageSize = pagination.pageSize;
    requestBody.filter = pagination.filter;
    requestBody.orderBy = pagination.orderBy;
    requestBody.desc = pagination.desc;
    return requestBody;
  };

  // Get Company Services List
  const getCompanyServicesList = async () => {
    const requestBody = prepareRequest();
    const res = await CompanyServicesService.getCompanyServicesList(requestBody);

    if (res.statusMsg === msgTypes.SUCCESS.msg) {
      setCompanyServicesList(res);
    }
  };

  //Open Form To edit Record
  const editRecord = (record: CompanyServicesModel) => {
    updateRecord(record);
    setFlag(true);
  };

  const deleteRecord = async (record: CompanyServicesModel) => {
    const requestData: CompanyServicesModel = new CompanyServicesModel();
    requestData.companyServiceId = record.companyServiceId;

    const deleteFlag = await conformationAlert(
      msgTypes.CONFORMATION_MESSAGE.DELETE_RECORD,
      msgTypes.CONFORMATION_ICON.ERROR
    );
    if (deleteFlag) {
      const res = await CompanyServicesService.deleteCompanyServices(
        requestData
      );
      if (res.statusMsg === msgTypes.SUCCESS.msg) {
        getCompanyServicesList(); 

      }
    }
  };

  const initialValues = {};
  const onSubmit = () => {
    getCompanyServicesList();
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {(formik) => (
          <Form>
            <CustomTableGrid
              item={
                <FormikControl
                  control='materialTable'
                  tableColumn={columns}
                  tableData={companyServicesList}
                  formikValue={formik}
                  setPagination={setPagination}
                  actions={[{ type: "icon", icon: <EditIcon />, actionFunc: editRecord },
                  { type: "icon", icon: <DeleteIcon color='error' />, actionFunc: deleteRecord }
                  ]}
                />
              }
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CompanyServicesList;
