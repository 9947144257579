// Step 7 : Sponshership => Set the CSS of switch label

import React, { useEffect } from "react";
import { useState, useRef } from "react";
import "./Events.scss";
import { Formik, Form, FieldArray } from "formik";
import {
  Box,
  Button,
  Grid,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import CustomGrid from "components/uiComponents/CustomGrid";
import FormikControl from "components/wrapperComponents/FormikControl";
import { EventsModel } from "model/screensModels/EventsModel";
import { EventsService } from "services/menu/eventsService/EventsService";
import { EventsSchema } from "schemas/menu/events/EventsSchema";
import { msgTypes } from "constants/msgTypes";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { UtilService } from "services/shared/UtilService";
import EventList from "./EventList";

const Events = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [countryList, setCountryList] = useState([]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const formikRef = useRef(null);
  const [flag, setFlag] = useState(false)

  const [initialValues, setInitialValues] = useState(new EventsModel());

  const steps = [
    "Event Details",
    "Room Details",
    "Table Details",
    "Food Details",
    "Activity Details",
    "Add Option Details",
    "Sponsorship Details",
  ];

  const breackpoint = { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }

  const handleNext = async () => {
    if (formikRef.current) {
      const errors = await formikRef.current.validateForm();
      if (Object.keys(errors).length === 0) {
        setActiveStep((prevStep) => prevStep + 1);
      } else {
        formikRef.current.setTouched(errors);
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };


  useEffect(() => {
    loadCountry();
  }, [])

  //load country
  const loadCountry = async () => {
    const res = await UtilService.getCountryList();
    if (res.statusMsg === msgTypes.SUCCESS.msg) {
      setCountryList(res.data);
    }
  }


  const prepareRequestBody = async (values: EventsModel): Promise<EventsModel> => {
    const prepareData = new EventsModel();

    prepareData.eventName = values.eventName ?? prepareData.eventName;
    prepareData.eventStartDate =
      values.eventStartDate ?? prepareData.eventStartDate;
    prepareData.eventEndDate = values.eventEndDate ?? prepareData.eventEndDate;
    prepareData.eventVenue = values.eventVenue ?? prepareData.eventVenue;
    prepareData.country = values.country ?? prepareData.country;

    // Add room details from values if available
    prepareData.roomOptions = values.roomOptions.map((room) => ({
      roomType: room.roomType ?? "",
      roomPrice: room.roomPrice ?? undefined,
      roomDescription: room.roomDescription ?? "",
      allowMultipleRoomBooking: room.allowMultipleRoomBooking ?? "",
    }));

    // Add table details from values if available
    prepareData.tableOptions = values.tableOptions.map((table) => ({
      tableType: table.tableType ?? "",
      tablePrice: table.tablePrice ?? undefined,
      tableDescription: table.tableDescription ?? "",
      allowMultipleTableBooking: table.allowMultipleTableBooking ?? "",
    }));

    // Add Food details from values if available
    prepareData.foodOptions = values.foodOptions.map((food) => ({
      foodType: food.foodType ?? "",
      foodPrice: food.foodPrice ?? undefined,
      foodDescription: food.foodDescription ?? "",
      allowMultipleFoodBooking: food.allowMultipleFoodBooking ?? "",
    }));

    // Add Activity details from values if available
    prepareData.activityOptions = values.activityOptions.map((activity) => ({
      activityType: activity.activityType ?? "",
      activityPrice: activity.activityPrice ?? undefined,
      activityDescription: activity.activityDescription ?? "",
      activityOptionsAllowed: activity.activityOptionsAllowed ?? "",
    }));

    // prepareData.addNewOptionType =
    //   values.addNewOptionType ?? prepareData.addNewOptionType;

    // Add other options
    prepareData.anotherOptions = values.anotherOptions.map((activity) => ({
      optionType: activity.optionType ?? "",
      optionPrice: activity.optionPrice ?? undefined,
      optionDescription: activity.optionDescription ?? "",
      optionAllowed: activity.optionAllowed ?? "",
    }));

    prepareData.sponsorship = values.sponsorship ?? prepareData.sponsorship;

    return prepareData;
  };

  const addEvents = async (values: EventsModel) => {
    const requestData = await prepareRequestBody(values);
    const res = await EventsService.addEvents(requestData);
    if (res.statusMsg === msgTypes.SUCCESS.msg) {
      setInitialValues(new EventsModel());
      setActiveStep(0);
      return true
    } else {
      return false
    }
  };

  return (
    <>
     {/* To Display Event List */}
    
      {/* <Box margin="0 auto"
        padding="10px">
        <Grid>
          <Paper className="event-paper outer-container">
            <Typography variant="h5" align="left" className="registration-heading py-2 pl-5">
              Events Registration Form
            </Typography>
          </Paper>
        </Grid>
      </Box> */}
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <div className='content-subheader'>
        {!flag ?<h4 className='mr-3'>Events List</h4>:<h4 className='mr-3'>Create Events</h4>}
        {!flag ?
          <button className='btn primary' onClick={()=>{setFlag(true)}}>Add Events</button>
          :""}
        </div>
      </Box>
      {flag ? 
        <div className="sub-content-sec">
        <Box
        className="event-height event-main-box"
        width="100%"
        height="100%"
        margin="0 auto"
        padding="10px"
      >
        <Grid container justifyContent="center">
          <Paper className="event-paper outer-container">
            {/* <Typography variant="h5" align="center" className="pt-3">
              Events Registration Form
            </Typography> */}

            <Formik
              innerRef={formikRef}
              initialValues={initialValues}
              validationSchema={EventsSchema[activeStep]}
              // onSubmit={handleSubmit}
              onSubmit={async (values, { resetForm }) => {
                const res = await addEvents(values);
                if (res)
                  resetForm();
              }}
            >
              {({
                isSubmitting,
                isValid,
                dirty,
                values,
                errors,
                touched,
                handleChange,
              }) => (
                <Form>
                  <Box marginLeft={1.5} marginRight={0.5}>
                    <Grid
                      container
                      spacing={1}
                      bgcolor={"#fff"}
                      paddingTop={4}
                      paddingBottom={2}
                      paddingRight={"5px"}
                    >
                      <Stepper
                        activeStep={activeStep}
                        orientation={isSmallScreen ? "vertical" : "horizontal"}
                        sx={{
                          width: "100%",
                          background: "transparent",
                          marginBottom: "30px",
                        }}
                      >
                        <Step>
                          <StepLabel className="step-label-sec">Event Details</StepLabel>
                        </Step>
                        <Step>
                          <StepLabel className="step-label-sec">Room Options</StepLabel>
                        </Step>
                        <Step>
                          <StepLabel className="step-label-sec">Table Options</StepLabel>
                        </Step>
                        <Step>
                          <StepLabel className="step-label-sec">Food Options</StepLabel>
                        </Step>
                        <Step>
                          <StepLabel className="step-label-sec">Activity Options</StepLabel>
                        </Step>
                        <Step>
                          <StepLabel className="step-label-sec">Add New Option Type +</StepLabel>
                        </Step>
                        <Step>
                          <StepLabel className="step-label-sec">Sponsorship</StepLabel>
                        </Step>
                      </Stepper>

                      {/* Render form fields based on the current step */}

                      {activeStep === 0 && (
                        <>
                          <Grid className="steper-form" container spacing={2}>
                            <CustomGrid
                              item={
                                <FormikControl
                                  control="textfield"
                                  name="eventName"
                                  label="Event Name"
                                  placeholder="Event name"
                                  requiredField
                                  disabled={false}
                                />
                              }
                            />

                            <CustomGrid
                              item={
                                <FormikControl
                                  control="datepicker"
                                  name="eventStartDate"
                                  label="Event Start Date"
                                  placeholder="Start Date"
                                  requiredField
                                  disabled={false}
                                />
                              }
                            />

                            <CustomGrid
                              item={
                                <FormikControl
                                  control="datepicker"
                                  name="eventEndDate"
                                  label="Event End Date"
                                  placeholder="End Date"
                                  requiredField
                                  disabled={false}
                                />
                              }
                            />

                            <CustomGrid
                              item={
                                <FormikControl
                                  control="textfield"
                                  name="eventVenue"
                                  label="Event Venue"
                                  placeholder="Event Venu"
                                  requiredField
                                  disabled={false}
                                />
                              }
                            />

                            <CustomGrid
                              item={
                                <FormikControl control='autocomplete'
                                  label="Country"
                                  name="country"
                                  placeholder="Select Country"
                                  requiredField
                                  options={countryList}
                                  keyfield="name"
                                  valuefield="countryIdentifier"
                                />
                              }
                            />
                          </Grid>

                          <Box marginTop="20px" className="btn-next" display="flex" justifyContent="end">
                            <Button
                              //type="submit"
                              className='btn btn-submit'
                              variant="contained"
                              color="primary"
                              onClick={handleNext}
                              disabled={(!dirty || !(isValid)) || values.eventName.length === 0}
                            >
                              Next
                            </Button>
                          </Box>
                        </>



                      )}

                      {activeStep === 1 && (
                        <>
                          <div className="container d-flex justify-content-between flex-column">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="row">
                                  <div className="col-lg-3 col-md-6 mb-3 error-position">
                                    <FormikControl
                                      control="radiogroup"
                                      name="roomOptionsBtn"
                                      label="Room Options"
                                      requiredField
                                      disabled={false}
                                      options={[
                                        { key: "Yes", value: "Yes" },
                                        { key: "No", value: "No" },
                                      ]}
                                    />
                                  </div>
                                </div>

                                {values.roomOptionsBtn === "Yes" && (
                                  <FieldArray name="roomOptions">
                                    {({ push, remove }) => (
                                      <div>
                                        <div className="row mt-1 mb-3">
                                          <div className="col-lg-6 col-md-6">
                                            <Button
                                              type="button"
                                              variant="contained"
                                              color="primary"
                                              name="roomOptions"
                                              onClick={() =>
                                                push({
                                                  roomType: "",
                                                  roomPrice: undefined,
                                                  roomDescription: "",
                                                  allowMultipleRoomBooking: undefined,
                                                })
                                              }
                                            >
                                              Add Room Choices +
                                            </Button>
                                          </div>
                                        </div>
                                        {values.roomOptions.map(
                                          (roomOption, index) => (
                                            <React.Fragment key={index}>
                                              <div className="row mb-2">
                                                <div className="col-lg-3 col-md-6 error-position">
                                                  <FormikControl
                                                    control="textfield"
                                                    name={`roomOptions[${index}].roomType`}
                                                    label={`Room ${index + 1} Type`}
                                                    placeholder="Text"
                                                    requiredField
                                                    disabled={false}
                                                    onChange={handleChange}
                                                  />
                                                </div>
                                                <div className="col-lg-2 col-md-6 error-position">
                                                  <FormikControl
                                                    control="textfield"
                                                    name={`roomOptions[${index}].roomPrice`}
                                                    label={`Room ${index + 1} Price`}
                                                    placeholder="Number"
                                                    requiredField
                                                    disabled={false}
                                                    onChange={handleChange}
                                                  />
                                                </div>
                                                <div className="col-lg-3 col-md-6 error-position">
                                                  <FormikControl
                                                    control="textfield"
                                                    name={`roomOptions[${index}].roomDescription`}
                                                    label={`Room ${index + 1
                                                      } Description (Optional)`}
                                                    placeholder="Text"
                                                    disabled={false}
                                                    onChange={handleChange}
                                                  />
                                                </div>

                                                <div className="col-lg-3 col-md-6 error-position">
                                                  <FormikControl
                                                    control="radiogroup"
                                                    name={`roomOptions[${index}].allowMultipleRoomBooking`}
                                                    row
                                                    label="Allow Multiple Room Booking"
                                                    requiredField
                                                    disabled={false}
                                                    options={[
                                                      { key: "Yes", value: "Yes" },
                                                      { key: "No", value: "No" },
                                                    ]}
                                                    onChange={handleChange}
                                                  />
                                                </div>

                                                <div className="col-lg-1 col-md-12 button-registration">
                                                  <Button
                                                    type="button"
                                                    variant="contained"
                                                    color="warning"
                                                    onClick={() => remove(index)}
                                                    disabled={false}
                                                  >
                                                    Remove
                                                  </Button>
                                                </div>
                                              </div>
                                            </React.Fragment>
                                          )
                                        )}
                                      </div>
                                    )}
                                  </FieldArray>
                                )}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-12 col-md-12 text-end ">
                                <Box marginTop="20px" display="grid" justifyContent="space-between">
                                  <Button
                                    className='btn btn-back'
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleBack}
                                  >
                                    Back
                                  </Button>
                                  <Button
                                    //type="submit"
                                    className='btn btn-submit'
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNext}
                                    disabled={(!dirty || !(isValid)) || values.roomOptionsBtn === ""}
                                  >
                                    Next
                                  </Button>
                                </Box>
                              </div>
                            </div>

                          </div>
                        </>
                      )}

                      {activeStep === 2 && (
                        <>
                          <div className="container d-flex justify-content-between flex-column">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="row">
                                  <div className="col-lg-3 col-md-6 mb-3 error-position">
                                    <FormikControl
                                      control="radiogroup"
                                      name="tableOptionsBtn"
                                      label="Table Options"
                                      requiredField
                                      disabled={false}
                                      options={[
                                        { key: "Yes", value: "Yes" },
                                        { key: "No", value: "No" },
                                      ]}
                                    />
                                  </div>
                                </div>

                                {values.tableOptionsBtn === "Yes" && (
                                  <FieldArray name="tableOptions">
                                    {({ push, remove }) => (
                                      <div>
                                        <div className="row">
                                          <div className="col-lg-12 col-md-12 mt-1 mb-3">
                                            <Button
                                              type="button"
                                              variant="contained"
                                              color="primary"
                                              name="tableOptions"
                                              onClick={() =>
                                                push({
                                                  tableType: "",
                                                  tablePrice: undefined,
                                                  tableDescription: "",
                                                  addTableChoice: "",
                                                  allowMultipleTableBooking: undefined,
                                                })
                                              }
                                            >Add Table Choices +
                                            </Button>

                                          </div>
                                        </div>
                                        {values.tableOptions.map(
                                          (tableOptions, index) => (
                                            <React.Fragment key={index}>
                                              <div className="row mb-2">
                                                <div className="col-lg-3 col-md-6 error-position">
                                                  <FormikControl
                                                    control="textfield"
                                                    name={`tableOptions[${index}].tableType`}
                                                    label={`Table ${index + 1
                                                      } Type`}
                                                    placeholder="Text"
                                                    requiredField
                                                    disabled={false}
                                                    onChange={handleChange}
                                                  />

                                                </div>
                                                <div className="col-lg-2 col-md-6 error-position">
                                                  <FormikControl
                                                    control="textfield"
                                                    name={`tableOptions[${index}].tablePrice`}
                                                    label={`Table ${index + 1
                                                      } Price`}
                                                    placeholder="Number"
                                                    requiredField
                                                    disabled={false}
                                                    onChange={handleChange}
                                                  />

                                                </div>
                                                <div className="col-lg-3 col-md-6 error-position">
                                                  <FormikControl
                                                    control="textfield"
                                                    name={`tableOptions[${index}].tableDescription`}
                                                    label={`Table ${index + 1
                                                      } Descritption`}
                                                    placeholder="Text"
                                                    disabled={false}
                                                    onChange={handleChange}
                                                  />

                                                </div>

                                                <div className="col-lg-3 col-md-6 error-position">
                                                  <FormikControl
                                                    control="radiogroup"
                                                    name={`tableOptions[${index}].allowMultipleTableBooking`}
                                                    label="Allow Multiple Table Booking"
                                                    requiredField
                                                    disabled={false}
                                                    options={[
                                                      { key: "Yes", value: "Yes" },
                                                      { key: "No", value: "No" },
                                                    ]}
                                                    onChange={handleChange}
                                                  />

                                                </div>

                                                <div className="col-lg-1 col-md-12 button-registration">
                                                  <Button
                                                    type="button"
                                                    variant="contained"
                                                    color="warning"
                                                    onClick={() => remove(index)}
                                                    disabled={false}
                                                  >
                                                    Remove
                                                  </Button>
                                                </div>
                                              </div>
                                            </React.Fragment>
                                          )
                                        )}
                                      </div>
                                    )}
                                  </FieldArray>
                                )}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-12 col-md-12 text-end ">
                                <Box marginTop="20px" display="grid" justifyContent="space-between">
                                  <Button
                                    className='btn btn-back'
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleBack}
                                  >
                                    Back
                                  </Button>
                                  <Button
                                    //type="submit"
                                    className='btn btn-submit'
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNext}
                                    disabled={(!dirty || !(isValid)) || values.tableOptionsBtn === ""}
                                  >
                                    Next
                                  </Button>
                                </Box>
                              </div>
                            </div>

                          </div>

                        </>
                      )}

                      {activeStep === 3 && (
                        <>
                          <div className="container d-flex justify-content-between flex-column">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="row">
                                  <div className="col-lg-3 col-md-6 mb-3 error-position">
                                    <FormikControl
                                      control="radiogroup"
                                      name="foodOptionsBtn"
                                      label="Food Options"
                                      requiredField
                                      disabled={false}
                                      options={[
                                        { key: "Yes", value: "Yes" },
                                        { key: "No", value: "No" },
                                      ]}
                                    />
                                  </div>
                                </div>

                                {values.foodOptionsBtn === "Yes" && (
                                  <FieldArray name="foodOptions">
                                    {({ push, remove }) => (
                                      <div>
                                        <div className="row">
                                          <div className="col-lg-12 col-md-12 mt-1 mb-3">
                                            <Button
                                              type="button"
                                              variant="contained"
                                              color="primary"
                                              name="foodOptions"
                                              onClick={() =>
                                                push({
                                                  foodType: "",
                                                  foodPrice: undefined,
                                                  foodDescription: "",
                                                  allowMultipleFoodBooking: undefined,
                                                })
                                              }
                                            >Add Food Choices +
                                            </Button>
                                          </div>
                                        </div>
                                        {values.foodOptions.map(
                                          (foodOptions, index) => (
                                            <React.Fragment key={index}>
                                              <div className="row mb-2">
                                                <div className="col-lg-3 col-md-6 error-position">
                                                  <FormikControl
                                                    control="textfield"
                                                    name={`foodOptions[${index}].foodType`}
                                                    label={`Food ${index + 1} Type`}
                                                    placeholder="Text"
                                                    requiredField
                                                    disabled={false}
                                                    onChange={handleChange}
                                                  />

                                                </div>
                                                <div className="col-lg-2 col-md-6 error-position">
                                                  <FormikControl
                                                    control="textfield"
                                                    name={`foodOptions[${index}].foodPrice`}
                                                    label={`Food ${index + 1
                                                      } Price`}
                                                    placeholder="Number"
                                                    requiredField
                                                    disabled={false}
                                                    onChange={handleChange}
                                                  />

                                                </div>
                                                <div className="col-lg-3 col-md-6 error-position">
                                                  <FormikControl
                                                    control="textfield"
                                                    name={`foodOptions[${index}].foodDescription`}
                                                    label={`Food ${index + 1
                                                      } Description (Optional)`}
                                                    placeholder="Text"
                                                    disabled={false}
                                                    onChange={handleChange}
                                                  />

                                                </div>

                                                <div className="col-lg-3 col-md-6 error-position">
                                                  <FormikControl
                                                    control="radiogroup"
                                                    name={`foodOptions[${index}].allowMultipleFoodBooking`}
                                                    label="Allow Multiple Food Booking"
                                                    requiredField
                                                    disabled={false}
                                                    options={[
                                                      { key: "Yes", value: "Yes" },
                                                      { key: "No", value: "No" },
                                                    ]}
                                                    onChange={handleChange}
                                                  />

                                                </div>

                                                <div className="col-lg-1 col-md-12 button-registration">
                                                  <Button
                                                    type="button"
                                                    variant="contained"
                                                    color="warning"
                                                    onClick={() => remove(index)}
                                                    disabled={false}
                                                  >
                                                    Remove
                                                  </Button>
                                                </div>
                                              </div>
                                            </React.Fragment>
                                          )
                                        )}
                                      </div>
                                    )}
                                  </FieldArray>
                                )}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-12 col-md-12 text-end ">
                                <Box marginTop="20px" display="grid" justifyContent="space-between">
                                  <Button
                                    className='btn btn-back'
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleBack}
                                  >
                                    Back
                                  </Button>
                                  <Button
                                    //type="submit"
                                    className='btn btn-submit'
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNext}
                                    disabled={(!dirty || !(isValid)) || values.foodOptionsBtn === ""}
                                  >
                                    Next
                                  </Button>
                                </Box>
                              </div>
                            </div>

                          </div>
                        </>
                      )}

                      {activeStep === 4 && (
                        <>
                          <div className="container d-flex justify-content-between flex-column">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="row">
                                  <div className="col-lg-3 col-md-6 mb-3 error-position">
                                    <FormikControl
                                      control="radiogroup"
                                      name="activityOptionsBtn"
                                      label="Activity Options"
                                      requiredField
                                      disabled={false}
                                      options={[
                                        { key: "Yes", value: "Yes" },
                                        { key: "No", value: "No" },
                                      ]}
                                    />
                                  </div>
                                </div>

                                {values.activityOptionsBtn === "Yes" && (
                                  <FieldArray name="activityOptions">
                                    {({ push, remove }) => (
                                      <div>
                                        <div className="row">
                                          <div className="col-lg-12 col-md-12 mt-1 mb-3">
                                            <Button
                                              type="button"
                                              variant="contained"
                                              color="primary"
                                              name="activityOptions"
                                              onClick={() =>
                                                push({
                                                  activityType: "",
                                                  activityPrice: undefined,
                                                  activityDescription: undefined,
                                                })
                                              }
                                            >Add Activity Choices +
                                            </Button>

                                          </div>
                                        </div>
                                        {values.activityOptions.map(
                                          (activityOptions, index) => (
                                            <React.Fragment key={index}>
                                              <div className="row mb-2">
                                                <div className="col-lg-3 col-md-6 error-position">
                                                  <FormikControl
                                                    control="textfield"
                                                    name={`activityOptions[${index}].activityType`}
                                                    label={`Activity ${index + 1
                                                      } Type`}
                                                    placeholder="Text"
                                                    requiredField
                                                    disabled={false}
                                                    onChange={handleChange}
                                                  />

                                                </div>
                                                <div className="col-lg-2 col-md-6 error-position">
                                                  <FormikControl
                                                    control="textfield"
                                                    name={`activityOptions[${index}].activityPrice`}
                                                    label={`Activity ${index + 1
                                                      } Price`}
                                                    placeholder="Number"
                                                    requiredField
                                                    disabled={false}
                                                    onChange={handleChange}
                                                  />

                                                </div>
                                                <div className="col-lg-3 col-md-6 error-position">
                                                  <FormikControl
                                                    control="textfield"
                                                    name={`activityOptions[${index}].activityDescription`}
                                                    label={`Activity ${index + 1
                                                      } Description`}
                                                    placeholder="Text"
                                                    disabled={false}
                                                    onChange={handleChange}
                                                  />

                                                </div>

                                                <div className="col-lg-3 col-md-6 error-position">
                                                  <FormikControl
                                                    control="radiogroup"
                                                    name={`activityOptions[${index}].activityOptionsAllowed`}
                                                    row
                                                    label="Allow Multiple Activity Options"
                                                    requiredField
                                                    disabled={false}
                                                    options={[
                                                      { key: "Yes", value: "Yes" },
                                                      { key: "No", value: "No" },
                                                    ]}
                                                    onChange={handleChange}
                                                  />

                                                </div>

                                                <div className="col-lg-1 col-md-12 button-registration">
                                                  <Button
                                                    type="button"
                                                    variant="contained"
                                                    color="warning"
                                                    onClick={() => remove(index)}
                                                    disabled={false}
                                                  >
                                                    Remove
                                                  </Button>
                                                </div>
                                              </div>
                                            </React.Fragment>
                                          )
                                        )}
                                      </div>
                                    )}
                                  </FieldArray>
                                )}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-12 col-md-12 text-end ">
                                <Box marginTop="20px" display="grid" justifyContent="space-between">
                                  <Button
                                    className='btn btn-back'
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleBack}
                                  >
                                    Back
                                  </Button>
                                  <Button
                                    //type="submit"
                                    className='btn btn-submit'
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNext}
                                    disabled={(!dirty || !(isValid)) || values.activityOptionsBtn === ""}
                                  >
                                    Next
                                  </Button>
                                </Box>
                              </div>
                            </div>

                          </div>
                        </>
                      )}

                      {activeStep === 5 && (
                        <>
                          <div className="container d-flex justify-content-between flex-column">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="row">
                                  <div className="col-lg-3 col-md-6 mb-3 error-position">
                                    <FormikControl
                                      control="radiogroup"
                                      name="otherOptionBtn"
                                      label="Is any other options"
                                      requiredField
                                      disabled={false}
                                      options={[
                                        { key: "Yes", value: "Yes" },
                                        { key: "No", value: "No" },
                                      ]}
                                    />
                                  </div>
                                </div>

                                {values.otherOptionBtn === "Yes" && (
                                  <FieldArray name="anotherOptions">
                                    {({ push, remove }) => (
                                      <div>
                                        <div className="row">
                                          <div className="col-lg-12 col-md-12 mb-3">
                                            <Button
                                              type="button"
                                              variant="contained"
                                              color="primary"
                                              name="anotherOptions"
                                              onClick={() =>
                                                push({
                                                  optionType: "",
                                                  optionPrice: undefined,
                                                  optionDescription: "",
                                                  optionAllowed: undefined
                                                })
                                              }
                                            >Add New Options +
                                            </Button>

                                          </div>
                                        </div>
                                        {values.anotherOptions.map(
                                          (anotherOptions, index) => (
                                            <React.Fragment key={index}>
                                              <div className="row mb-2">
                                                <div className="col-lg-3 col-md-6 error-position">
                                                  <FormikControl
                                                    control="textfield"
                                                    name={`anotherOptions[${index}].optionType`}
                                                    label={`Option ${index + 1
                                                      } Type`}
                                                    placeholder="Text"
                                                    requiredField
                                                    disabled={false}
                                                    onChange={handleChange}
                                                  />

                                                </div>
                                                <div className="col-lg-2 col-md-6 error-position">
                                                  <FormikControl
                                                    control="textfield"
                                                    name={`anotherOptions[${index}].optionPrice`}
                                                    label={`Option ${index + 1
                                                      } Price`}
                                                    placeholder="Number"
                                                    requiredField
                                                    disabled={false}
                                                    onChange={handleChange}
                                                  />

                                                </div>
                                                <div className="col-lg-3 col-md-6 error-position">
                                                  <FormikControl
                                                    control="textfield"
                                                    name={`anotherOptions[${index}].optionDescription`}
                                                    label={`Option ${index + 1
                                                      } Description`}
                                                    placeholder="Text"
                                                    disabled={false}
                                                    onChange={handleChange}
                                                  />

                                                </div>

                                                <div className="col-lg-3 col-md-6 error-position">
                                                  <FormikControl
                                                    control="radiogroup"
                                                    name={`anotherOptions[${index}].optionAllowed`}
                                                    row
                                                    label="Multiple Options Allowed"
                                                    requiredField
                                                    disabled={false}
                                                    options={[
                                                      { key: "Yes", value: "Yes" },
                                                      { key: "No", value: "No" },
                                                    ]}
                                                    onChange={handleChange}
                                                  />

                                                </div>

                                                <div className="col-lg-1 col-md-12 button-registration">
                                                  <Button
                                                    type="button"
                                                    variant="contained"
                                                    color="warning"
                                                    onClick={() => remove(index)}
                                                    disabled={false}
                                                  >
                                                    Remove
                                                  </Button>
                                                </div>
                                              </div>
                                            </React.Fragment>
                                          )
                                        )}
                                      </div>
                                    )}
                                  </FieldArray>
                                )}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-12 col-md-12 text-end ">
                                <Box marginTop="20px" display="grid" justifyContent="space-between">
                                  <Button
                                    className='btn btn-back'
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleBack}
                                  >
                                    Back
                                  </Button>
                                  <Button
                                    //type="submit"
                                    className='btn btn-submit'
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNext}
                                    disabled={(!dirty || !(isValid)) || values.otherOptionBtn === ""}
                                  >
                                    Next
                                  </Button>
                                </Box>
                              </div>
                            </div>

                          </div>
                        </>
                      )}

                      {activeStep === 6 && (
                        <>
                          <CustomGrid
                            item={
                              <FormikControl
                                control="switch"
                                name="sponsorship"
                                label="Sponsorship Needed"
                                requiredField
                                disabled={false}
                              />
                            }
                          />

                          <Box marginTop="20px" display="grid" justifyContent="space-between">
                            <Button
                              className='btn btn-back'
                              variant="contained"
                              color="secondary"
                              onClick={handleBack}
                            >
                              Back
                            </Button>
                            <Button
                              className='btn btn-submit'
                              variant="contained"
                              color="primary"
                              type="submit"
                              disabled={(!dirty || !(isValid)) || values.sponsorship === undefined}
                            >
                              Submit
                            </Button>
                          </Box>
                        </>
                      )}
                    </Grid>
                  </Box>

                  {/* Navigation Buttons */}

                  {/* <Box mt={2} display="flex" justifyContent="space-between">
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      variant="contained"
                      color="secondary"
                    >
                      Back
                    </Button>

                    {activeStep < steps.length - 1 && (
                      <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        disabled={!isValid || !dirty || isSubmitting}
                      >
                        Next
                      </Button>
                    )}

                    {activeStep === steps.length - 1 && (
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={!isValid || !dirty || isSubmitting}
                      >
                        Submit
                      </Button>
                    )}
                  </Box> */}
                </Form>
              )}
            </Formik>
          </Paper>
        </Grid>
      </Box>
        </div>
      :
      <div className="sub-content-sec">
      <EventList />
      </div>
      }

    </>
  );
};

export default Events;
