export class NewsAnnouncementsModel {
  submissionId: string;
  images: { file: File | null; placeholder: string }[];
  messages: string;
  additionalComments: string;

  constructor() {
    this.submissionId = "";
    this.images = [
      {
        file: null,
        placeholder: "/sampleImages/ChooseImage.png",
      },
    ];
    this.messages = "";
    this.additionalComments = "";
  }
}
