import { msgTypes } from "constants/msgTypes";
import { Validation } from "constants/Validation";
import * as Yup from "yup";

export const EventsSchema = [
  Yup.object().shape({
    eventName: Yup.string().required(Validation.required.message.replaceAll("^", "Event"))
      .matches(Validation.plaintext.regex, Validation.plaintext.message),
    eventStartDate: Yup.date().required(Validation.required.message.replaceAll("^", "Event Start Date")),
    eventEndDate: Yup.date().required(Validation.required.message.replaceAll("^", "Event End Date")),
    eventVenue: Yup.string().required(Validation.required.message.replaceAll("^", "Event Venue"))
      .matches(Validation.plaintext.regex, Validation.plaintext.message),
    country: Yup.string().required(Validation.required.message.replaceAll("^", "Country")),
  }),

  Yup.object().shape({
    roomOptionsBtn: Yup.string().required(Validation.required.message.replaceAll("^", "Room Option")),
    roomOptions: Yup.array().when("roomOptionsBtn", (val, schema) => {
      if (val[0] === "Yes") {
        return Yup.array().of(
          Yup.object().shape({
            roomType: Yup.string().required(Validation.required.message.replaceAll("^", "Room Type"))
              .matches(Validation.plaintext.regex, Validation.plaintext.message),
            roomPrice: Yup.string().required(Validation.required.message.replaceAll("^", "Room Price"))
              .matches(Validation.amount.regex, Validation.amount.message),
            roomDescription: Yup.string()
              .matches(Validation.plaintext.regex, Validation.plaintext.message),
            allowMultipleRoomBooking: Yup.string().required()
          })
        )
      } else {
        return Yup.array();
      }
    })

  }),


  Yup.object().shape({
    tableOptionsBtn: Yup.string().required(Validation.required.message.replaceAll("^", "Table Option")),
    tableOptions: Yup.array().when("tableOptionsBtn", (val, schema) => {
      if (val[0] === "Yes") {
        return Yup.array().of(
          Yup.object().shape({
            tableType: Yup.string().required(Validation.required.message.replaceAll("^", "Table Type"))
              .matches(Validation.plaintext.regex, Validation.plaintext.message),
            tablePrice: Yup.string().required(Validation.required.message.replaceAll("^", "Table Price"))
              .matches(Validation.amount.regex, Validation.amount.message),
            tableDescription: Yup.string()
              .matches(Validation.plaintext.regex, Validation.plaintext.message),
            allowMultipleTableBooking: Yup.string().required()
          })
        )
      } else {
        return Yup.array();
      }
    })
  }),



  Yup.object().shape({
    foodOptionsBtn: Yup.string().required(Validation.required.message.replaceAll("^", "Food Option")),
    foodOptions: Yup.array().when("foodOptionsBtn", (val, schema) => {
      if (val[0] === "Yes") {
        return Yup.array().of(
          Yup.object().shape({
            foodType: Yup.string().required(Validation.required.message.replaceAll("^", "Food Type"))
              .matches(Validation.plaintext.regex, Validation.plaintext.message),
            foodPrice: Yup.string().required(Validation.required.message.replaceAll("^", "Food Price"))
              .matches(Validation.amount.regex, Validation.amount.message),
            foodDescription: Yup.string()
              .matches(Validation.plaintext.regex, Validation.plaintext.message),
            allowMultipleFoodBooking: Yup.string().required()
          })
        )
      } else {
        return Yup.array();
      }
    })
  }),


  Yup.object().shape({
    activityOptionsBtn: Yup.string().required(Validation.required.message.replaceAll("^", "Activity Option")),
    activityOptions:
      Yup.array().when("foodOptionsBtn", (val, schema) => {
        if (val[0] === "Yes") {
          return Yup.array().of(
            Yup.object().shape({
              activityType: Yup.string().required(Validation.required.message.replaceAll("^", "Activity Type"))
                .matches(Validation.plaintext.regex, Validation.plaintext.message),
              activityPrice: Yup.string().required(Validation.required.message.replaceAll("^", "Activity Price"))
                .matches(Validation.amount.regex, Validation.amount.message),
              activityDescription: Yup.string()
                .matches(Validation.plaintext.regex, Validation.plaintext.message),
              activityOptionsAllowed: Yup.string().required()
            })
          )
        } else {
          return Yup.array();
        }
      })
  }),


  Yup.object().shape({
    otherOptionBtn: Yup.string().required(Validation.required.message.replaceAll("^", "Option")),
    anotherOptions: Yup.array().when("foodOptionsBtn", (val, schema) => {
      if (val[0] === "Yes") {
        return Yup.array().of(
          Yup.object().shape({
            optionType: Yup.string().required(Validation.required.message.replaceAll("^", "Option type"))
              .matches(Validation.plaintext.regex, Validation.plaintext.message),
            optionPrice: Yup.string().required(Validation.required.message.replaceAll("^", "Option price"))
              .matches(Validation.amount.regex, Validation.amount.message),
            optionDescription: Yup.string()
              .matches(Validation.plaintext.regex, Validation.plaintext.message),
            optionAllowed: Yup.string().required()
          })
        )
      } else {
        return Yup.array();
      }
    })
  }),

  Yup.object().shape({
    sponsorship: Yup.boolean().required(
      "Sponsorship Needed is required"
    ),
  }),
];