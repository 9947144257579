import { ContactPersonModel } from "./ContactPersonModel";



export class MembershipModel{
    contactPerson: ContactPersonModel[];
    companyId: number;
    companyName: string;
    companyEmail: string;
    companyLogo: string;
    companyLogoUrl: string;
    primaryPhoneNumber: string;
    secondaryPhoneNumber: string;
    websiteUrl: string;
    accountingEmail: string;
    addressLine1: string;
    city: number;
    state: number;
    country: number;
    countryName: string;
    zipCode: string;
    companyDetails: string;
    instagramUrl: string;
    linkedinUrl: string;
    twitterUrl: string;
    facebookUrl: string;
    youtubeUrl: string;
    membershipType: string;
    status: string;
    isSelected: boolean;


    constructor(){
        this.contactPerson = [];
        this.companyId = undefined;
        this.companyName="";
        this.companyEmail="";
        this.companyLogo ="";
        this.primaryPhoneNumber="";
        this.secondaryPhoneNumber="";
        this.websiteUrl="";
        this.accountingEmail="";
        this.addressLine1="";
        this.city=0;
        this.state=0;
        this.country=0;
        this.countryName="";
        this.zipCode="";
        this.companyDetails="";
        this.instagramUrl="";
        this.linkedinUrl="";
        this.twitterUrl="";
        this.facebookUrl="";
        this.youtubeUrl="";
        this.membershipType="";
        this.status ="";
        this.isSelected=false;
    }
}