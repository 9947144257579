import CustomTableGrid from "components/uiComponents/CustomTableGrid";
import FormikControl from "components/wrapperComponents/FormikControl";
import { msgTypes } from "constants/msgTypes";
import { Form, Formik } from "formik";
import { PaginatorModel } from "model/utilModels/PaginatorModel";
import { ResponseModel } from "model/utilModels/ResponseModel";
import React, { useEffect, useMemo, useState } from "react";
import { FeaturedInNewsLetterService } from "services/menu/featuredInNewsLetterService/FeaturedInNewsLetterService";

const FeaturedInNewsLetterList = () => {
  const [featuredInNewsLetterList, setFeaturedInNewsLetterList] = useState(
    new ResponseModel()
  );
  const [noRecord, setNoRecord] = useState(false);
  const paginatorModel: PaginatorModel = new PaginatorModel();
  const [pagination, setPagination] = useState({
    pageSize: paginatorModel.pageSize,
    pageNumber: paginatorModel.pageNumber,
    filter: "",
    orderBy: "",
    desc: "",
  });

  useEffect(() => {
    getfeaturedInNewsLetterList();
  }, [
    pagination.pageNumber,
    pagination.pageSize,
    pagination.filter,
    pagination.orderBy,
    pagination.desc,
  ]);

  // Prepare request body to get Featured News Letter List
  const prepareRequest = () => {
    const requestBody = new PaginatorModel();
    requestBody.pageNumber = pagination.pageNumber;
    requestBody.pageSize = pagination.pageSize;
    requestBody.filter = pagination.filter;
    requestBody.orderBy = pagination.orderBy;
    requestBody.desc = pagination.desc;
    return requestBody;
  };

  //Get Featured News Letter Enquiry list
  const getfeaturedInNewsLetterList = async () => {
    const requestBody = prepareRequest();
    const res = await FeaturedInNewsLetterService.getfeaturedInNewsLetterList(
      requestBody
    );
    if (res.statusMsg === msgTypes.SUCCESS.msg) {
      setFeaturedInNewsLetterList(res);
    }
  };

  const initialValues = {};
  const onSubmit = () => {
    getfeaturedInNewsLetterList();
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
        size: 100,
      },
      {
        accessorKey: "category",
        header: "Category",
        size: 50,
      },
      {
        accessorKey: "message",
        header: "Message",
        size: 200,
      },
    ],
    []
  );

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {(formik) => (
          <Form>
            <CustomTableGrid
              item={
                <FormikControl
                  control="materialTable"
                  tableColumn={columns}
                  tableData={featuredInNewsLetterList}
                  formikValue={formik}
                  setPagination={setPagination}
                />
              }
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FeaturedInNewsLetterList;
