import * as React from 'react';
import { useState } from 'react'
import Box from '@mui/material/Box';
import BlogList from './BlogList';
import CreateBlog from './CreateBlog';
import { BlogModel } from 'model/screensModels/BlogModel';


export default function Blog() {
  const [updateValue, setUpdateValue] = React.useState(new BlogModel());
  const [flag, setFlag] = useState(false)
  
  //to get update record value
  const getUpdateRecordValue = (blogModel: BlogModel) =>{
    setUpdateValue(blogModel);
  }

  const addBlog = () =>{
      setUpdateValue(new BlogModel() )
      setFlag(true);
  }

  return (
    <Box sx={{ width: '100%' }} className="container-subheaders">
      <Box sx={{ borderBottom: 1, borderColor: '#ebedf3' }}>
      <div className='content-subheader'>
        {!flag ? <h4 className='mr-3'>Blog List</h4> : <h4 className='mr-3'>Add Blog</h4> }
        {!flag ?
          (<button className='btn primary' onClick={addBlog}>Add Blog</button>)
          :""}
      </div>
      </Box>
      <div className="sub-content-sec">
        {!flag ?
        <BlogList setFlag={setFlag} updateRecord={getUpdateRecordValue}></BlogList>
        :
        <CreateBlog setFlag={setFlag} updateRecord={updateValue}></CreateBlog>
        }
      </div>
    </Box>
  );
}