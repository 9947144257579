export class NewsLettersModel {
  newsLetterId: string;
  title: string;
  imageFile: any;
  image: string;  
  description:string;

  constructor() {
    this.newsLetterId ="";
    this.title = "";
    this.imageFile = null;
    this.image = "";  
    this.description="";
  }
}
