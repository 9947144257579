import { Box, TextField } from "@mui/material";
import { CustomErrorDiv } from "components/uiComponents/CustomErrorDiv";
import { CustomInputLabel } from "components/uiComponents/CustomInputLabel";

const CustomTextField = (props) => {
    const { handleBlur, handleChange} = props.formikValue;
    return (
        <Box >
            <CustomInputLabel propsValue = {props}/>
            <TextField 
                autoComplete="off"
                type="text"
                variant="outlined"
                size="small"
                className="component-background"
                fullWidth
                disabled={props.disabled}
                placeholder={props.placeholder}
                name={props.name}
                value={props.value}
                onChange={props.onChange ? e=>{props.onChange(e);handleChange(e)}: handleChange}
                onBlur={handleBlur}
                onFocus={(event) => event.target.select()}
                sx={{ '& fieldset': props.style }}
            >
            </TextField>
            <CustomErrorDiv propsValue={props} />
        </Box>
    )
}

export default CustomTextField;