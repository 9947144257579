import React, { useEffect, useState } from "react";
import {
    Avatar,
    Box,
    Card,
    CardActionArea,
    CardMedia,
    Grid,
    Paper,
    Typography,
  } from "@mui/material";
import { InvoiceManagementModel } from "model/screensModels/InvoiceManagementModel";
import { InvoiceManagementService } from "services/menu/invoiceManagementService/InvoiceManagementService";
import { msgTypes } from "constants/msgTypes";

function CompanyFinancialProtection({companyId}){
    const [companyStatus, setCompanyStatus] = useState(undefined);

    useEffect(() => {
      if(companyId!=undefined)
           getCompanyStatus();
      }, [companyId]);
    

      const getCompanyStatus = async () => {
        const requestBody = new InvoiceManagementModel();
        requestBody.fromCompanyId = companyId;
        const res = await InvoiceManagementService.invoiceManagementstatus(requestBody);
        if (res.statusMsg === msgTypes.SUCCESS.msg) {
            setCompanyStatus(res.data);
        }
      };
      
 return(
<>
{companyStatus?(
<div className="row px-4  ">
            <div className="d-flex">
               <div className="col-md-4 ">
                      <div className="d-flex align-items-center financial-sec">
                        
                        <div>
                          <Avatar sx={{ bgcolor: "green" }}>{companyStatus?.Paid}</Avatar>
                        </div>
                        <div className="ml-2">
                          <h5>Paid Invoices</h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="d-flex align-items-center financial-sec">
                        <div>
                          <Avatar sx={{ bgcolor: "orange" }}>{companyStatus?.Outstanding}</Avatar>
                        </div>
                        <div className="ml-2">
                          <h5>Outstanding Invoices</h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="d-flex align-items-center financial-sec">
                        <div>
                          <Avatar sx={{ bgcolor: "red" }}>{companyStatus?.Overdue}</Avatar>
                        </div>
                        <div className="ml-2">
                          <h5>Overdue Invoices</h5>
                        </div>
                      </div>
                    </div>
            </div>


                  </div>
                  ):""}
</>


 )
}


export default CompanyFinancialProtection ;