import { Form, Formik } from 'formik';
import { Box, Grid } from '@mui/material';
import CustomGrid from 'components/uiComponents/CustomGrid';
import CustomButtonGrid from 'components/uiComponents/CustomButtonGrid';
import FormikControl from 'components/wrapperComponents/FormikControl';
import { BlogModel } from 'model/screensModels/BlogModel';
import { useEffect, useState } from 'react';
import { BlogSchema } from 'schemas/menu/blog/BlogSchema';
import { BlogService } from 'services/menu/blogService/BlogService';
import { msgTypes } from 'constants/msgTypes';


const CreateBlog = ({ setFlag, updateRecord }) => {
  const [viewMode, setViewMode] = useState(false);
  const [initialValue, setInitialValue] = useState(new BlogModel());
  const onSubmit = async (values: BlogModel) => {
    addBlog(values)
  }
  

  useEffect(() => {
    if (updateRecord) {
      setInitialValue(updateRecord)
    }
  }, [updateRecord])

 
  //Prepare Data for API
  const prepareRequestBody = async (values: BlogModel) => {
    const prepareData = new BlogModel();

    //to assing id based on action 
    if ((initialValue.blogId)?.length > 0) {
      prepareData.blogId = initialValue.blogId;
    } else {
      prepareData.blogId = values.blogId ? values.blogId : '';
    }

    prepareData.title = values.title ? values.title : '';
    prepareData.metaTitle = values.metaTitle ? values.metaTitle : '';
    prepareData.shortDescription = values.shortDescription ? values.shortDescription : '';
    prepareData.description = values.description ? values.description : '';
    prepareData.metaDescription = values.metaDescription ? values.metaDescription : '';
    prepareData.altTag = values.altTag ? values.altTag : '';
    prepareData.image = values.image ? values.image : '';

    return prepareData;

  }

  const addBlog = async (values: BlogModel) => {
    const requestData = await prepareRequestBody(values);
    const res = await BlogService.addBlog(requestData);
    if (res.statusMsg === msgTypes.SUCCESS.msg) {
      setInitialValue(new BlogModel())
      setFlag(false)
    }
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValue}
        validationSchema={BlogSchema}
        onSubmit={onSubmit}
      >
        {
          ({ isValid, dirty, isSubmitting, values }) => (
            <Form>
              <Box marginLeft={1.5} marginRight={0.5}>
                <Grid container spacing={1} bgcolor={'#fff'} paddingTop={4} paddingBottom={2} paddingRight={'5px'}>
                  <CustomGrid
                    item={
                      <FormikControl control='textfield'
                        name='title'
                        label="Blog Title"
                        placeholder="Enter blog title"
                        requiredField
                        disabled={viewMode}
                      />
                    } />

                  <CustomGrid
                    item={
                      <FormikControl control='textfield'
                        label="Meta Title"
                        name="metaTitle"
                        placeholder="Enter Meta Title"
                        disabled={viewMode}
                      />
                    } />

                  <CustomGrid
                    item={
                      <FormikControl
                        control='textfield'
                        label="Short Description"
                        name="shortDescription"
                        placeholder="Enter short description"
                        disabled={viewMode}
                      />
                    } />

                  <CustomGrid
                    item={
                      <FormikControl control='textfield'
                        label="Description"
                        name="description"
                        placeholder="Enter Description"
                        requiredField
                        disabled={viewMode}
                      />
                    } />

                  <CustomGrid
                    item={
                      <FormikControl control='textfield'
                        label="Meta Description"
                        name="metaDescription"
                        placeholder="Enter Meta Description"
                        disabled={viewMode}
                      />
                    } />

                  <CustomGrid
                    item={
                      <FormikControl control='textfield'
                        label="Alt tag"
                        name="altTag"
                        placeholder="Enter Alt Tag"
                        disabled={viewMode}
                      />
                    } />

                  <CustomGrid
                    item={
                      <FormikControl control='uploadImage'
                        label="Upload Image"
                        requiredField
                        name="imageFile"
                        nameUrl="image"
                        disabled={viewMode}
                      />
                    } />

                  {!viewMode ? (
                    <CustomButtonGrid
                      item={
                        <>
                          <FormikControl control='button' color="primary" label={values.blogId==='' ? "Save" : "Update"} type="submit" disabled={!dirty || !isValid || isSubmitting} />
                          <FormikControl control='button' className="btn btn-danger" color="warning" label="Back" type="reset" onClick={()=> {setFlag(false)}} />
                        </>
                      } />
                  ) : null}
                </Grid>
              </Box>
            </Form>
          )
        }
      </Formik>
    </>
  )
}
export default CreateBlog;


