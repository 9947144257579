

export class EventsModel {
  eventId: number;
  eventName: string;
  eventStartDate: string;
  eventEndDate: string;
  eventVenue: string;
  country: string;

  roomOptionsBtn: string; // step 2

  // Room options array
  roomOptions: {
    roomType: string;
    roomPrice: number;
    roomDescription: string;
    allowMultipleRoomBooking: string;
  }[];

  tableOptionsBtn: string; // step 3

// Table options array
  tableOptions: {
  tableType: string;
  tablePrice: number;
  tableDescription: string;
  allowMultipleTableBooking: string;
  }[];


  foodOptionsBtn: string;

  // Food options array

  foodOptions: {
  foodType: string;
  foodPrice: number;
  foodDescription: string;
  allowMultipleFoodBooking: string;
  }[];


  
 activityOptionsBtn: string;

  // Activity options array

  activityOptions: {
  activityType: string;
  activityPrice: number;
  activityDescription: string;
  activityOptionsAllowed: string;
  }[];


  otherOptionBtn: string;

  anotherOptions: {
    optionType: string;
    optionPrice: number;
    optionDescription: string;
    optionAllowed: string;
    }[];
 // addNewOptionType: string;
  sponsorship: boolean;

  constructor() {
    this.eventId = 0;
    this.eventName = "";
    this.eventStartDate = "";
    this.eventEndDate = "";
    this.eventVenue = "";
    this.country = "";

    this.roomOptionsBtn = ""; // step 2

    // Room options array

    this.roomOptions = [
      {
        roomType: "",
        roomPrice: undefined,
        roomDescription: "",
        allowMultipleRoomBooking: "",

      }
    ];

    this.tableOptionsBtn = ""; // step 3

// Table options array

    this.tableOptions = [
      {
    tableType: "",
    tablePrice: undefined,
    tableDescription: "",
    allowMultipleTableBooking: "",
      }
    ];


    this.foodOptionsBtn = "";

    // Food options array

    this.foodOptions = [
      {
    foodType: "",
    foodPrice: undefined,
    foodDescription: "",
    allowMultipleFoodBooking: "",
      }
    ];

    
    this.activityOptionsBtn = "";

    // Activity options array

    this.activityOptions = [
      {
    activityType: "",
    activityPrice: undefined,
    activityDescription: "",
    activityOptionsAllowed: "",
      }
    ];

    
    this.otherOptionBtn = "";

    //this.addNewOptionType = "";
    this.anotherOptions = [
      {
        optionType: "",
        optionPrice: undefined,
        optionDescription: "",
        optionAllowed: "",
      }
    ];

    this.sponsorship = false;
  }
}




