
import React, { useEffect } from "react";
import { Form, Formik, ErrorMessage } from "formik";
import { Box, Grid, Typography } from "@mui/material";
import CustomGrid from "components/uiComponents/CustomGrid";
import CustomButtonGrid from "components/uiComponents/CustomButtonGrid";
import FormikControl from "components/wrapperComponents/FormikControl";
import { useState } from "react";
import { CompanyServicesModel } from "model/screensModels/CompanyServicesModel";
import { CompanyServicesSchema } from "schemas/menu/companyServices/CompanyServicesSchema";
import { CompanyServicesService } from "services/menu/companyServicesService/CompanyServicesService";
import { UploadFileService } from "services/shared/UploadFileService";
import { msgTypes } from "constants/msgTypes";
import { UtilService } from 'services/shared/UtilService';
import { InvoiceManagementModel } from 'model/screensModels/InvoiceManagementModel';

const CreateCompanyServices = ({ setFlag, updateRecord }) => {
  const [viewMode, setViewMode] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [initialValues, setInitialValues] = useState(
    new CompanyServicesModel()
  );
  const [initialValue, setInitialValue] = useState(new InvoiceManagementModel());

  
  const loadCompany = async () => {
    const res = await UtilService.getCompanyList();
    if (res.statusMsg === msgTypes.SUCCESS.msg) {
        setCompanyList(res.data);
    }
  }

  useEffect(() => {
    loadCompany();
    if (updateRecord) {
      setInitialValue(updateRecord)
      //values = updateRecord;
    }
  }, [updateRecord])


  const onSubmit = async (values: CompanyServicesModel) => {
    addCompanyServices(values);
  };

  useEffect(() => {
    if (updateRecord) {
      setInitialValues(updateRecord);
    }
  }, [updateRecord]);

  //Prepare Data for API
  const prepareRequestBody = async (values: CompanyServicesModel) => {
    const prepareData = new CompanyServicesModel();

    //to assing id based on action
    if (initialValues.companyServiceId?.length > 0) {
      prepareData.companyServiceId = initialValues.companyServiceId;
    } else {
      prepareData.companyServiceId = values.companyServiceId
        ? values.companyServiceId
        : "";
    }
    prepareData.companyId = values.companyId ? values.companyId : "";
    prepareData.companyName = values.companyName ? values.companyName : "";
    prepareData.title = values.title ? values.title : "";
    prepareData.description = values.description ? values.description : "";
    prepareData.image = values.image ? values.image : "";

    return prepareData;
  };

  const addCompanyServices = async (values: CompanyServicesModel) => {
    const requestData = await prepareRequestBody(values);
    const res = await CompanyServicesService.addCompanyServices(requestData);
    if (res.statusMsg === msgTypes.SUCCESS.msg) {
      setInitialValues(new CompanyServicesModel());
      setFlag(false);
    }
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={CompanyServicesSchema}
        onSubmit={onSubmit}
      >
        {({ isValid, dirty, isSubmitting, values }) => (
          <Form>
            <div className="container">
              <div className="row">
                <div className="col-md-5 position-relative">
                  <FormikControl
                    control="textfield"
                    label="Title"
                    name="title"
                    placeholder="Enter Title"
                    requiredField
                    disabled={viewMode}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-5 position-relative">
                  <FormikControl
                    control="uploadImage"
                    label="Upload Image"
                    name="imageFile"
                    nameUrl="image"
                    requiredField
                    disabled={viewMode}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-5 textarea-sec position-relative">
                  <FormikControl
                    control="textfield"
                    name="description"
                    label="Description"
                    placeholder="Enter Description"
                    type="text"
                    variant="outlined"
                    multiline
                    disabled={viewMode}
                    rows={8}
                    requiredField
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-5">
                  {!viewMode ? (
                    <div className="mt-3 d-flex justify-content-end">
                        <div className="pr-2">
                        <FormikControl
                          control="button"
                          color="primary"
                          label={
                            values.companyServiceId === "" ? "Save" : "Update"
                          }
                          type="submit"
                          disabled={!dirty || !isValid || isSubmitting}
                        />
                      </div>
                      <div >
                        <FormikControl
                          control="button"
                          className="btn btn-danger"
                          color="warning"
                          label="Back"
                          type="reset"
                          onClick={() => {
                            setFlag(false);
                          }}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CreateCompanyServices;
