import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardMedia,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import "./CompanyProfile.scss";
import CompanyServices from "./CompanyServices";
import { NavLink, useLocation } from "react-router-dom";
import CompanyFinancialProtection from "./CompanyFinancialProtection";
import { MembershipModel } from "model/screensModels/membership/MembershipModel";
import { UtilService } from "services/shared/UtilService";
import { msgTypes } from "constants/msgTypes";
import { LocalStorageService } from 'services/auth/LocalStorageService';

const CompanyProfile = () => {
  const location = useLocation();
  const [selectedCompanyId, setSelectedCompanyId] = useState(undefined);
  const [companyData , setCompanyData]= useState(new MembershipModel());

  useEffect(() => {
    if (location.state?.companyId) {
      setSelectedCompanyId(location.state.companyId);
    }else{
      getCompanyIdByLocalStorage();
    }
  
    
  });

  useEffect(()=>{
    if(selectedCompanyId!=undefined)
    companyDetails();
  },[selectedCompanyId])


  const getCompanyIdByLocalStorage = async() =>{
    const userDetails = JSON.parse(await LocalStorageService.getUserDetails());
    setSelectedCompanyId(userDetails.companyId);
    
  }

  
  const companyDetails = async () => {
    const requestBody = new MembershipModel();
    requestBody.companyId = selectedCompanyId;
    const res = await UtilService.getCompanyDetailsByCompanyId(requestBody);

    if (res.statusMsg === msgTypes.SUCCESS.msg) {
      setCompanyData(res.data);
    }
  };

  const companyAddress = `${companyData?.addressLine1}, ${companyData?.city}, ${companyData?.countryName}-${companyData?.zipCode}`;
  const googleMapsUrl = `https://www.google.com/maps?q=${encodeURIComponent(
    `${companyAddress}`
  )}&output=embed`;

  return (
    <>
    {companyData?(
      <div className="container mt-3">
        <div className="row">
          <div className="col-lg-9">
            <div className="row">
              <div className="col-12">
                <div className="company-profile-sec">
                  <div className="row justify-content-between align-items-center mx-0 status-sec">
                    <div className="col-md-6">
                      <Typography className="title-1-1">
                        Company Profile
                      </Typography>
                    </div>
                    <div className="col-md-6">
                      <Typography className="title-1-2">
                        Status: <span>Active</span>
                      </Typography>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="company-profile-page">
                        <div className="company-profile-section d-flex">
                          <div className="company-logo">
                            <CardMedia
                              component="img"
                              height="140"
                              image="/images/logistics.png"
                              alt="Logistics"
                            />
                          </div>
                          <div className="company-content">
                            <h2>{companyData.companyName}</h2>
                            <p>
                              Address: {companyAddress}
                              <br /> <br />
                              Website: http://www.combinedlog.com
                              <br /> <br />
                              Enrollment: October 10, 2013 Expire: October 9,
                              2024
                              <br /> <br />
                              HQ/Branch: HQ
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="company-profile-sec">
                  <div className="row mx-0 d">
                    <div className="col-12 status-sec">
                      <Typography className="title-2">
                        Financial Protection System
                      </Typography>
                    </div>
                    <CompanyFinancialProtection companyId={companyData?.companyId} />
                  </div>
                </div>
                <div className="company-profile-sec">
                  <div className="row mx-0 flex max-w-3">
                    <div className="col-md-12 status-sec">
                      <Typography className="title-1-1">
                        Company Profile
                      </Typography>
                    </div>
                    <div className="col-12 p-3">
                      <Typography>{companyData.companyDetails}</Typography>
                    </div>
                  </div>
                </div>
                <div className="company-profile-sec">
                  <div className="row mx-0 flex max-w-3">
                    <div className="col-md-12 status-sec">
                      <Typography className="title-1-1">
                        Company Services
                      </Typography>
                    </div>
                    <div className="col-12 p-3">
                      <CompanyServices companyId={companyData?.companyId} />
                    </div>
                  </div>
                </div>
                <div className="company-profile-sec">
                  <div className="row mx-0 flex max-w-3">
                    <div className="col-md-12 status-sec">
                      <Typography className="title-1-1">
                        Company Location
                      </Typography>
                    </div>
                    <div className="col-12 p-3">
                      <iframe
                        src={googleMapsUrl}
                        width="100%"
                        height="450"
                        loading="lazy"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            {/* Contact Person Section */}
          </div>
        </div>
      </div>
      ):""}
    </>
  );
};

export default CompanyProfile;
