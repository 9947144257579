export class MailRequestModel {

    from: string;
    to: string[];
    cc: string[];
    bcc: string[];
    subject: string;
    body: string;

    constructor() {
        this.from="";
        this.to=[];
        this.cc=[];
        this.bcc=[];
        this.subject="";
        this.body ="";
    }
}
