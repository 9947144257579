import { msgTypes } from "constants/msgTypes";
import { AuthService } from "./AuthService";

export class LocalStorageService {
    //Token 
    static getToken = async () => {
        const token = ""+localStorage.getItem(msgTypes.ACCESS_TOKEN);
        return token;
    };

    static setToken = async (token: string) => {
        localStorage.setItem(msgTypes.ACCESS_TOKEN ,token);
    };

    static getUserDetails = async () => {
        const userDetails = ""+localStorage.getItem(msgTypes.USER_DETAILS);
        const decryptData = await AuthService.getDecryptText(userDetails)
        return decryptData;
    };

    static setUserDetails = async (userDeatils: string) => {
        const encryptData= await AuthService.getEncryptText(userDeatils)
        localStorage.setItem(msgTypes.USER_DETAILS ,encryptData);
    };


}