
import Header from "../header/Header";
import "./Home.scss"
import { Box, Stack } from "@mui/material";
import ContentPane from "../ContentPane/ContentPane";
import SideMenu from "../menuItems/SideMenu";

const Home = () => {
  return (
    <>
      <Header />
      <Stack direction={{ xs: "row" }} justifyContent={{ sm: "space-between" }} >
      {/* <Hidden xsDown> <SideMenu /></Hidden> */}
      <SideMenu />
        <Box width={'82%'} overflow={'hidden'} flex={4} className="content">
          <ContentPane />
        </Box>
      </Stack>
    </>
  )
}
export default Home;
