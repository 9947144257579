import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Card,
  CardContent,
} from "@mui/material";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import "./AnnouncementDisplay.scss";
import { useEffect, useState } from "react";
import { AnnouncementService } from "services/menu/announcementService/AnnouncementService";
import { msgTypes } from "constants/msgTypes";
import { PaginatorModel } from "model/utilModels/PaginatorModel";
import ApiUrl from "services/shared/ApiUrl";
import { AnnouncementModel } from "model/screensModels/AnnouncementModel";

const AnnouncementDisplay = () => {
  const [announcementData, setAnnouncementData] = useState([]);

  const paginatorModel = new PaginatorModel();
  const [pagination, setPagination] = useState({
    pageSize: paginatorModel.pageSize,
    pageNumber: paginatorModel.pageNumber,
  });

  useEffect(() => {
    getAnnouncementList();
  }, []);

  const prepareRequest = () => {
    const requestBody = new PaginatorModel();
    requestBody.pageNumber = pagination.pageNumber;
    requestBody.pageSize = pagination.pageSize;
    return requestBody;
  };

  const getAnnouncementList = async () => {
    const requestBody = prepareRequest();
    const res = await AnnouncementService.getAnnouncementList(requestBody);

    if (res.statusMsg === msgTypes.SUCCESS.msg) {
      const announcementAllData = res.data.tbody.map((data) => ({
        attachment: ApiUrl.fileBaseUrl + data.attachment,
        title: data.title,
      }));

      setAnnouncementData(announcementAllData);
    }
  };

  const openInTab = async (announcement: AnnouncementModel) => {
    if (announcement.attachment) {
      // Image Open In The New Tab
      window.open(announcement.attachment, "_blank");

      // Download The Image
      const imageURL = await fetch(announcement.attachment);
      const image = await imageURL.blob();
      const url = window.URL.createObjectURL(image);
      const link = document.createElement("a");
      link.href = url;
      link.download = announcement.attachment.split("/").pop();
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } else {
      alert("No Image Is Found From This Attachment");
    }
  };

  return (
    <Box className="announcement-display-main-box">
      <Typography gutterBottom>
        <div className="announcement-display">
          <h6>All Announcements</h6>
        </div>
      </Typography>
      <Card className="announcement-display-data-card">
        <CardContent>
          <List>
            {announcementData.map((announcement, index) => (
              <ListItem key={index}>
                <ListItemIcon className="announcement-display-data-list-item">
                  <MailOutlineOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <a
                      href="#"
                      onClick={() => openInTab(announcement)}
                      className="announcement-title"
                    >
                      {announcement.title}
                    </a>
                  }
                />
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>
    </Box>
  );
};

export default AnnouncementDisplay;
