import { Box } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import CreateCompanyServices from "./CreateCompanyServices";
import CompanyServicesList from "./CompanyServicesList";
import { CompanyServicesModel } from "model/screensModels/CompanyServicesModel";

export default function CompanyServices() {
  const [value, setValue] = React.useState(0);
  const [updateValue, setUpdateValue] = React.useState(
    new CompanyServicesModel()
  );
  const [flag, setFlag] = useState(false);

  //to get update record value
  const getUpdateRecordValue = (CompanyServicesModel: CompanyServicesModel) => {
    setUpdateValue(CompanyServicesModel);
  };

  const addService = () =>{
    setUpdateValue(new CompanyServicesModel())
    setFlag(true);
  }

  return (
    <Box sx={{ width: "100%" }} className="container-subheaders">
      <Box sx={{ borderBottom: 1, borderColor: "#ebedf3" }}>
        <div className="content-subheader">
          {!flag ? (
            <h4 className="mr-3">Company Services List</h4>
          ) : (
            <h4 className="mr-3">Add Company Services</h4>
          )}
          {!flag ? (
            <button
              className="btn primary"
              onClick={addService}
            >
              Add Company Services
            </button>
          ) : (
            ""
          )}
        </div>
      </Box>

      <div className="sub-content-sec">
        {!flag ? (
          <CompanyServicesList
            setFlag={setFlag}
            updateRecord={getUpdateRecordValue}
          ></CompanyServicesList>
        ) : (
          <CreateCompanyServices
            setFlag={setFlag}
            updateRecord={updateValue}
          ></CreateCompanyServices>
        )}
      </div>
    </Box>
  );
}
