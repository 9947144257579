import { msgTypes } from "constants/msgTypes";
import { Validation } from "constants/Validation";
import * as Yup from "yup";

export const CertificateSchema=

Yup.object().shape({
    certificateId: Yup.string(),

    certificateName: Yup.string().required(Validation.required.message.replaceAll("^","Certificate Name"))
                      .matches(Validation.plaintext.regex , Validation.plaintext.message),
    expireDate: Yup.string().required(Validation.required.message.replaceAll("^","Certificate Expire Date")),
  //   uploadedCertificate: Yup.mixed().required().test(
  //     "FILE_FORMAT",
  //     "Uploaded file has unsupported format",
  //     (value) => !value || (value && msgTypes.SUPPORTED_IMAGE_FORMAT.includes(value?.type))
  // ).test(
  //     "FILE_SIZE",
  //     "Uploaded file is too big",
  //     (value) =>!value || (value && value.size <=1024 * 1024)
  // )

})